.currency-strength-cards-filters {
  margin-bottom: 20px;
  user-select: none;
}

.currency-strength-cards-filter-item {
  background: #212122;
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #00DC0A;
  width: 140px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  & svg {
    margin-left: 5px;
    fill: #00DC0A!important;
    color: #00DC0A!important;
  }
}

.currency-strength-cards {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 650px;
  overflow: auto;
}

.currency-strength-card {
  width: calc((100% - 32px) / 3);
  margin-bottom: 16px;
  margin-left: 8px;
  margin-right: 8px;

  &:nth-child(3n + 1) {
    margin-left: 0;
  }

  &:nth-child(3n) {
    margin-right: 0;
  }
}

.currentcy-strength-list-wrapper {
  height: 650px;
  overflow: auto;
}

.currency-strength-card-list {
  width: 100%;
}

.currency-strength-list-header {
  background: #212122;
  padding-top: 13px;
  padding-bottom: 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  position: sticky;
  top: 0;
  z-index: 999999;
  gap: 20px;
  color: #FFFFFF;
  min-width: 1250px;
}

.currency-strength-header-favorite {
  min-width: 30px;

  & svg {
    position: relative;
    color: #C7C7CC !important;
    fill: #C7C7CC !important;
    left: 5px;
    width: 30px!important;
    height: 30px!important;
    cursor: pointer;
  }
}

.currency-strength-header-currency {
  min-width: 110px;
}

.currency-strength-header-rate {
  min-width: 100px;
}

.currency-strength-header-change {
  min-width: 100px;
}

.currency-strength-header-graph {
  min-width: 200px;
}

.currency-strength-header-ai-sentiment {
  min-width: 140px;
}

.currency-strength-header-new-sentiment {
  min-width: 170px;
}

.currency-strength-header-bid {
  min-width: 70px;
}

.currency-strength-header-ask {
  min-width: 70px;
}

.currency-strength-header-volume {
  min-width: 70px;
  padding-right: 24px;
}

@media (max-width: 1300px) {
  .currency-strength-card {
    width: calc(50% - 8px);
    &:nth-child(2n) {
      margin-right: 0;
      margin-left: 8px;
    }

    &:nth-child(2n + 1) {
      margin-right: 8px;
      margin-left: 0;
    }
  }
}

@media (max-width: 800px) {
  .currency-strength-card {
    width: 100%;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}