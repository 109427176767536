.trade-with-mike-page {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}

.w-70 {
  width: 70%;
}

.w-30 {
  width: 30%;
}

.left-part {
  padding: 16px;
}

.right-part {
  background: #29292B;
}

.trade-with-mike-page-title {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #FFFFFF;
  margin-bottom: 10px;
}

.trade-with-mike-page-short-description {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.05em;
  color: #6E6E70;
  margin-bottom: 30px;
}

.trade-with-mike-page-main-view {
  padding: 24px 30px;
  background: #fff;
}

.trade-with-mike-page-live-streams-info {
  margin-top: 24px;
}

.trade-with-mike-page-live-streams-info-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 24px;
}

.live-streams-author {
  width: 48px;
  height: 48px;
  margin-right: 10px;
}

.trade-with-mike-page-live-streams-author-name {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #121416;
}

.trade-with-mike-page-live-streams-author-job {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  line-height: 18px;
  color: #BCBCBC;
}

.trade-with-mike-page-live-streams-info-content {
  padding-bottom: 40px;
  border-bottom: 0.5px solid #E4E5E8;
}

.trade-with-mike-page-live-streams-title {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: #121416;
  margin-bottom: 8px;
}

.trade-with-mike-page-live-streams-description {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  color: #6E6E70;
}

.trade-with-mike-page-live-streams-schedule {
  padding-top: 40px;
  padding-bottom: 26px;
}

.trade-with-mike-page-live-streams-schedule-title {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  color: #121416;
  margin-bottom: 10px;
}

.trade-with-mike-page-live-streams-schedule-list {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}

.trade-with-mike-page-live-streams-schedule-day-item {
  width: calc(20% - 2px);
}

.trade-with-mike-page-live-streams-schedule-day {
  background: #F7F8F7;
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #6E6E70;
  padding: 5px;
}

.trade-with-mike-page-live-streams-schedule-time {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  color: #6E6E70;
  text-align: center;
  margin-top: 7px;
}

.trade-with-mike-page-previous-streams {
  padding-top: 30px;
  border-top: 0.5px solid #E4E5E8;
}

@media (min-width: 993px) and (max-width: 1200px) {
  .trade-with-mike-page {
    flex-direction: column;
  }

  .w-70.left-part {
    width: 100%;
  }

  .w-30.right-part {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .trade-with-mike-page-live-streams-schedule-day {
    font-size: 12px;
  }

  .trade-with-mike-page-live-streams-schedule-time {
    font-size: 12px;
  }
}

@media (max-width: 767px) {
  .trade-with-mike-page {
    flex-direction: column;
  }

  .trade-with-mike-page-content-wrapper {
    width: 100%;
  }

  .past-videos-content {
    width: 100%;
  }

  .w-70.left-part {
    width: 100%;
  }

  .w-30.right-part {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .trade-with-mike-page-main-view {
    padding: 20px;
  }

  .trade-with-mike-page-real-time-trading-info {
    width: 100%;
  }
}

body.light {
  .trade-with-mike-page-title {
    color: #000;
  }

  .right-part {
    background: #fff;
  }
}