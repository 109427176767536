/* start range styles */
.range-bar-container {
  min-width: 200px;
  max-width: 300px;
}

.range-bar-container .range-bar-box {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #dbdbdb;
  width: 100%;
  height: 4px;
}

.range-bar-container .range-bar-box .current {
  cursor: pointer;
  position: relative;
  height: 8px;
  width: 8px;
  background-color: #007bff;
  border-radius: 50%;
}
/* end range styles */

/* start tags list styles */
.tags-list-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-bottom: 0;
}

.tags-list-expand-btn {
  position: absolute;
  z-index: 2;
  display: flex;
  align-items: center;
  /* height: 100%; */
  padding: 0;
  transition: all 0.3s;
  right: 0;
}

.tags-list-items {
  white-space: pre-wrap;
  padding-right: 40px;
  position: relative;
  max-height: 45px;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: all 0.3s;
}

.tags-list-items.expanded {
  max-height: 1000px;
  transition: all 0.3s;
}

.tags-list-items .tags-list-item {
  display: inline-block;
  margin: 3px 10px 3px 0px;
}

.tags-list-items-expand-btn {
  position: absolute;
  top: 4px;
  right: 2px;
}

.tags-list-items .tag-list-item {
  display: inline-block;
  margin: 3px 10px 3px 0px;
}

.tags-list-items.expandable .tag-item:nth-last-child(2) {
  margin-right: 0;
}

.tags-list-items.not-expandable .tag-item:last-child {
  margin-right: 0;
}
/* end tags list styles */

/* start expandable list styles */
.expandable-list {
  white-space: pre-wrap;
  position: relative;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: all 0.5s;
}

.expandable-list.expanded {
  max-height: 100000px !important;
  transition: all 15s;
}

/* .expand-divider {
    background-color: #dadce0;
    border: 0;
    height: 1px;
    left: 0;
    margin-top: 18px;
    position: absolute;
    width: 100%;
  } */
/* end expandable list styles */

/* start statistic callout styles */
/* end statistic callout styles */

/* start scrollable styles */
.h-scrollable {
  overflow-x: auto;
  overflow-y: hidden;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  white-space: nowrap;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.h-scrollable::-webkit-scrollbar {
  display: none;
}
/* end scrollable styles */

/* start scrollable container styles */
.scrollable-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-bottom: 0;
}

.scrollable-container::before {
  opacity: 0;
  content: "";
  display: block;
  pointer-events: none;
  position: absolute;
  top: 0;
  z-index: 1;
  bottom: 1px;
  transition: all 0.3s;
}

.scrollable-container.scroll-left::before {
  left: 0;
  width: 36px;
  background: linear-gradient(
    270deg,
    hsla(0, 0%, 100%, 0) 0,
    #f5f5f5 95%,
    #f5f5f5
  );
  opacity: 1;
  transition: all 0.3s;
}

.scrollable-container::after {
  opacity: 0;
  content: "";
  display: block;
  pointer-events: none;
  position: absolute;
  top: 0;
  z-index: 1;
  bottom: 1px;
  transition: all 0.3s;
}

.scrollable-container.scroll-right::after {
  right: 0;
  width: 36px;
  background: linear-gradient(
    90deg,
    hsla(0, 0%, 100%, 0) 0,
    #f5f5f5 95%,
    #f5f5f5
  );
  opacity: 1;
  transition: all 0.3s;
}

.scrollable-items {
  padding: 6px;
  height: 100%;
  box-sizing: initial;
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}

.scrollable-items::-webkit-scrollbar {
  display: none;
}

.scrollable-item {
  flex-shrink: 0;
}

.btn-scroll {
  position: absolute;
  z-index: 2;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0;
  transition: all 0.3s;
}

.btn-scroll svg {
  fill: #767676;
}

.btn-scroll:hover svg {
  fill: #343a40;
}

.btn-scroll.hidden {
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}

.btn-scroll.next {
  right: 0;
}

.btn-scroll.previous {
  left: 0;
}
/* end scrollable container styles */

/* start grid widget styles */
.grid-widget .grid-widget-item {
  transition: all 0.2s;
  padding: 6px 8px;
}

.grid-widget.hover .grid-widget-item {
  border-radius: 5px;
}

.grid-widget.hover .grid-widget-item:hover {
  background-color: #f5f5f5;
  transition: all 0.2s;
}
/* end grid widget styles */

/* start space styles */
@media (max-width: 768px) {
  .empty-space {
    height: 50px !important;
  }
}
/* end space styles */

/* start actions styles */
.arrow-btn.btn .icon.bi-arrow-right {
  -webkit-animation: bounceRight 1.5s infinite;
  animation: bounceRight 1.5s infinite;
}

.arrow-btn.btn.btn-lg .icon.bi-arrow-right {
  width: 1.5rem;
  height: 1.5rem;
}
/* end actions styles */

/* start logo styles */
.logo {
  margin-bottom: 0;
  font-size: 0;
}

.logo a,
.logo span {
  font-weight: 600;
  letter-spacing: -2.4px;
  font-family: Open Sans, sans-serif;
  font-size: 32px;
  text-decoration: none !important;
}

.logo.with-icon a,
.logo.with-icon span {
  font-size: 25px;
}

.logo-icon {
  width: auto;
  height: 30px;
  margin-right: 6px;
}

/* end logo styles */

.custom-button {
  color: #007bff !important;
  background-color: transparent;
  border: 1px solid #007bff !important;
  padding: 8px 12px;
  line-height: 1.5;
  border-radius: 0.25rem;
  height: auto !important;
}

.spinner-box {
  margin: 0 auto;
  text-align: center;
  width: 100%;
}

.spinner-box img {
  width: 160px;
}

.popupRealTimeOptions{
  width: 494px !important;
  border-radius: 15px !important;
  overflow: hidden;
}

.popupRealTimeOptions .ant-modal-content {
  border-radius: 15px !important;
  box-shadow: none;
  padding: 15px !important;
}

.popupRealTimeOptions .ant-modal-body img{
  margin: 0 auto 30px !important;
}

body.dark .dialog-body-text {
  color: #fffffa;
}
body.dark .ant-modal-content {
  background-color: #202020;
}

body.dark .ant-modal-header {
  background: #202020;
}

body.dark .ant-modal-body {
  color: #FFFFFA;
}

body.dark .ant-modal-close-icon {
  color: #fffffa;
}

p.text{
  font-size: 24px;
  color: #000000;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  text-align: center;
}

.actions .btn-primary{
  border-radius: 20px;
  background-color: #0071ed;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  text-align: right;
}

.actions .btn-outline{
  border-radius: 20px;
  border-color: #a2a2a2 !important;
  font-size: 18px;
  line-height: 27px;
  color: #a2a2a2 !important;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  text-align: right;
}

.membershipPopup{
  border-radius: 15px !important;
  top: 50%;
  transform: translateY(-50%);
}

.membershipPopup .ant-modal-content {
  border-radius: 15px !important;
  box-shadow: none;
  padding: 15px !important;
}

.membershipPopup .ant-modal-body {
  padding: 0 24px 24px 24px;
}

.no-side-padding {
  padding-left: 0!important;
  padding-right: 0!important;
}

.darkflow-tabs-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

@media screen and (min-width: 1601px) {
  .guest-company-chart-tabs {
    margin-left: 30px!important;
  }

  .company-header-specific-icon2 svg {
    top: 0!important;
  }

  .company-header-specific-icon3 svg {
    top: 0!important;
  }

  .darkflow-tab {
    width: 180px;
  }
}

@media screen and (min-width: 1400px) and (max-width: 1600px){
  .guest-company-chart-tabs {
    margin-left: 88px!important;
  }

  .darkflow-tab {
    width: 160px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1399px){
  .darkflow-tab {
    width: 130px;
  }

  .today-darkflow-activity {
    font-size: 16px!important;
  }
}

@media screen and (min-width: 1400px) {
  .company-header-specific-icon2 svg {
    position: relative;
    top: 0;
  }

  .company-header-specific-icon3 svg {
    position: relative;
    top: -11px;
  }

  .company-header-specific-icon4 svg {
    position: relative;
    top: -11px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  /** styles of tablet view in company page chart header **/
  .company-page-chart-header-wrapper {
    flex-wrap: nowrap!important;
  }

  .company-page-chart-header {
    margin-left: 0!important;
  }

  .company-header-right-border {
    display: none!important;
  }

  .darkflow-tab {
    width: 180px;
  }
}

@media screen and (max-width: 991px) {
  .today-darkflow-activity {
    font-size: 17px!important;
    line-height: 30px!important;
  }

  .company-header-pin-wrapper {
    display: none!important;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .company-header-specific-icon2 svg {
    position: relative;
    top: 0;
  }

  .company-header-specific-icon3 svg {
    position: relative;
    top: -11px;
  }

  .company-header-specific-icon4 svg {
    position: relative;
    top: -11px;
  }

  .company-header-custom-report-icon svg {
    position: relative;
    top: -11px;
  }

  .darkflow-tab {
    width: 130px;
  }
}

@media screen and (max-width: 767px) {
  .company-header-right-border {
    display: none!important;
  }

  .company-tab-label {
    font-size: 11px!important;
  }

  .company-header-specific-icon2 svg {
    position: relative;
    top: 0px;
  }

  .company-header-specific-icon3 svg {
    position: relative;
    top: -9px;
  }

  .company-header-specific-icon4 svg {
    position: relative;
    top: -9px;
  }

  .darkflow-activity-wrapper {
    display: block;
  }

  .today-darkflow-activity {
    flex: 0 0 100%;
    max-width: 100%;
    justify-content: center;
  }

  .darkflow-tabs-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .darkflow-tab-filter {
    margin-left: 30px!important;
  }

  .darkflow-tab {
    width: 150px;
  }
}

@media screen and (max-width: 480px) {
  .company-tab-label {
    font-size: 9px!important;
  }
}

@media screen and (max-width: 460px) {
  .company-header-left-border {
    display: none!important;
  }

  .company-header-wrapper {
    flex-wrap: wrap!important;
  }

  .company-page-header {
    max-width: 100% !important;
  }

  .company-page-header-first-part {
    border-bottom: 1px solid #dfd0d073!important;
    border-top: 1px solid #dfd0d073!important;
    padding: 10px 0!important;
  }

  .company-page-header-second-part {
    padding-left: 0!important;
    margin-top: 20px!important;
    border-left: none!important;
  }

  .company-page-header-second-part-wrapper {
    margin-left: 0!important;
    margin-right: 0!important;
  }

  .company-page-header-second-part-wrapper .col-lg-6 {
    display: flex!important;
    align-items: center!important;;
    justify-content: space-between!important;;
    flex-direction: row!important;
    padding: 10px 0!important;
    flex-wrap: wrap!important;
  }

  .company-page-header-second-part-wrapper .col-lg-6 .row.text-bold {
    flex: 0 0 50% !important;
    max-width: 50% !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
    display: initial!important;
  }

  .company-page-header-second-part-wrapper .col-lg-6 .row.text-bold span{
    margin-left: 10px!important;
  }

  .company-page-header-second-part-wrapper hr {
    width: 100% !important;
    margin-left: 0!important;
    display: none!important;
  }
}

@media screen and (max-width: 1600px) {
  .company-header-pin-wrapper {
    margin-left: 140px!important;
  }
}

@media screen and (max-width: 1200px) {
  .company-header-pin-wrapper {
    margin-left: 0!important;
  }
}

.very-bullish {
  color: rgb(34, 139, 34);
}

.very-bearish {
  color: #fa4646;
}

.neutral-dark {
  color: rgba(0, 0, 0, 0.5);
}

.neutral-light {
  color: rgba(0, 0, 0, 0.5);
}

.MuiOutlinedInput-notchedOutline {
  border-color: transparent!important;
}