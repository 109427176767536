.forexActivity{
    background-color: black;
    height: 100vh;
    .headerText{
        font-family: 'Circular Std';
        font-weight: 700;
        font-size: 32px;
        line-height: 40px;
        letter-spacing: -0.04em;
        color: #FFFFFF;
    }
    .tableCont{
        width: 100%;
    }
    .tableHeader{
        display: flex;
        justify-content: space-around;
        background-color: aqua;
    }
}