.calendly-inline-widget {
  min-width: 320px;
  position: relative;
  height: 666px !important;
  overflow: hidden;

  @media (min-width: 0px) {
    min-width: 320px;
    position: relative;
    top: -60px;
    overflow: hidden;
    height: 94vh;
  }

  @media (min-width: 776px) {
    min-width: 320px;
    position: relative;
    height: 94vh !important;
    overflow: hidden;
  }

  @media (min-width: 1200px) {
    min-width: 320px;
    position: relative;
    top: -60px;
    height: 94vh !important;
    overflow: hidden;
  }

  @media (min-width: 1300px) {
    min-width: 320px;
    position: relative;
    overflow: hidden;
    top: -60px;
  }
}

.options-mentorship-starter {
  padding: 21px 125px 21px 25px;
  min-height: calc(100vh - 90px);
  background: url(../../../assets/images/plan-bg.png);
  background-size: cover;
}

.options-mentorship-starter-title {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 46px;
  color: #FFFFFF;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
}

.options-mentorship-starter-type {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 46px;
  color: #00DC0A;
}

.telegram-join {
  margin-top: 20px;
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #BCBCBC;
}

.options-mentorship-starter-info {
  max-width: 700px;
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: #BCBCBC;
  margin-top: 10px;
}

.options-mentorship-starter-available-sessions {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: #00DC0A;
  margin-top: 20px;
  margin-bottom: 40px;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.options-mentorship-starter-content {
  background: #121416;
  border-radius: 7px;
  border: 2px solid #D9D9D933;
  padding: 30px 50px 50px 50px;
}

.options-mentorship-starter-no-sessions-title {
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 57px;
  text-transform: capitalize;
  color: #FFFFFF;
  margin-bottom: 20px;
}

.options-mentorship-starter-no-sessions-info {
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  color: #BCBCBC;
  margin-bottom: 25px;
}

.options-mentorship-starter-renew-button {
  border: 2px solid #00DC0A !important;
  border-radius: 7px !important;
  padding: 10px 40px !important;
  font-family: 'Circular Std' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  text-transform: capitalize !important;
  color: #00DC0A !important;
}

.options-mentorship-starter-return-home {
  margin-top: 20px;

  a {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #00DC0A;
    justify-content: flex-end;

    svg {
      margin-right: 10px;
      fill: #00DC0A !important;
      color: #00DC0A !important;
    }
  }
}

.no-padding-border {
  padding: 0 !important;
  border: none !important;
}

.options-mentorship-mentors-selector {
  width: 260px;
  cursor: pointer;
  height: 57px;
  transition: all 0.3s;
  background: #1D1D1E;
  border: 1px solid rgba(217, 217, 217, 0.2);
  position: absolute;
  top: 0;
  right: 0;
}

.options-mentorship-mentors-selector.active {
  height: 200px;

  .options-mentorship-mentor-candidate {
    display: block;
  }
}

.options-mentorship-current-mentor {
  padding: 16px;
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: #FFFFFF;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.options-mentorship-mentor-candidate {
  display: none;
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #FFFFFF;
  padding: 10px 16px;
}

body.light {
  .options-mentorship-starter {
    background: none;
  }

  .options-mentorship-starter-title {
    color: #121416;
  }

  .options-mentorship-starter-type {
    color: #007BFF;
  }

  .options-mentorship-starter-info {
    color: #7a7a7a;
  }

  .options-mentorship-starter-available-sessions {
    color: #007BFF;
  }

  .options-mentorship-starter-content {
    background: #FFFFFF;
  }

  .options-mentorship-starter-content.no-padding-border {
    background: #cccccc00;
  }

  .options-mentorship-starter-no-sessions-title {
    color: #121416;
  }

  .options-mentorship-starter-no-sessions-info {
    color: #7a7a7a;
  }

  .options-mentorship-starter-renew-button {
    border: 2px solid #007BFF !important;
    color: #007BFF !important;
  }

  .options-mentorship-starter-return-home a {
    color: #007BFF;

    svg {
      margin-right: 10px;
      fill: #007BFF !important;
      color: #007BFF !important;
    }
  }
}

@media (max-width: 767px) {
  .options-mentorship-starter {
    padding: 21px 25px;
  }

  .options-mentorship-starter-no-sessions-title {
    font-size: 40px;
    line-height: 49px;
  }
}