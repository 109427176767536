/*** Company News Styles ***/
.company-news-page {
  // height: calc(50vh - 102px );
  // height: 100%;
  min-height: 300px;
  // height: 48%;
  overflow-y: auto;
  max-height: calc(50vh - 95px);

  width: 100%;
  padding: 16px 0px 0px 0px;
  padding-top: 0;
  @media (max-width: 1200px) {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .company-news-item-big-title {
    font-family: "Circular Std";
    padding: 13px 0;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    letter-spacing: -0.04em;
    color: black;
  }

  .company-news-title {
    font-family: "Circular Std";
    padding: 10px;

    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.04em;
    color: black !important;
    background: white;
  }

  .company-news-item-read-more {
    margin-top: 4px;
    cursor: pointer;
    color: black;
    opacity: 0.5;
  }

  .company-news-content {
    // overflow-y: auto;
    // max-height: calc(50vh - 145px);

    // height: calc(100% - 350px);
    // max-height: 600px;

    // max-height: calc(100vh - 270px);

    // @media (max-width: 1200px) {
    //   max-height: initial;
    // }

    .company-news-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 10px;
      background: white;
      border: 1px solid #ffffff;
      position: relative;

      &:last-child {
        margin-bottom: 0;
      }

      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        border-bottom: 1px solid #d1d1d6;
        width: 80%;
        transform: translateX(-50%);
      }
    }
  }

  .company-news-item-title {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    .company-news-item-title-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      margin-right: 12px;
      padding: 10px;
      background: linear-gradient(
        90deg,
        rgba(5, 142, 135, 0.1) -19.07%,
        rgba(0, 220, 10, 0.1) 49.27%,
        rgba(244, 250, 6, 0.1) 112.14%
      );

      svg {
        color: #00dc0a;
        font-size: 20px;
      }
    }

    .company-news-item-title-time {
      font-family: "Circular Std";
      font-style: normal;
      font-weight: 450;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.02em;
      color: black;
      opacity: 0.5;
    }

    .company-news-item-title-content {
      font-family: "Circular Std";
      font-style: normal;
      font-weight: 450;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.02em;
      color: black;
      opacity: 0.5;
      margin-left: 10px;
    }
  }

  .company-news-item-content {
    font-family: "Circular Std";
    font-style: normal;
    font-weight: 450;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.04em;
    color: black;
    opacity: 0.8;
  }

  .company-news-content::-webkit-scrollbar {
    width: 8px;
    border-radius: 0px;
  }

  .company-news-content::-webkit-scrollbar-track {
    background: #d1d1d6;
    margin-right: 8px;
    border-radius: 0px;
  }

  .company-news-content::-webkit-scrollbar-thumb {
    background-color: #6d6f71;
    border: 1px solid #6d6f71;
    border-radius: 0px;
  }
}

.company-news-content-result {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
}
