.content-in-layout {
  margin-left: 0;
  min-height: 100vh;
}

.sidebar-layout-content {
  height: 100%;
  flex-grow: 1;
  padding-bottom: 85px;
}

@media (max-width: 1024px) {
  .main-content-wrapper {
    overflow: hidden;
  }
}

@media (max-width: 991px) {
  .search-bar {
    /*max-width: 500px;*/
  }

  .sidebar-layout-content {
    padding-bottom: 10px;
  }
}

@media (min-width: 992px) {
  .search-bar {
    min-width: 380px;
  }
}

@media (min-width: 1200px) {
  .search-bar {
    min-width: 400px;
  }
}

@media (min-width: 1366px) {
  .search-bar {
    min-width: 500px;
  }
}

@media (min-width: 992px) {
  .content-in-layout {
    margin-left: 90px;
  }

  .sidebar-layout-content {
    z-index: 1;
    padding-top: 20px;
    padding-bottom: 0px;
  }
}

#mobile-header-search-bar .ant-select-dropdown {
  top: 60px !important;
}

.sidebar-content-container {
  /* padding: 0 2rem; */
  height: 100%;
  flex-grow: 1;
  padding: 0;
}

@media (max-width: 1599px) and (min-width: 1200px) {
  .sidebar-content-container {
    padding: 0;
  }
}

@media (min-width: 1200px) {
  /* .homepage-right {
    max-height: calc(100vh - 97px);
    overflow: auto;
  } */

  .homepage-right::-webkit-scrollbar {
    display: none;
  }
}

.strong-buys-items {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
  grid-gap: 26px;
}

@media (min-width: 768px) {
  .strong-buys-items {
    grid-template-columns: repeat(auto-fill, minmax(192px, 1fr));
  }
}

.strong-buys-items a {
  text-decoration: inherit;
  color: inherit;
}

.sidebar-menu-items svg {
  width: 26px;
  fill: #fff;
}

.sidebar-menu-items svg.attach-icon {
  fill: #fff !important;
}

/* start user popup styles */
.user-profile-popup {
  position: fixed;
  bottom: 58px;
  left: 0;
  right: 0;
  z-index: 1000;
  overflow: auto;
}

.user-profile-popup.open {
  background-color: #fff;
  transition: box-shadow 500ms, background-color 500ms;
  box-shadow: 0px -1px 3px #00000029;
}

.user-profile-popup.close {
  transition: box-shadow 500ms, background-color 500ms;
}

@media (min-width: 992px) {
  .user-profile-popup {
    bottom: 0px;
    left: 90px;
    right: auto;
    width: 430px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  .user-profile-popup.open {
    box-shadow: 5px 0 5px -5px #00000029, -5px 0 5px -5px #00000029;
  }

  .user-profile {
    min-height: 600px;
  }
}

/* @media screen and (max-width: 992px) and (orientation: landscape) {
  .user-profile-popup.open {
    top: 15%;
  }
} */

.user-profile {
  max-height: calc(100vh - 97px);
  overflow: auto;
}

/* Popup animation */
.user-popup-transtion-enter {
  opacity: 0;
  transform: translateX(-90%);
  margin-left: -25%;
  padding: 0 10px;
}

@media (max-width: 1599px) and (min-width: 992px) {
  .user-popup-transtion-enter {
    margin-left: -33.3333%;
  }
}

.user-popup-transtion-enter-active {
  opacity: 1;
  transform: translateX(0);
  margin-left: 0;
  padding: 0;
  transition: opacity 500ms, transform 500ms, margin-left 500ms, padding 500ms;
}

.user-popup-transtion-exit {
  opacity: 1;
}

.user-popup-transtion-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  margin-left: -25%;
  padding: 0;
  transition: opacity 500ms, transform 500ms, margin-left 500ms, padding 500ms;
}

@media (max-width: 1599px) and (min-width: 992px) {
  .user-popup-transtion-exit-active {
    margin-left: -33.3333%;
  }
}

.user-popup-transtion-exit-done {
  opacity: 0;
  transform: translateX(-100%);
  margin-left: -25%;
  padding: 0;
}

@media (max-width: 1599px) and (min-width: 992px) {
  .user-popup-transtion-exit-done {
    margin-left: -33.3333%;
  }
}

/* end user popup styles */
