.options-metorship-plan {
  background: #121416;
  border-radius: 10px;
  padding: 20px 20px 60px 20px;
  width: calc(100% / 3 - 8px);
  border: 2px solid #666769;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  cursor: pointer;
  transition: all 0.01s ease-out;
  position: relative;

  &:hover {
    box-shadow: inset 0px 4px 25px #00DC0A;
    transition: all 1s;
  }

  &.active {
    border: 2px solid #00DC0A;

    &:hover {
      box-shadow: none;
    }
  }
}

.most-popular-badge {
  position: absolute;
  top: 0;
  right: 0;
  width: 85px;
}

.options-metorship-plan-badge {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  text-transform: uppercase;
  color: #121416;
  padding: 6px 15px;
  margin-bottom: 16px;
  border-radius: 6px;
  width: 170px;
}

.novice-badge {
  background: #fff;
}

.accelerator-badge {
  background: linear-gradient(90deg, #00FF87 1.67%, #60EFFF 100%);
}

.master-badge {
  background: linear-gradient(268.42deg, #FF0F7B 1.28%, #F89B29 98.66%);
}

.options-mentorship-plan-info {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
  padding-bottom: 12px;
  border-bottom: 1px solid #666769;
  flex: 1;
}

.options-mentorship-plan-features {
  padding-top: 20px;
  padding-bottom: 80px;
  border-bottom: 1px solid #666769;
  flex: 1 1 auto;
  max-height: 500px;
}

.options-mentorship-plan-feature {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.options-mentorship-plan-feature span {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
  margin-left: 8px;
}

.options-mentorship-plan-price {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  color: #FFFFFF;
  padding: 32px 0;
}

.small-text {
  font-size: 20px;
  line-height: 25.3px;
  font-weight: 450;
}

.small-usd-text {
  font-size: 20px;
  line-height: 25.3px;
  font-weight: 450;
  margin-left: 5px;
}

.options-mentorship-plan-buy-button {
  background: #00DC0A!important;
  border-radius: 5px!important;
  font-family: 'Circular Std'!important;
  font-style: normal!important;
  font-weight: 700!important;
  font-size: 24px!important;
  line-height: 30px!important;
  color: #121416!important;
  padding: 16px 55px!important;
  text-transform: initial!important;
  margin: 0 auto!important;
  display: block!important;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,220,11,0.4)!important;
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,220,11,0.4)!important;
  box-shadow: 0px 5px 10px 0px rgba(0,220,11,0.4)!important;
}

body.light {
  .options-metorship-plan {
    background-color: #fff;

    &:hover {
      box-shadow: inset 0px 4px 25px #007BFF;
      transition: all 1s;
    }
  
    &.active {
      border: 2px solid #007BFF;
  
      &:hover {
        box-shadow: none;
      }
    }
  }

  .novice-badge {
    background: #ccc;
  }

  .options-mentorship-plan-info {
    color: #000;
  }

  .options-mentorship-plan-feature svg {
    color: #000;
  }

  .options-mentorship-plan-feature span {
    color: #000;
  }

  .options-mentorship-plan-price {
    color: #000;
  }
}