/* start fontawesome overrides */
.fa,
.far {
  /* color: #e0dddde3; */
  font-size: 15px;
}
/* end fontawesome overrides */

/* start antd overrides */

a.ant-typography,
.ant-typography a {
  color: #007bff;
}

a.ant-typography:hover,
.ant-typography a:hover {
  color: #007bff;
}

.ant-slider-track,
.ant-slider:hover .ant-slider-track {
  background-color: #007bff;
}
.ant-slider-handle,
.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open),
.ant-slider-handle-dragging.ant-slider-handle-dragging.ant-slider-handle-dragging {
  border-color: #007bff !important;
}

/* .ant-btn, .ant-btn:active, .ant-btn:focus{} */

.ant-btn-primary {
  color: #fff;
  background-color: #007bff;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background-color: #007bff;
  color: #fff !important;
}

.ant-btn:hover,
.ant-btn:focus {
  color: #007bff;
  border-color: #007bff;
}

.ant-btn-sm span {
  font-size: unset;
}

.custom-antd-tabs .ant-tabs-nav-wrap {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 2px #00000033;
}

.custom-antd-tabs .ant-tabs-tabpane {
  padding: 1px;
}

.custom-antd-tabs .ant-tabs-nav-wrap-ping-right {
  padding-left: 10px;
}

/* .ant-btn:hover,
.ant-btn:focus {
  border-color: #007bff;
} */

.ant-layout {
  background-color: #F8FAFC;
}

.ant-layout-content {
  overflow: hidden !important;
}

.ant-message .anticon {
  top: -3px !important;
}

.ant-steps-item-icon > .ant-steps-icon {
  top: -3px !important;
}

.ant-select-dropdown-menu {
  padding-left: 0 !important;
}

.ant-input-prefix {
  margin-right: 8px;
}

.ant-input {
  border-color: #e9ebed;
}

.ant-input-search-icon::before {
  border-left-color: #e9ebed;
}

.ant-form-item-has-error .ant-input,
.ant-form-item-has-error .ant-input-affix-wrapper,
.ant-form-item-has-error .ant-input:hover,
.ant-form-item-has-error .ant-input-affix-wrapper:hover {
  border-color: #ff4d4f !important;
}

.ant-input-affix-wrapper:hover {
  border-color: #40a9ff !important;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-focused) {
  border: 1px solid #e9ebed;
}

.ant-input-affix-wrapper {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.fix-select-arrow .ant-select-selector {
  z-index: 1;
}

.fix-select-arrow .ant-select-arrow {
  z-index: 0;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #e9ebed;
}

.ant-select-selection:not(:focus) {
  border-color: #e9ebed !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-search-input {
  height: 35px;
}

.ant-select-dropdown-menu-item-selected {
  font-weight: 400 !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  font-weight: 400 !important;
  background-color: #2196f31f !important;
}

.ant-form-item-control-input-content {
  margin: 6px 0;
}

.ant-form-item-control-input-content .ant-input {
  padding: 8px 10px !important;
}

.ant-form-item {
  margin-bottom: 0;
}

.ant-popover-content .ant-popover-inner-content {
  padding-top: 6px;
  max-width: 400px;
  font-family: "Poppins", sans-serif !important;
}

.ant-popover-content .ant-popover-inner {
  padding-top: 6px;
  box-shadow: 0 0 10px #e9ebed !important;
  border-radius: 5px;
}

.ant-input-affix-wrapper .ant-input {
  padding-left: 0 !important;
}

/* start antd table overrides */
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-pagination-item-active,
.ant-pagination-item:focus a,
.ant-pagination-item:hover a {
  color: #007bff;
}
.ant-pagination-item-active:focus,
.ant-pagination-item-active:hover {
  border-color: #007bff;
  color: #007bff;
}

.ant-pagination-item-active:focus a,
.ant-pagination-item-active:hover a {
  color: #007bff;
}

/* .ant-table-ping-right:not(.ant-table-has-fix-right)
  .ant-table-container::after {
  background: linear-gradient(90deg, hsla(0, 0%, 100%, 0), #fff);
  box-shadow: none;
} */

/* .ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container::before {
  background: linear-gradient(90deg, hsl(0deg 0% 100%), #ffffff1f);
  box-shadow: none;
} */

.custom-antd-table .ant-table .ant-table-thead > tr > th {
  background-color: #f8f9fa;
}

.custom-antd-table .ant-table .ant-table-thead > tr > th:hover {
  background-color: transparent;
}

.custom-antd-table
  .ant-table-thead
  th.ant-table-column-has-sorters:hover
  .ant-table-filter-trigger-container {
  background-color: transparent;
}

.custom-antd-table .ant-table-filter-trigger-container-open,
.custom-antd-table .ant-table-filter-trigger-container:hover,
.custom-antd-table
  .ant-table-thead
  th.ant-table-column-has-sorters:hover
  .custom-antd-table
  .ant-table-filter-trigger-container:hover {
  background-color: transparent;
}

.custom-antd-table .ant-table .ant-table-thead > tr {
  color: #fff;
  background-color: #007bff;
  background: linear-gradient(90deg, #4185f3, #2159b7);
  background: -moz-linear-gradient(90deg, #4185f3, #2159b7);
  background: -webkit-gradient(90deg, #4185f3, #2159b7);
  background: -webkit-linear-gradient(90deg, #4185f3, #2159b7);
  background: -o-linear-gradient(90deg, #4185f3, #2159b7);
  background: -ms-linear-gradient(90deg, #4185f3, #2159b7);
  background: linear-gradient(90deg, #4185f3, #2159b7);
}

.custom-antd-table .ant-table .ant-table-thead > tr > th {
  border-top: 1px solid #e0e3eb;
  border-bottom: 1px solid #e0e3eb;
  background-color: transparent;
}

.custom-antd-table .ant-table-filter-trigger {
  color: #ffffff38;
}
.custom-antd-table .ant-table-column-sorter {
  color: #ffffff38;
}

.custom-antd-table .ant-table-filter-trigger.active {
  color: #fff;
}

.custom-antd-table
  .ant-table-filter-trigger-container-open
  .ant-table-filter-trigger,
.custom-antd-table .ant-table-filter-trigger:hover {
  color: #fff;
}

.custom-antd-table .ant-table-column-sorter-up.active,
.custom-antd-table .ant-table-column-sorter-down.active {
  color: #fff;
}

.custom-antd-table .ant-table .ant-table-thead > tr > th:first-child {
  border-left: 1px solid #e0e3eb;
}

.custom-antd-table .ant-table.ant-table-thead > tr > th:last-child {
  border-right: 1px solid #e0e3eb;
}

.custom-antd-table td.ant-table-column-sort {
  /* background-color: #f8f9fa; */
}

.custom-antd-table .ant-table {
  color: black;
}

/* end antd table overrides */

/* start antd tooltip styles */
.ant-tooltip .ant-tooltip-inner {
  text-align: center;
}

/* end antd tooltip styles */

/* end antd overrides */

/* start bootstrap overrides */

.popover {
  border-color: #e9ebed !important;
  /* padding: 6px; */
  max-width: 400px;
  font-family: "Poppins", sans-serif !important;
}

.bs-popover-auto[x-placement^="top"] > .arrow::before,
.bs-popover-top > .arrow::before {
  border-top-color: #e9ebed !important;
}

.bs-popover-auto[x-placement^="right"] > .arrow::before,
.bs-popover-right > .arrow::before {
  border-right-color: #e9ebed !important;
}

.bs-popover-auto[x-placement^="bottom"] > .arrow::before,
.bs-popover-bottom > .arrow::before {
  border-bottom-color: #e9ebed !important;
}

.bs-popover-auto[x-placement^="left"] > .arrow::before,
.bs-popover-left > .arrow::before {
  border-left-color: #e9ebed !important;
}

.dropdown .dropdown-menu {
  border: none;
  box-shadow: 0 0 10px #00000026;
}

.dropdown .dropdown-item {
  color: #212529;
}

.dropdown .dropdown-item:hover,
.dropdown .dropdown-item:active {
  background-color: #f5f5f5;
  color: #212529;
}

.dropdown .dropdown-menu .dropdown-item:hover,
.dropdown .dropdown-menu .dropdown-item:active {
  background-color: #f5f5f5;
  color: #212529;
}
.btn:focus {
  box-shadow: none;
}

.btn.btn-lg {
  padding: 0.4rem 1rem;
}

.search-label {
  width: 100%;
}

.filter-label {
  margin-bottom: 0;
  width: 60%;
}

._loading_overlay_overlay {
  background-color: #0000004d !important;
  /* background-color: rgba(255, 255, 255, 0.8)!important; */
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff !important;
  background-color: #0062cc !important;
  border-color: #005cbf !important;
}
/* end bootstrap overrides */

/* start bootstrap search overrides */

.custom-b-search-bar input[type="text"],
.custom-b-search-bar .form-control {
  border: none;
  color: #424242;
}

.custom-b-search-bar .dropdown-item.active,
.custom-b-search-bar .dropdown-item:active {
  color: #212529;
  background-color: #f5f5f5;
}

.custom-b-search-bar .close {
  font-weight: 400;
}

.rbt-aux .rbt-close {
  margin-top: 2px;
}

.rbt-highlight-text {
  font-weight: 400;
  color: #007bff;
}

.rbt-menu > .dropdown-item {
  color: #000000d9;
}

/* end bootstrap search overrides */
