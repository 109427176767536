.currency-strength-page {
  padding: 16px;
}

.currency-strength-page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.currecy-strength-page-title {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #FFFFFF;
}

.currecy-strength-page-timestamps {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.currecy-strength-page-timestamp {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #6E6E70;
  cursor: pointer;
  padding: 6px 40px;
}

.currecy-strength-page-timestamp.active {
  background: #212122;
  color: #00DC0A;
}

.currency-strength-page-caption {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.currency-strength-page-info {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.05em;
  color: #6E6E70;
}

.currency-strength-page-datetime {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: right;
  letter-spacing: -0.05em;
  color: #6E6E70;
}

.currency-strength-modal {
  max-width: 450px;
  width: 100%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
}

.currency-strength-page-cards-wrapper {
  margin-top: 30px;
}

body.light {
  .currecy-strength-page-title {
    color: #000000;
  }
}


@media (max-width: 767px) {
  .currency-strength-page-header {
    flex-direction: column;
    align-items: flex-start;
  }
}