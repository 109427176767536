/* start loader styles */
.loader-view {
  position: fixed;
  left: 0;
  right: 0;
  text-align: center;
  top: 0;
  bottom: 0;
  z-index: 1002;
  background-color: #0000002e;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.loader-section-view {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  top: 0;
  bottom: 0;
  z-index: 2;
  background-color: #00000017;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
/* end loader styles */

/* start empty styles */
.empty-container .empty-block {
  /* padding: 10px;
  background-color: #fff;
  border-radius: 5px;
  text-align: center;
  min-height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
}
/* end empty styles */

/* start chart styles*/
/* start custom piechart styles */
@media (max-width: 575px) {
  .piechart-wrapper,
  .piechart-wrapper .custom-piechart,
  .piechart-wrapper .custom-piechart svg {
    height: 250px !important;
    margin: 0 auto;
  }
}

.piechart-hoverable .recharts-layer.recharts-pie-sector {
  transition: all 0.3s;
}

.piechart-hoverable .recharts-layer.recharts-pie-sector:hover {
  opacity: 0.7;
  transition: all 0.3s;
}

/* end custom piechart styles */

/* start custom tooltip styles */
@media (max-width: 575px) {
  .recharts-tooltip-wrapper .recharts-default-tooltip {
    white-space: pre-wrap !important;
  }
}

.recharts-tooltip-wrapper {
  z-index: 1;
}

.recharts-tooltip-wrapper .recharts-default-tooltip {
  border-color: #e9ebed !important;
}

.custom-tooltip {
  background-color: #ffffffe6;
  padding: 12px;
  box-shadow: 0 0 10px #00000026;
  border-radius: 5px;
  min-width: 120px;
}
/* end custom tooltip styles */
/* end chart styles*/

/* start not found page styles */
.fallback-error {
  background-color: #fff;
  border-radius: 5px;
  text-align: center;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
/* end not found page styles */

/* start card action styles */
.custom-card-action {
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #d8dcde;
  background-color: #e8e8e8;
  padding: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  transition: all ease-in 0.3s;
}

.custom-card-action:hover {
  opacity: 0.8;
}

.custom-card-action h5 {
  margin-bottom: 0;
}

.custom-card-action.selected {
  background-color: #007bff;
  transition: all ease-in 0.3s;
}

.custom-card-action.selected h5 {
  color: #fff;
}
/* end card action styles */

/* start futures page styles */
.futures-page .futures-tags {
  padding: 0 !important;
}

.futures-page .futures-tags .future-tag {
  display: inline-block;
  margin: 3px 10px 3px 0px;
}

.futures-page .grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(192px, 1fr));
  grid-gap: 12px;
}

.futures-page .futures-heading:first-child {
  margin-top: 0rem;
}

.futures-page .futures-heading {
  padding: 8px 0px;
  border-bottom: 2px solid #007bff;
  margin: 1rem 0rem;
}

@media (min-width: 992px) {
  .futures-page .futures-heading {
    margin: 1.5rem 0rem;
  }
}

.futures-card.positive-label {
  border-top: 2px solid #20c997;
}

.futures-card.negative-label {
  border-top: 2px solid #dc3545;
}

.futures-card .future-box {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: max-content;
  grid-gap: 0px 16px;
  align-items: center;
  min-height: 70px;
}

.futures-card .future-detail {
  display: grid;
  align-items: end;
  height: 100%;
  grid-row-start: 2;
}

.futures-card .future-change {
  border-top-right-radius: 5px;
  /* border-top-left-radius: 5px; */
}

.futures-card .future-last {
  padding-right: 8px;
  grid-row-start: 2;
  padding-top: 0;
}

/* end futures page styles */

/* start custom news styles */
.custom-news .steps-action,
.custom-news .steps-content {
  /* padding-top: 25px; */
  text-align: center;
}

.custom-news .custom-news-block {
  background-color: #fff;
  /* padding-top: 25px; */
  /* padding-bottom: 25px; */
  border-radius: 3px;
}

.custom-news .steps-action .btn {
  min-width: 120px;
}

.custom-news .selection-title {
  font-weight: 500;
}

.market-selection .market-selection-text {
  margin-bottom: 10px;
}

.favorite-titans .favorite-titans-list .follow-btn {
  width: 100%;
}
/* end custom news styles */

/* start auth pages styles */
/* start signup page styles */
.signup-container .signup-heading {
  color: #007bff;
  font-weight: 700 !important;
}

.signup-block .signup-title {
  margin-bottom: 25px;
  text-transform: capitalize;
}

@media (max-width: 575px) {
  .signup-container h2 {
    font-size: 1.5rem;
  }

  .signup-container h4 {
    font-size: 1.25rem;
  }
}
/* end signup page styles */

/* start success page styles */
.success-container .success-heading {
  color: #007bff;
  font-weight: 700 !important;
}

.success-container .success-contact-us {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.success-contact-us h4 strong {
  font-weight: 500 !important;
}

.success-contact-us .payment-info {
  padding: 20px 25px;
  border: 2px solid #e8e8e8;
}

.success-contact-us p {
  font-size: 16px;
}

.success-payment p {
  font-size: 16px;
}

.success-payment .success-payment-block {
  text-align: center;
  padding-bottom: 20px;
  border-bottom: 2px solid #e8e8e8;
}

.success-payment-block h4 {
  color: #007bff;
  font-weight: 700 !important;
  margin-bottom: 20px !important;
}

.success-payment-block .price-badge {
  padding: 15px;
  border-radius: 12px;
  background-color: #f5f5f5;
}

.success-payment-block .price-badge p {
  margin-bottom: 0;
}

ul.terminal-success-list {
  padding-left: 1.5rem !important;
}

ul.terminal-success-list li {
  margin-bottom: 5px;
}

.terminal-success-list li::before {
  content: "\2022";
  color: #007bff;
  font-weight: bold !important;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.success-payment .payment-total {
  padding: 12px 0px;
  border-top: 2px solid #e8e8e8;
  border-bottom: 2px solid #e8e8e8;
}

.success-payment .payment-total p {
  margin-bottom: 0;
}

.success-action .btn {
  min-width: 150px;
}

.success-action .btn-icon {
  vertical-align: text-bottom;
}

@media (min-width: 1200px) {
  .success-payment-block h4 {
    width: 80%;
    margin: 0 auto;
  }

  .success-payment-block .price-badge {
    width: 70%;
    margin: 0 auto;
  }
}

@media (max-width: 575px) {
  .success-container h2 {
    font-size: 1.5rem;
  }

  .success-container h4 {
    font-size: 1.25rem;
  }
}
/* end success page styles */

.complete-signup-hero {
  background-repeat: no-repeat;
  height: 500px;
}

/* .complete-signup-progress {
  width: 90px;
} */

.complete-signup-progress svg {
  fill: #007bff;
}

/* end auth pages styles */

/* start screener styles */
.screener-filters .screener-filter .ant-select {
  width: 100%;
}

.screener-filters .screener-filter .ant-input {
  margin: 0;
}
/* end screener styles */

/* start insider styles */
.insider-header .insider-icon {
  position: relative;
  height: 45px;
}

.insider-header .insider-icon .fa-chart-line {
  transform: translateY(-50%);
  padding: 15px;
  color: #007bff;
  font-size: 25px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 0 10px #e8e8e8;
}

@media (min-width: 1200px) {
  .insider-header .insider-icon .fa-chart-line {
    font-size: 35px;
  }
}

/* end insider styles */

/* start follow button styles */
.btn-primary.follow-btn.follow-active {
  background-color: #2159b7 !important;
  border-color: transparent;
}

.follow-btn.follow-active .icon-left {
  margin-right: 4px;
}
/* end follow button styles */

/* start pin button styles */
.pin-btn {
  color: #007bff !important;
  border-color: #007bff !important;
  vertical-align: middle;
  width: 40px;
  height: 40px;
}

.pin-btn.justIcon {
  background-color: #007bff !important;
  border-color: #007bff !important;
  color: #ffffff!important;
}

.pin-btn:focus {
  /* border-color: transparent; */
}

.pin-btn:hover {
  color: #007bff !important;
  border-color: #007bff !important;
}

.pin-btn.pin-active {
  color: #007bff !important;
  border-color: #007bff !important;
}

.pin-btn.ant-btn svg {
  height: 0.7rem;
  vertical-align: baseline;
}

.pin-btn.ant-btn-lg svg {
  height: 0.8rem;
}

.pin-btn.ant-btn-sm svg {
  height: 0.6rem;
}

/* end pin button styles */

/* start cms page styles */
.cms-page b,
.cms-page strong,
.cms-page p,
.cms-page a,
.cms-page ul li,
.cms-page ol li {
  font-size: 16px;
}

.cms-page h5 {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  font-weight: 500 !important;
}

/* .cms-page {
  font-size: 16px;
} */

.cms-page ul,
.cms-page ol {
  padding-left: 1.5rem;
}

.cms-page ul li,
.cms-page ol li {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.cms-page ul li:before {
  content: "\2022";
  color: #007bff;
  font-weight: bold !important;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
/* end cms page styles */

/* start about us page */

.about-us-v2 {
  font-family: "Inter", sans-serif !important;
}

.about-us-bg {
  position: absolute;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #e8eef596;
}

.about-us-bg:before {
  content: "";
  position: absolute;
  overflow: hidden;
  border-radius: 70% 70% 0 0;
  background-color: #f1f5f9;
  width: 100%;
  height: 90px;
  margin: -46px auto 0;
}
/* end about us page */

/* start homepage styles */
@media (min-width: 1200px) {
  .homepage .laptop-banner {
    width: 500px;
  }
}
/* end homepage styles */

/* start custom drodown styles */
.custom-dropdown .drodown-trigger-label {
  color: #fff;
}

.custom-dropdown .drodown-trigger-label:hover {
  text-decoration: none;
  color: #fff;
}

.custom-dropdown .icon {
  margin-left: 5px;
}
/* end custom drodown styles */

/* start watchlist styles */
@media (min-width: 992px) {
  .watchlist-page .ant-tabs-nav {
    width: 600px;
    margin: auto auto 16px !important;
  }

  .watchlist-page .ant-tabs-nav .ant-tabs-tab {
    display: flex;
    text-align: center;
    width: 100px;
  }
}

.watchlist-page .ant-badge-count {
  background-color: #007bff;
}

.watchlist-page .ant-tabs-nav .ant-tabs-tab:nth-last-child(2) {
  margin-right: 0 !important;
}

.watchlist-item p {
  margin-bottom: 0;
}

.watchlist-item .titan-info h1 {
  line-height: 20px;
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .watchlist-item .avatar {
    width: 80px;
    height: 80px;
  }
}
/* end watchlist styles */

/* start mutual funds styles */

@media (min-width: 1200px) {
  .fund-widget {
    padding: 50px;
    /* box-shadow: 0 -2px 10px rgba(0, 0, 0, 0); */
  }
}

@media (min-width: 576px) {
  .fund-widget .piechart-wrapper,
  .fund-widget .piechart-wrapper .custom-piechart,
  .fund-widget .piechart-wrapper .custom-piechart svg {
    height: 280px !important;
    margin: 0 auto;
  }
}

.fund-widget .fund-widget-tabs {
  justify-content: center;
}

.fund-widget .fund-widget-tabs .nav-link {
  background-color: transparent !important;
  margin: 0px 25px;
}

@media (max-width: 575px) {
  .fund-widget .fund-widget-tabs {
    justify-content: space-between;
  }

  .fund-widget .fund-widget-tabs .nav-link {
    margin: 0px;
  }
}
/* end mutual funds styles */

/* start titans page styles */

.titans-page .ant-badge-count,
.ant-badge-dot,
.ant-badge .ant-scroll-number-custom-component {
  top: -8px;
  right: -12px;
}

/* start filter styles */
.titans-filters .filter-actions {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 20px;
}

.titans-filters .filter-actions .btn {
  width: 100%;
}

@media (min-width: 992px) {
  .titans-filters .filter-actions {
    display: block !important;
  }

  .titans-filters .filter-actions .btn {
    width: auto;
  }
}

@media (max-width: 991px) {
  .titans-filters .filter-dropdown {
    width: 100% !important;
  }
  .titans-filters .filter-dropdowns {
    flex-direction: column;
  }
}

/* end filter styles */
/* end titans page styles */

/* start titan page styles */
.titan-actions {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 20px;
}

.titan-actions .btn {
  width: 100%;
}

@media (min-width: 786px) {
  .titan-actions {
    display: block;
  }

  .titan-actions .btn {
    width: auto;
  }
}

.holdings .pagination {
  margin-top: 15px;
  margin-bottom: 15px;
}

.holdings .pagination .page-item .page-link {
  padding: 5px 15px;
}
/* end titan page styles */

/* start portfolios page styles */
.portfolios-filters .filter-actions {
  display: grid;
  /* grid-template-columns: repeat(auto-fill, minmax(110px, 1fr)); */
  grid-template-columns: auto auto auto;
  grid-gap: 20px;
}

.portfolios-filters .filter-actions .btn {
  width: 100%;
}

@media (min-width: 992px) {
  .portfolios-filters .filter-actions {
    display: block !important;
  }

  .portfolios-filters .filter-actions .btn {
    width: auto;
  }
}

/* end portfolios page styles */

/* start account page styles */
.account-page .account-layout {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
  font-family: "Inter";
}

.account-layout .left-menu {
  width: 100%;
}

.account-layout .left-menu .ant-menu-inline {
  border: none;
}

.account-layout .right-content {
  flex: 1;
}

.account-layout .left-menu .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  font-weight: 550;
}

@media screen and (max-width: 575px) {
  .account-page .account-layout {
    flex-direction: column;
  }

  .account-layout .left-menu {
    width: 100%;
    border: none;
  }
}

@media (min-width: 992px) {
  .account-page .account-layout {
    flex-direction: row;
  }

  .account-layout .left-menu {
    border-right: 1px solid #e9ebed;
    width: fit-content;
    min-width: 264px;
  }
}
/* end account page styles */

/* start research p[age styles */

/* .categories-modal .ant-modal-body {
  overflow-y: scroll;
  max-height: 360px;
} */

.select-category-btn .btn-icon {
  font-size: 12px;
  vertical-align: middle;
}

.research-page .editorial-content h3 {
  font-size: 1.25rem;
}

.research-page .editorial-content p,
.research-page .editorial-content a {
  text-align: justify;
}

.research-page .editorial-content strong {
  font-weight: 500 !important;
}

.research-page .editorial-content .chart_embed {
  text-align: center;
}

.research-page .editorial-content img {
  max-width: 100%;
  width: fit-content;
}

.research-page iframe,
.research-page object,
.research-page embed {
  max-width: 100%;
}

.research-page .heading-wrapper{
  text-align: left !important;
}
/* end research page styles */

/* start dashbaord page styles */

.pin-widget .pin-item {
  position: relative;
  overflow: hidden;
  border-bottom-width: 3px;
}

/* .pin-widget .item:last-child {
  margin-bottom: 0 !important;
} */

/* .pin-widget .pin-item .price-change .icon {
  width: 1.1rem;
} */

.pin-widget .pin-item .price-change {
  width: 80px;
  justify-content: center;
  text-align: center;
}

.pin-widget .pin-item .pin-btn.ant-btn svg {
  vertical-align: baseline;
}

/* 
.pin-widget .pin-item .hidden-pin {
  bottom: 0;
  left: 100%;
  right: 0;
  overflow: hidden;
  width: 0;
  transition: transform ease-in-out 0.5s;
  transform: translateX(100px);
  visibility: hidden;
  opacity: 0;
}

.pin-widget .pin-item:hover .hidden-pin {
  width: auto;
  left: 0;
  transition: transform ease-in-out 0.5s;
  transform: translateX(0);
  visibility: visible;
  opacity: 1;
}

.pin-widget .pin-item:hover .meta {
  transition: transform ease-in-out 0.5s;
  transform: translateX(-40px);
}

.pin-widget .pin-item .meta {
  transition: transform ease-in-out 0.5s;
} */

/* end dashbaord page styles */

/* start savings styles */
.saving-account-item .saving-account-img {
  object-fit: contain;
  max-width: 125px;
  max-height: 100px;
}

@media (max-width: 575px) {
  .saving-account-item p,
  .saving-account-item a {
    font-size: 14px;
  }
}
/* end savings styles */

/* start markets page styles */
.markets-page .stocks-items {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(3, 1fr);
}

.markets-page .markets-right .custom-table {
  margin-bottom: 15px !important;
}

@media (min-width: 992px) {
  .markets-page .right-side-separator {
    border-left: 1px solid #bad4ff;
  }
}
/* end markets page styles */

/* start news styles */

.news-item img {
  border-radius: 50%;
  width: 85px;
  height: 85px;
  object-fit: cover;
  padding: 10px 10px;
}

.news-item .source-name:after {
  display: inline-block;
  content: "\2022";
  color: #757575;
  padding: 0 4px;
}

#ticker-report-search.search-bar {
  min-width: 100%!important;
}

/* end news styles */

/* start ticker-report-table component styles */
table#ticker-report-table, #ticker-report-table th, #ticker-report-table td {
  border: 1px solid rgb(240, 240, 240);
  border-collapse: collapse;
}

#ticker-report-table th {
  background-color: #fafafa;
  font-weight: 400;
}

#ticker-report-table th, #ticker-report-table td {
  padding: 16px 24px;
  text-align: left;
}
/* end ticker-report-table component styles */
