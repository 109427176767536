.real-time-trading-alerts-component {
  padding: 24px 30px;
  background: #fff;
}

.real-time-trading-alerts-navigator {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 30px;
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  color: #6E6E70;
  cursor: pointer;
}

.real-time-trading-alerts-navigator svg {
  color: #6E6E70!important;
  fill: #6E6E70!important;
  margin-right: 5px;
  cursor: pointer;
}

.real-time-trading-alerts-navigator:hover {
  color: #00DC0A;
}

.real-time-trading-alerts-navigator:hover svg {
  color: #00DC0A!important;
  fill: #00DC0A!important;
}

.real-time-trading-alerts-title {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #121416;
  margin-bottom: 30px;
}

.real-time-trading-alerts-guide {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 150%;
  color: #6E6E70;
  margin-bottom: 30px;
}

.real-time-trading-alerts-section {
  margin-bottom: 60px;
}

.real-time-trading-alerts-section-title {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 20px;
  line-height: 25px;
  color: #121416;
  padding-bottom: 24px;
  border-bottom: 0.5px solid #E4E5E8;
}

.real-time-trading-alerts-section-item {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  color: #121416;
  padding: 20px 15px;
  border-bottom: 0.5px solid #E4E5E8;
  cursor: pointer;
}

.real-time-trading-alerts-section-item:hover {
  background: #E4E5E8;
}

.real-time-trading-alerts-section-item.active {
  background: #121416;
  color: #fff;
}

.real-time-trading-alerts-section-item-main {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}

.real-time-trading-alerts-section-item svg {
  color: #121416!important;
  fill: #121416!important;
}

.real-time-trading-alerts-section-item-more {
  border-left: 1px solid #6E6E70;
  padding-left: 16px;
  margin-top: 16px;
  padding-right: 30px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #FFFFFF;
  cursor: initial;
}

.real-time-trading-alerts-section-item.active svg {
  color: #fff!important;
  fill: #fff!important;
}

a.trading-alerts-anchor {
  color: #fff;
  text-decoration: underline;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-decoration-line: underline;
  color: #FFFFFF;
}

a.trading-alerts-anchor:hover {
  text-decoration: underline!important;
}

.alpha-options-telegram-button {
  background: #121416;
  box-shadow: 3px 3px 8px 1px rgba(29, 29, 30, 0.35);
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 11px;
  line-height: 14px;
  text-decoration: none;
  letter-spacing: -0.05em;
  padding: 8px 16px;
  color: #F4F4F5;
  display: inline-block;
  margin-bottom: 30px;
}

.alpha-options-telegram-button:hover {
  color: #F4F4F5;
}

@media (max-width: 600px) {
  .real-time-trading-alerts-component {
    padding: 20px;
  }
}
