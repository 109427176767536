
.security-page {
    .up {
        color: #0DAD60;
    }
    .down {
        color: #dc3545;
    }
    .card-body {
        overflow: hidden;
    }
    
}
.hide-scroll::-webkit-scrollbar {
    display: none;
}

.main-chart-container {
    // background: #FFFFFF;
    padding: 16px 0;
    padding-top: 0;
    padding-bottom: 0;
    // height: 100%;
}
  