.MMChartContainer {
  height: calc(72vh - 80px);
  /* min-height: 600px; */
  min-height: calc(100vh - 240px)!important;
}
.TVChartContainerOptions {
  height: calc(72vh - 290px);
  min-height: 480px;
}
/* chart grid css */
/* .MMChartContainer0 {
  height: calc(72vh - 290px);
  min-height: 1480px;
} */
.MMChartContainer0 {
  height:99%;
  min-height:99%;
}
.MMChartContainer1 {
  height:100%;
  min-height:100%;
}
.MMChartContainer2 {
  height:100%;
  min-height:100%;
}
.MMChartContainer3 {
  height:100%;
  min-height:100%;
}
.MMChartContainer4 {
  height:100%;
  min-height:100%;
}
.MMChartContainer5 {
  height:100%;
  min-height:100%;
}
/* new grid css*/
.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}
.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  margin-top: 34px !important;
  padding-bottom: 26px;
  background-color: #e7edf785;
  /* padding: 20px; */
}
.react-grid-item span {
  display: inline-block;
  
}
.react-grid-item.cssTransforms {
  transition-property: transform;
}
.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.react-grid-item.react-grid-placeholder {
  background: red;
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 30px;
  height: 30px;
  bottom: -3px;
  right: -4px;
  cursor: se-resize;
  z-index: 100000000000000;
}

.react-grid-item > .react-resizable-handle::after {
  content: "";
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 15px;
  height: 15px;
  border-right: 3px solid black;
  border-bottom: 3px solid black;
}
.grid-item {
  background: #212124;
  border: 1px solid #141414;
  display: flex;
  flex-direction: column;
}

.grid-item__title {
  font-size: 14px;
  color: #fff;
  text-align: center;
}
