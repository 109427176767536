.options-mentorship-page {
  padding: 20px 25px;
  user-select: none;
}

.options-mentorship-page-title {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 46px;
  color: #FFFFFF;
  margin-bottom: 10px;
}

.options-mentorship-page-description {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: #BCBCBC;
  margin-bottom: 28px;
}

.options-mentorship-page-plans-title {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #00DC0A;
  margin-bottom: 16px;
}

.options-mentorship-plans {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  margin: -6px;
  gap: 8px;
}

.options-mentorship-terms {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-transform: capitalize;
  color: #BCBCBC;
  margin-top: 24px;
  margin-bottom: 38px;
  cursor: pointer;
}

.options-mentorship-mentors {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #00DC0A;
  margin-bottom: 15px;
}

.options-mentorship-mentor {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 70px;
}

.options-mentorship-mentor-avatar {
  width: 87px;
  border-radius: 50%;
}

.options-mentorship-mentor-info {
  margin-left: 10px;
}

.options-mentorship-mentor-name {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #FFFFFF;
  margin-bottom: 5px;
}

.options-mentorship-mentor-job {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 20px;
  line-height: 25px;
  color: #BCBCBC;
}


body.light {
  .options-mentorship-page-title {
    color: #000000;
  }

  .options-mentorship-page-description {
    color: #7A7A7A;
  }

  .options-mentorship-page-plans-title {
    color: #007BFF;
  }

  .options-mentorship-terms {
    color: #7A7A7A;
  }

  .options-mentorship-mentors {
    color: #007BFF;
  }

  .options-mentorship-mentor-name {
    color: #000000;
  }

  .options-mentorship-mentor-job {
    color: #7A7A7A;
  }
}

@media (min-width: 993px) and (max-width: 1200px) {
  .options-mentorship-plan-features {
    max-height: 580px;
  }

  .options-mentorship-plan-buy-button {
    padding: 16px 32px!important;
  }
}

@media (max-width: 992px) {
  .options-mentorship-plans {
    flex-direction: column;
  }

  .options-metorship-plan {
    width: 100%;
    margin-bottom: 20px;
  }
}
