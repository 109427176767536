#root {
  background-color: #060203;
  height: 100%;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.main {
  /*min-height: 100vh;*/
  min-height: calc(100vh - 500px);
  flex: 1 0 auto;
}

.footer {
  flex-shrink: 0;
  /* flex: 1 1 auto; */
}

.fixed-page {
  /* min-height: 550px; */
  min-height: calc(100vh - 450px);
}

.page-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /*height: 100%;*/
  /* min-height: 100vh; */
  min-height: calc(100vh - 450px);
}

.main-layout {
  background-color: #f5f5f5;
  padding-top: 16px;
  padding-bottom: 50px;
  padding-top: 97px;
}

@media (max-width: 575px) {
  .main.main-layout {
    padding-top: 185px;
  }

  .main.custom-layout.auth {
    padding-top: 185px;
  }

  .main.about-us-layout.auth {
    padding-top: 185px;
  }
}

.public-layout {
  background-color: #f5f5f5;
  padding-top: 50px;
  padding-bottom: 50px;
}

.custom-layout {
  padding-top: 25px;
  padding-bottom: 100px;
}

.about-us-layout {
  padding-top: 25px;
  padding-bottom: 25px;
}

@media (min-width: 768px) {
  .about-us-layout {
    padding-top: 40px;
  }
}

body {
  font-size: 0.9rem !important;
  line-height: 1.7 !important;
}

a {
  font-size: 0.9rem;
  transition: all 0.3s ease;
  /* letter-spacing: 0.3px; */
}

a:hover {
  color: #007bff;
}

ul li ul {
  margin-top: 16px;
}

img {
  width: 100%;
}

/* hr {
  border-color: #e0e3eb !important;
} */

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400 !important;
  font-family: "Inter", sans-serif;
}

b,
strong {
  font-weight: 600 !important;
}

@media (max-width: 991px) {
  h2 {
    font-size: 1.75rem !important;
  }

  h3 {
    font-size: 1.5rem !important;
  }

  h4 {
    font-size: 1.3rem !important;
  }

  h5 {
    font-size: 1.2rem !important;
  }
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #bfbfbf !important;
  font-size: 0.9rem;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: #bfbfbf !important;
  font-size: 0.9rem;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: #bfbfbf !important;
  font-size: 0.9rem;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: #bfbfbf !important;
  font-size: 0.9rem;
}

/* default browser input autocomplete background color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

/* start accessibility styles */

/* :focus {
  outline: -webkit-focus-ring-color auto 1px !important;
} */

/* a:focus {
  box-shadow: 0 0 0 3px rgba(21, 156, 228, 0.4);
  transform: scale(1.08);
} */

/* end accessibility styles */


.ant-modal-header {
  border-bottom: none;
}

.ant-modal-body {
  padding: 0 24px 24px 24px;
}