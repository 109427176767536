.options-mentorship-thank-you {
  padding: 80px 0;
  text-align: center;
}

.custom-loader {
  color: #00DC0A!important;
}

.options-mentorship-loading-text {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #FFFFFF;
  margin-top: 60px;
}

.options-mentorship-thank-you-done-title {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #FFFFFF;
  margin-top: 20px;
}

.options-mentorship-thank-you-done-description {
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #FFFFFF;
  margin-top: 32px;
  margin-bottom: 50px;
}

.thank-you-strong-text {
  font-weight: 800;
  color: #00DC0A;
}

.back-to-dashboard-button {
  border-radius: 5px!important;
  border: 1px solid #045F08!important;
  font-family: 'Circular Std'!important;
  font-style: normal!important;
  font-weight: 450!important;
  font-size: 16px!important;
  line-height: 20px!important;
  color: #FFFFFF!important;
  padding: 8px 16px!important;
  text-transform: initial!important;
}

.thank-you-page-inner {
  padding: 40px 0;
  width: calc(100% - 20px);
  max-width: 550px;
  margin: 0 auto;
}

body.light {
  .custom-loader {
    color: #007BFF!important;
  }

  .options-mentorship-loading-text {
    color: #000;
  }

  .options-mentorship-thank-you-done-title {
    color: #000;
  }

  .options-mentorship-thank-you-done-description {
    color: #000;
  }

  .thank-you-strong-text {
    color: #007BFF;
  }

  .back-to-dashboard-button {
    background: #007BFF!important;
    border: none!important;
  }

  .custom-check-icon {
    g path {
      fill: #007BFF!important;
      color: #007BFF!important;
    }
  }

  .thank-you-page-inner {
    background: #FFFFFF;
    border-radius: 10px;
  }
}