.slider-tooltip {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.calendar-button {
  position: relative;
  top: -3px;
}

.dates {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #13111a;
}

.live-options-filter-container {
  display: flex;
  justify-content: flex-start;
  margin: 0;
  padding: 15px;
  width: 100%;
}

.MuiSvgIcon-fontSizeMedium {
  fill: white;
  color: white;
  font-size: 25px !important;
}

.pairsInput2 {
  background: black;
  padding: 10px 16px;
  flex-grow: 1;
  max-width: 500px;
  border: none;
  min-width: 250px;
  &:focus {
    outline: none;
  }
}

body.light #auto-complete-search {
  background: #79797B;
  border: 0;
  color: #fff;
  box-shadow: none;
}

body.light #bid-ask-auto-complete {
  background: #79797B;
  border: 0;
  color: #fff;
  box-shadow: none;
}

.live-options-sliders-wrapper {
  width: 100%;
  display: flex;
  max-width: 600px;
  column-gap: 25px;
  padding: 15px;
}

.live-options-inner-content {
  width: 100%;
  display: flex;
  padding: 15px 0px;
  flex-direction: row;
  align-items: center;
}

.live-option-search-inputs {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex: 1;
}

.input-wrapper {
  margin-right: 5px!important;
}

.live-option-card-box {
  display: flex;
  padding: 10px;
  background-color: #fff;
  align-items: center;
  width: calc(25% - 4px);
}

@media screen and (max-width : 1410px) {
  .live-options-inner-content {
    flex-direction: column;
  }

  .live-options-sliders-wrapper {
    max-width: none;
  }  

  .live-option-search-inputs {
    width: calc(100% - 220px);

    .autocomplete-lo {
      width: calc(50% - 10px);

      .input-wrapper {
        width: 100%;

        > div {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width : 720px) {
  .live-options-filter-container {
    flex-direction: column;
  }

  .live-option-search-inputs {
    width: 100%;
  }
}

@media screen and (max-width : 500px) {
  .live-option-search-inputs {
    flex-direction: column;
  }

  .input-wrapper {
    width: 100%!important;
    margin-bottom: 5px;
  }
  
  .live-option-search-inputs {
    .autocomplete-lo {
      width: 100%;
    }
  }
}

@media screen and (max-width: 1300px) {
  .live-option-card-box {
    width: calc(50% - 4px);
    margin-bottom: 8px;
  }
}

@media screen and (max-width: 600px) {
  .live-option-card-box {
    width: 100%;
    margin-bottom: 8px;
  }
}