.dashboard-container {
  .dashboard-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    .dashboard-header-title {
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
      letter-spacing: -0.04em;
    }
  }

  .card-grid-viewer {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .loader-notification {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }

  .mobile-dashboard-date {
    display: flex;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: -0.04em;
    color: #79797B;
    text-transform: inherit;
    margin-bottom: 16px;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.dashboard-date {
  display: none;
  text-transform: none;
}

.vertical-card-wrapper {
  width: calc((100% - 32px) / 3);
  margin-bottom: 16px;
  margin-left: 8px;
  margin-right: 8px;

  &:nth-child(3n + 1) {
    margin-left: 0;
  }

  &:nth-child(3n) {
    margin-right: 0;
  }
}

.api-type-selector {
  background: #242426;
  padding: 5px 20px;
  color: #fff;
  font-family: 'Circular Std';
  width: 130px;
  height: 30px;
  font-size: 16px;
  font-weight: 400;

  &:focus-visible {
    outline: none;
  }
}

@media (max-width: 1300px) {
  .vertical-card-wrapper {
    width: calc(50% - 8px);
    &:nth-child(2n) {
      margin-right: 0;
      margin-left: 8px;
    }

    &:nth-child(2n + 1) {
      margin-right: 8px;
      margin-left: 0;
    }
  }
}

@media (max-width: 1050px) {
  .dashboard-container .dashboard-header {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (max-width: 800px) {
  .dashboard-container .mobile-dashboard-date {
    display: flex;
    width: 100%;
  }

  .dashboard-date {
    display: none;
  }

  .vertical-card-wrapper {
    width: 100%;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
