.vertical-card {
  background: #fff;
  // padding: 24px;
  width: 100%;

  .card-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    background: #fff;
    border-radius: 0;
    border: none;
    padding: 18px 18px 0px 18px;
    .card-header-symbol {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 8px;

      .ticker {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        letter-spacing: -0.04em;
        color: #000000;
        cursor: pointer;
        left: 0;
      }

      .favorite-active {
        color: #00dc0a;
        cursor: pointer;
      }

      .favorite-inactive {
        color: #c7c7cc;
        cursor: pointer;
      }
    }

    .card-header-price {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .card-header-space-between {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .percentage {
        font-style: normal;
        font-weight: 450;
        font-size: 18px;
        line-height: 23px;
        letter-spacing: -0.04em;
        color: #8e8e93;
        margin-right: 12px;
      }

      .price {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 23px;
        letter-spacing: -0.04em;
        color: #000000;
      }
    }

    .currency-strength-star-outline-icon {
      fill: #C7C7CC!important;
      color: #C7C7CC!important;
      cursor: pointer;
    }

    .currency-strength-view-more {
      font-family: 'Circular Std';
      font-style: normal;
      font-weight: 450;
      font-size: 13px;
      line-height: 20px;
      color: #6E6E70;

      & svg {
        fill: #C7C7CC!important;
        color: #C7C7CC!important;
      }

      &:hover {
        color: #00dc0a!important;

        & svg {
          fill: #00dc0a!important;
          color: #00dc0a!important;
        }
      }
    }
  }

  .splitter {
    margin: 16px 0;
    width: 100%;
    height: 1px;
    background: #e5e5ea;
  }

  .card-info {
    padding: 0px 18px 0px 18px;
    .card-info-row {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;

      .card-info-label {
        font-style: normal;
        font-weight: 450;
        font-size: 18px;
        line-height: 23px;
        letter-spacing: -0.04em;
        color: #2f2e31;
        text-transform: capitalize;
      }

      .card-info-value {
        display: flex;
        font-style: normal;
        font-weight: 450;
        font-size: 18px;
        line-height: 23px;
        letter-spacing: -0.04em;
        color: #2f2e31;
      }

      .bullish {
        color: rgb(0, 255, 0, 0.7);
        text-transform: capitalize;
      }
      .very-bullish {
        color: rgb(34, 139, 34, 1);
        text-transform: capitalize;
      }
      .neutral {
        color: rgb(0, 0, 0, 0.5);
        text-transform: capitalize;
      }

      .bearish {
        color: rgb(255, 0, 0, 0.7);
        text-transform: capitalize;
      }
      .very-bearish {
        color: rgb(255, 0, 0, 1);
        text-transform: capitalize;
      }
    }
  }

  .card-footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    padding: 10px 18px 18px 18px;
    border-radius: 0;
    border: none;
    min-height: 68px;

    .price-info {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.02em;
      color: #2f2e31;
    }
  }
}

.vertical-card-list-mode {
  background: #fff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  min-width: 1250px;
  gap: 20px;

  .currency-strength-header-currency .ticker{
    left: 0;
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.04em;
    color: #000000;
  }

  .currency-strength-header-rate {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: -0.04em;
    color: #121416;
  }

  .currency-strength-header-change {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 450;
    font-size: 18px;
    line-height: 23px;    
    letter-spacing: -0.04em;
    color: #8E8E93;
  }

  .currency-strength-header-ai-sentiment .card-info-value {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 450;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: -0.04em;
  }

  .currency-strength-header-new-sentiment .card-info-value {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 450;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: -0.04em;
  }

  .currency-strength-header-bid, .currency-strength-header-ask, .currency-strength-header-volume {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 450;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: -0.04em;
    color: #121416;
  }
}
