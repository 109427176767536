// @import url("https://api.fontshare.com/v2/css?f[]=satoshi@1,900,700,500,301,701,300,501,401,901,400,2&display=swap");

.background-glow {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1;
  display: block;
  margin-right: auto;
  margin-left: auto;
  background-image: url(glow.png);
  background-position: 50% 0%;
  background-size: cover;
  opacity: 0.5;
}

.signin-container {
  font-family: Satoshi, sans-serif;
  z-index: 2;
  background: #060203;
  color: #FFFFFF;

  .signin-navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 28px 0;
    font-family: Satoshi, sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    padding-left: 40px;
    padding-right: 40px;
    max-width: 1360px;
    padding-right: 40px;
    padding-left: 40px;
    margin: 0 auto;
    z-index: 3;
    position: relative;

    .logo-MM {
      width: 74px;
    }

    .signin-navigations {
      display: flex;
      align-items: center;
      justify-content: space-between;
      
      .signin-resources {
        margin-right: 16px;
        cursor: pointer;
        position: relative;

        .signin-menulist-wrapper {
          display: none;
          position: absolute;
          left: auto;
          top: auto;
          right: 0%;
          bottom: auto;
          width: 440px;
          margin-right: 0px;
          padding: 3px;
          border-radius: 10px;
          background-image: linear-gradient(313deg, #045f08, #00dc0a);
          font-weight: 700;

          .signin-menulist {
            padding: 12px 4px;
            border-radius: 9px;
            background-color: #060203;
            z-index: 3;
            position: relative;

            .signin-menu-item {
              padding: 10px 16px;
              -webkit-box-pack: justify;
              -webkit-justify-content: space-between;
              -ms-flex-pack: justify;
              justify-content: space-between;
              -webkit-box-align: center;
              -webkit-align-items: center;
              -ms-flex-align: center;
              align-items: center;
              border-radius: 8px;
              -webkit-transition: color 300ms ease;
              transition: color 300ms ease;
              color: #fff;
              text-decoration: none;
              width: 33%;

              &:hover {
                background-color: hsla(0, 0%, 100%, 0.05);
                color: #00dc0a;
              }
            }
          }
        }

        &:hover {
          .signin-menulist-wrapper {
            display: block;
          }
        }
      }

      .signin-login-button {
        padding: 10px 16px;
        border: 1px solid hsla(0, 0%, 100%, 0.12);
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        font-weight: 700;
        
        .small-logo {
          width: 29px;
          background-color: #FFFFFF;
          border-radius: 50%;
          margin-right: 5px;
        }
      }
    }
  }

  .signin-content {
    padding-left: 40px;
    padding-right: 40px;
    // padding-top: 80px;
    // padding-bottom: 80px;
    max-width: 1360px;
    margin-left: auto;
    margin-right: auto;
    // min-height: 100vh;
    height: calc(100vh - 110px);
    display: flex;
    align-items: stretch;
    flex-direction: column;
    justify-content: center;
    z-index: 3;
    position: relative;

    .signin-content-tiny {
      max-width: 712px;
      margin: 0 auto;

      .signin-caption {
        margin-top: 0px;
        font-size: 60px;
        line-height: 70px;
        font-weight: 700;
        color: #FFFFFF;
        margin-bottom: 24px;
      }

      form {
        min-width: 400px;
        font-weight: 500;
        font-size: 16px;

        .input-field {
          height: 72px;
          margin-bottom: 0px;
          padding: 24px 0px;
          border-style: none none solid;
          border-width: 1px;
          border-color: #000 #000 hsla(0, 0%, 100%, 0.1);
          background-color: transparent;
          color: #fff;
          font-size: 16px;
          font-weight: 500;

          &:focus {
            outline: none;
          }

          &::placeholder {
            font-size: 16px;
          }
        }

        .input-row {
          width: 100%;
          margin-bottom: 20px;
        }

        .squaredTwo {
          padding-top: 16px;
          padding-bottom: 24px;
        }
      }
    }
  }

  .space-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .full-width {
    width: 100%;
  }

  #checkbox {
    display: flex;
    align-items: center;
    font-size: 16px;
  }

  .checkbox-field.smaller {
    padding-top: 16px;
    padding-bottom: 24px;
  }

  .checkbox-field {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 0px;
    padding-top: 32px;
    padding-bottom: 40px;
    padding-left: 0px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-weight: 600;
  }

  .w-checkbox {
    display: block;
    margin-bottom: 5px;

    &::before {
      content: ' ';
      display: table;
      -ms-grid-column-span: 1;
      grid-column-end: 2;
      -ms-grid-column: 1;
      grid-column-start: 1;
      -ms-grid-row-span: 1;
      grid-row-end: 2;
      -ms-grid-row: 1;
      grid-row-start: 1;
    }
  }

  .checkbox {
    width: 8%;
    height: 24px;
    margin-top: 0px;
    margin-right: 12px;
    margin-left: 0px;
    border-width: 1px;
    border: 1px solid hsla(0, 0%, 100%, 0.12);
    border-radius: 6px;
  }

  .checkbox-label {
    width: auto;
  }

  .w-form-label {
    display: inline-block;
    cursor: pointer;
    font-weight: normal;
    margin-bottom: 0px;
  }

  .checkbox.w--redirected-checked {
    background-color: #d846a5!important;
    background-size: 14px!important;
  }

  .w-checkbox-input--inputType-custom.w--redirected-checked {
    background-color: #3898ec;
    border-top-color: #3898ec;
    border-bottom-color: #3898ec;
    border-left-color: #3898ec;
    border-right-color: #3898ec;
    background-image: url(https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg);
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .border-button {
    border-radius: 8px;
    background-image: linear-gradient(305deg, #045f08, #00dc0a);
    color: #fff;
    font-weight: 700;
    padding: 3px;
    cursor: pointer;

    .login-button {
      padding: 16px 24px;
      border-radius: 6px;
      background-color: #060203;
      text-align: center;
      color: #fff;
      width: 100%;
      border: none;
      font-weight: 700;
      text-transform: initial;
    }
  }

  .small-text {
    font-size: 14px;
    line-height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sign-up {
    font-weight: 700;
    color: rgb(0,220,10);
    margin-left: 5px;
    font-size: 14px;
  }
}