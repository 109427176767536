.w-layout-grid {
    display: -ms-grid;
    display: grid;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
    grid-row-gap: 16px;
    grid-column-gap: 16px;
}


body {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    background-color: #000;
    font-family: Inter, sans-serif;
    color: #616d7e;
    font-size: 18px;
    line-height: 180%;
}

blockquote {
    margin-bottom: 32px;
    margin-left: 18px;
    padding: 0px 24px;
    border-left: 2px solid #3183ff;
    color: #fff;
    font-size: 20px;
    line-height: 175%;
    font-style: italic;
}

figure {
    margin-top: 40px;
    margin-bottom: 40px;
    border-radius: 15px;
}

figcaption {
    font-size: 16px;
    line-height: 160%;
    text-align: center;
}

.link-underlined {
    display: inline-block;
    border-bottom: 1px solid rgba(216, 223, 233, 0.8);
    -webkit-transition: border-color 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94),
        color 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: border-color 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94),
        color 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    color: rgba(216, 223, 233, 0.8);
    line-height: 150%;
    text-decoration: none;
}

.link-underlined:hover {
    border-bottom-color: #3183ff;
    color: #fff;
}

.navbar {
    position: fixed;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: auto;
    z-index: 99;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 30px 40px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    background-color: transparent;
}

.grid-navbar {
    z-index: 10;
    width: 100%;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    -ms-grid-columns: 1fr auto 1fr;
    grid-template-columns: 1fr auto 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
}

.nav.right {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.logo {
    /* height: 36px; */
}

.brand {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 5px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: -webkit-transform 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: -webkit-transform 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: transform 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: transform 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94),
        -webkit-transform 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.brand:hover {
    -webkit-transform: scale(0.94);
    -ms-transform: scale(0.94);
    transform: scale(0.94);
}

.nav-link {
    padding: 12px 28px;
    -webkit-transition: color 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: color 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    color: rgba(216, 223, 233, 0.8);
    font-size: 18px;
    line-height: 140%;
    font-weight: 400;
}

.nav-link:hover {
    color: #fff;
}

.nav-link.w--current {
    color: rgba(216, 223, 233, 0.8);
}

.nav-link.w--current:hover {
    color: #fff;
}

.nav-button {
    padding: 10px 28px;
    border-radius: 15px;
    background-color: #1d2838;
    -webkit-transition: background-color 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94),
        -webkit-transform 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: background-color 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94),
        -webkit-transform 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: transform 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94),
        background-color 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: transform 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94),
        background-color 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94),
        -webkit-transform 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    color: #fff;
    font-size: 18px;
    line-height: 140%;
    font-weight: 400;
    text-align: center;
}

.nav-button:hover {
    background-color: #3183ff;
    -webkit-transform: scale(0.94);
    -ms-transform: scale(0.94);
    transform: scale(0.94);
    color: white !important;
}

.section-hero {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    padding-top: 120px;
    padding-right: 40px;
    padding-left: 40px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.glow-top {
    position: absolute;
    left: 0%;
    top: -70px;
    right: 0%;
    z-index: -1;
    width: 100%;
    height: 500px;
    background-image: radial-gradient(circle farthest-side at 50% -170%, transparent, #000),
        -webkit-gradient(linear, left top, left bottom, from(#3183ff), to(transparent));
    background-image: radial-gradient(circle farthest-side at 50% -170%, transparent, #000),
        linear-gradient(180deg, #3183ff, transparent);
    -webkit-transform: translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
    -moz-transform: translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
    -ms-transform: translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
    transform: translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
}

.grid-button {
    justify-items: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    grid-auto-flow: column;
    grid-auto-columns: auto;
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    -ms-grid-columns: auto;
    grid-template-columns: auto;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
    -webkit-transform: translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
    -moz-transform: translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
    -ms-transform: translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
    transform: translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
}

.block-hero {
    position: relative;
    z-index: 10;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
}

.heading-hero {
    max-width: 960px;
    font-size: 92px;
    line-height: 103%;
}

.paragraph-large {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-width: 620px;
    margin-bottom: 42px;
    font-size: 20px;
    line-height: 176%;
    -webkit-transform: translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
    -moz-transform: translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
    -ms-transform: translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
    transform: translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
}

.button {
    padding: 16px 35px;
    border-radius: 15px;
    text-decoration: none;
    background-color: #3183ff;
    background-image: linear-gradient(150deg, transparent, #0751bf);
    -webkit-transition: background-color 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94),
        -webkit-transform 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: background-color 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94),
        -webkit-transform 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: background-color 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94),
        transform 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: background-color 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94),
        transform 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94),
        -webkit-transform 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    color: #fff;
    font-size: 18px;
    line-height: 140%;
    text-align: center;
}

.button:hover {
    background-color: #0751bf;
    color: white !important;
    -webkit-transform: scale(0.94);
    -ms-transform: scale(0.94);
    transform: scale(0.94);
}

.button.small {
    padding: 10px 26px;
    border-radius: 12px;
    font-size: 16px;
}

.figure-a-hero {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 500px;
    margin-top: 40px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-perspective: 1300px;
    perspective: 1300px;
}

.figure-a1 {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(220deg, rgba(0, 0, 0, 0.8), transparent),
        linear-gradient(50deg, #3183ff, transparent);
    opacity: 0.5;
    -webkit-filter: blur(5px);
    filter: blur(5px);
    -webkit-transform: translate3d(0px, 0px, 4vh);
    transform: translate3d(0px, 0px, 4vh);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.figure-a2 {
    position: absolute;
    width: 90%;
    height: 90%;
    background-image: linear-gradient(220deg, rgba(0, 0, 0, 0.8), transparent),
        linear-gradient(50deg, #3183ff, transparent);
    -webkit-transform: translate3d(0px, 0px, -13vh);
    transform: translate3d(0px, 0px, -13vh);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.figure-a3 {
    position: absolute;
    width: 60%;
    height: 70%;
    background-image: linear-gradient(220deg, rgba(0, 0, 0, 0.8), transparent),
        linear-gradient(50deg, #3183ff, transparent);
    opacity: 0.5;
    -webkit-filter: blur(20px);
    filter: blur(20px);
    -webkit-transform: translate3d(0px, 0px, -34vh);
    transform: translate3d(0px, 0px, -34vh);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.figure-block-a-hero {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 70%;
    height: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-perspective: 1300px;
    perspective: 1300px;
    -webkit-transform: rotateX(46deg) rotateY(17deg) rotateZ(-3deg);
    transform: rotateX(46deg) rotateY(17deg) rotateZ(-3deg);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.shadow-navbar {
    position: absolute;
    left: 0%;
    right: 0%;
    bottom: 0%;
    height: 160%;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#000), to(transparent));
    background-image: linear-gradient(180deg, #000, transparent);
    opacity: 0;
}

.section {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    padding: 0 40px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.section.footer {
    background-color: #060203;
    margin-top: auto;
}

.section.banner {
    padding-bottom: 50px;
}

.section.overflow {
    overflow: visible;
}

.content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    /* max-width: 1200px; */
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 2;
    max-width: 1360px;
    padding-right: 40px;
    padding-left: 40px;
    margin: 0 auto;
}

.grid-footer {
    width: 100%;
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    -ms-grid-columns: 2.4fr 1fr 1fr 1fr;
    grid-template-columns: 2.4fr 1fr 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
}

.block-footer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.logo-footer {
    height: 52px;
    width: auto;
}

.paragraph-footer {
    max-width: 390px;
    margin-bottom: 0px;
    font-size: 16px;
    line-height: 185%;
}

.heading-footer {
    margin-top: 4px;
    margin-bottom: 32px;
    color: #fff;
    font-size: 18px;
    line-height: 140%;
    font-weight: 500;
}

.link-footer {
    padding-top: 6px;
    padding-bottom: 6px;
    -webkit-transition: color 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: color 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    color: #616d7e;
    font-size: 16px;
    line-height: 140%;
    font-weight: 400;
    text-decoration: none;
}

.link-footer:hover {
    color: #fff;
}

.footer-down {
    width: 100%;
    margin-top: 100px;
}

.footer-top {
    padding-top: 120px;
    padding-bottom: 120px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    color: #fff;
    font-family: Satoshi, sans-serif;
}

.footer-top-subscribe {
    width: 40%;
    margin-right: 20px;
    margin-right: 60px;
    max-width: 420px;
}

.social-icons {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.social-icon {
    display: flex;
    width: 40px;
    height: 40px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 50%;
    background-color: #fff;
    margin-right: 10px;
}

.social-icon img {
    width: 14px;
}

.footer-top-menu {
    width: 20%;
    font-size: 16px;
}

.footer-menu-caption {
    font-size: 19px;
    font-weight: 700;
    margin-bottom: 20px;
}

.footer-menu-item {
    transition: color 200ms ease;
    color: hsla(0, 0%, 100%, 0.7);
    font-weight: 500;
    text-decoration: none;
    text-transform: capitalize;
    margin-bottom: 20px;
    cursor: pointer;
}

.subscribe-text {
    margin-top: 24px;
    font-size: 16px;
    font-weight: 500;
}

.newsletter-block {
    max-width: 420px;
    margin-top: 32px;
    font-family: Satoshi, sans-serif;
    color: #fff;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    margin-top: 32px;
    margin-bottom: 15px;
}

.block-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.block-row .input-field {
    height: 62px;
    margin-right: 16px;
    margin-bottom: 0px;
    padding-left: 0px;
    border-style: none none solid;
    border-width: 1px;
    border-color: #000 #000 hsla(0, 0%, 100%, 0.1);
    background-color: transparent;
    width: 100%;
    margin-right: 10px;
}

.block-row .input-field:focus {
    outline: none;
}

.block-row .input-field::placeholder {
    font-size: 16px;
}

.subscribe-button {
    cursor: pointer;
    display: flex;
    padding: 3px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    border-radius: 8px;
    background-image: linear-gradient(305deg, #045f08, #00dc0a);
    color: #fff;
    font-weight: 700;
}

.subscribe-button-content {
    padding: 16px 24px;
    border-radius: 6px;
    background-color: #060203;
    text-align: center;
}

.footer-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    font-family: Satoshi, sans-serif;
    line-height: 24px;
    padding-bottom: 56px;
}

.footer-reserved {
    transition: color 200ms ease;
    color: hsla(0, 0%, 100%, 0.7);
    font-weight: 500;
    text-decoration: none;
    text-transform: capitalize;
    background-image: linear-gradient(315deg, #045f08, #3ab434 36%, #00dc0a);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}