.landing__footer {
  display: flex;
  align-items: stretch;
  justify-content: space-between;

  .left-part {
    text-align: center;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    img {
      width: 43px;
    }

    .footer-brand-text {
      font-style: normal;
      font-weight: 600;
      font-size: 25.3733px;
      line-height: 48px;
      text-align: center;
      color: #FFFFFF;
    }
  }

  .right-part {
    position: relative;
    background: #141414;

    .right-part-row {
      display: flex;
      align-items: stretch;
      margin-bottom: 80px;
    }

    .right-part-row.last-child {
      padding-bottom: 32px!important;
      border-bottom: 1px solid #52525B!important;
      margin-bottom: 32px!important;
    }

    .right-part-row-half-block {
      width: 50%;
    }

    .right-part-row-half-block-title {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 100%;
      color: #FFFFFF;
    }

    .right-part-row-half-block-link {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: #A1A1AA;
      margin-top: 16px;
      cursor: pointer;
    }

    .copy-right-content {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      color: #71717A;
      padding-bottom: 60px;
    }
  }
}

@media screen and (max-width: 767px) {
  .landing__footer {
    flex-direction: column;

    .left-part, .right-part {
      background: #141414!important;
    }
  }
}

@media screen and (max-width: 500px) {
  .landing__footer .right-part .right-part-row {
    flex-direction: column;
    margin-bottom: 20px;
  }

  .landing__footer .right-part .right-part-row-half-block {
    width: 100%!important;
    text-align: center;
    margin-bottom: 32px;
  }
}
