.mentorship-promo-banner-page {
  background: url(../../assets/images/promo-banner.png);
  width: 100%;
  height: auto;
  border-radius: 5px;
  padding: 24px;
  background-size: cover;
  margin-bottom: 10px;
  border: 1px solid #CCCCCC;
}

.mentorship-promo-banner-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mentorship-promo-banner-texts {
  max-width: 760px;
  width: auto;
}

.mentorship-promo-banner-title {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: #FFFFFF;
}

.mentorship-promo-banner-info {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: #FFFFFF;
}

.promo-sign-up-button {
  background: linear-gradient(90deg, #00B208 1.67%, #4ADA51 100%)!important;
  border-radius: 5px!important;
  font-family: 'Circular Std'!important;
  font-style: normal!important;
  font-weight: 700!important;
  font-size: 16px!important;
  line-height: 20px!important;
  text-align: center!important;
  color: #FFFFFF!important;
  padding: 13px 50px!important;
  text-transform: initial!important;
}

@media (min-width: 993px) and (max-width: 1260px) {
  .mentorship-promo-banner-title {
    font-size: 24px;
    line-height: 32px;
  }

  .mentorship-promo-banner-info {
    font-size: 14px;
    line-height: 18px;
  }

  .mentorship-promo-banner-texts {
    margin-right: 20px;
  }

  .promo-sign-up-button {
    padding: 13px 0px !important;
    width: 300px;
  }
}

@media (max-width: 992px) {
  .mentorship-promo-banner-content {
    flex-direction: column;
    align-items: flex-start;
  }

  .mentorship-promo-banner-texts {
    max-width: 100%;
    margin-bottom: 20px;
  }
}

@media (max-width: 700px) {
  .mentorship-promo-banner-title {
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 5px;
  }
}