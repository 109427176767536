.live-stream-event-video {
  height: 50vh;
  width: 100%;
}

.tutorial-group-video {
  height: 50vh;
  width: 100%;
  border-radius: 10px;
  border: 1px solid;
}

.alpha-options-page {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  user-select: none;
}

.alpha-options-page-content-wrapper {
  padding: 16px;
  width: 70%;
  // min-height: calc(100vh - 90px);
}

.past-videos-content {
  padding: 16px;
  width: 30%;
  background: #29292B;
  min-height: calc(100vh - 90px);
}

.alpha-options-page-title {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #FFFFFF;
  margin-bottom: 10px;
}

.alpha-options-page-short-description {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.05em;
  color: #6E6E70;
  margin-bottom: 30px;
}

.alpha-option-page-navigator {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  margin-bottom: 30px;
}

.alpha-option-page-navItem {
  width: 25%;
  padding: 6px;
  text-align: center;
  cursor: pointer;
  color: #6E6E70;
  user-select: none;
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 48px;
}

.alpha-option-page-navItem .expand-icon {
  color: #6E6E70 !important;
  fill: #6E6E70 !important;
}

.alpha-option-page-navItem.active {
  background: #212122;
  color: #00DC0A;
}

.alpha-option-page-navItem.active .expand-icon {
  color: #00DC0A !important;
  fill: #00DC0A !important;
}

.alpha-options-page-tutorials-section {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
}

.alpha-options-page-tutorial-section {
  padding: 20px;
  background: #fff;
  width: calc(50% - 5px);
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.alpha-options-page-tutorial-section-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.alpha-options-page-tutorial-section-header .play-circle-icon {
  fill: #000 !important;
  color: #000 !important;
  width: 48px !important;
  height: 48px !important;
}

.alpha-options-page-tutorial-section-header-info {
  margin-left: 15px;
}

.alpha-options-page-tutorial-section-title {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  color: #121416;
  margin-bottom: 5px;
}

.alpha-options-page-tutorial-section-video-info {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: -0.05em;
  color: #6E6E70;
}

.alpha-options-page-tutorial-section-body {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.05em;
  color: #6E6E70;
  margin-bottom: 15px;
}

.alpha-options-page-tutorial-section-view-video {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  color: #00DC0A;
}

.alpha-options-page-tutorial-section-view-video .arrow-circle-right-icon {
  color: #00DC0A !important;
  fill: #00DC0A !important;
  margin-left: 5px;
}

.alpha-options-page-live-streams-content {
  padding: 24px 30px;
  background: #fff;
}

.alpha-options-page-live-streams-header {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #081131;
  margin-bottom: 15px;
}

.alpha-options-page-live-streams-info {
  margin-top: 24px;
}

.alpha-options-page-live-streams-info-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 24px;
}

.live-streams-author {
  width: 48px;
  height: 48px;
  margin-right: 10px;
}

.alpha-options-page-live-streams-author-name {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #121416;
}

.alpha-options-page-live-streams-author-job {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  line-height: 18px;
  color: #BCBCBC;
}

.alpha-options-page-live-streams-info-content {
  padding-bottom: 40px;
  border-bottom: 0.5px solid #E4E5E8;
}

.alpha-options-page-live-streams-title {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: #121416;
  margin-bottom: 8px;
}

.alpha-options-page-live-streams-description {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  color: #6E6E70;
}

.alpha-options-page-live-streams-schedule {
  padding-top: 40px;
  padding-bottom: 26px;
}

.alpha-options-page-live-streams-schedule-title {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  color: #121416;
  margin-bottom: 10px;
}

.alpha-options-page-live-streams-schedule-list {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}

.alpha-options-page-live-streams-schedule-day-item {
  width: calc(20% - 2px);
}

.alpha-options-page-live-streams-schedule-day {
  background: #F7F8F7;
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #6E6E70;
  padding: 5px;
}

.alpha-options-page-live-streams-schedule-time {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  color: #6E6E70;
  text-align: center;
  margin-top: 7px;
}

.current-video-group-navigator {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 30px;
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  color: #6E6E70;
  cursor: pointer;

  &:hover {
    color: #00DC0A;

    .current-video-group-crumb {
      color: #00DC0A!important;
      fill: #00DC0A!important;
    }
  }
}

.current-video-group-crumb {
  color: #6E6E70!important;
  fill: #6E6E70!important;
  margin-right: 5px;
  cursor: pointer;
}

.beginner-track-text {
  padding-left: 5px;
  margin-top: 30px;
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #121416;
}

.building-text {
  margin-top: 30px;
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 150%;
  color: #6E6E70;
  padding-bottom: 40px;
  border-bottom: 0.5px solid #E4E5E8;
}
.videos-container {
  padding: 30px;
  background-color: white;
}

.summary-bg {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 20px;
  line-height: 25px;
  margin-top: 30px;
  color: #121416;
}

.completely-text {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 150%;
  color: #6E6E70;
  margin-top: 30px;
  display: flex;
}

.objective-text {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 20px;
  line-height: 25px;
  margin-top: 30px;
  color: #121416;
}

.ul-videos {
  list-style: none;
  margin-top: 30px;
  margin-bottom: 0;
  padding-bottom: 40px;
  border-bottom: 0.5px solid #E4E5E8;
}
.list-item-videos::before {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  content: "• ";
  color: #00DC0A;
}

.videos-text {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 20px;
  line-height: 25px;
  margin-top: 30px;
  color: #121416;
}

.video-table-container {
  margin-top: 40px;
  border: 0.5px solid #E4E5E8;
}

.video-table-row {
  padding: 8px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.5px solid #E4E5E8;
  cursor: pointer;
}

.video-table-row:last-child {
  border-bottom: none;
}

.video-table-row:hover {
  background: #E7E7E7;
}

.video-table-row .MuiIconButton-root {
  padding: 0!important;
}

.w-18 {
  width: 18px;
}

.table-video-text {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  color: #121416;
  margin-left: 10px;
}

.time-stamp {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 11px;
  line-height: 14px;
  text-align: right;
  letter-spacing: -0.05em;
  color: #6E6E70;
  margin-right: 14px;
}

.align-center {
  align-items: center;
}

.radio-video .MuiSvgIcon-fontSizeMedium {
  fill: #BCBCBC!important;
  color: #BCBCBC!important;
}

.radio-video .MuiSvgIcon-fontSizeMedium.checked-icon {
  fill: #00DC0A!important;
  color: #00DC0A!important;
}

.arrow-circle-right-icon {
  margin-left: 5px;
}

.arrow-circle-left-icon {
  margin-right: 5px;
}

.alpha-options-qa-chat-widget {
  padding-top: 165px;
  height: 100%;
}

.qa-chat-wrapper {
  background: #171817!important;
}

body.light {
  .alpha-options-page-title {
    color: #000;
  }

  .past-videos-content {
    background-color: #999;
  }

  .real-time-trading-alerts-section-item.active {
    background: #999;
  }

  .past-videos-dropdown {
    background: #cccccc;
  }

  .past-videos-dropdown-text {
    color: #fff;
  }

  .past-videos-dropdown svg {
    fill: #FFFFFF!important;
    color: #FFFFFF!important;
  }

  .qa-chat-wrapper {
    background: #CCCCCC!important;
  }
}

.tutorial-video-group-footer {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tutorial-video-group-footer-item {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  text-align: right;
  color: #6E6E70;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  & svg {
    color: #6E6E70!important;
    fill: #6E6E70!important;
  }

  &:hover {
    color: #00DC0A;

    & svg {
      color: #00DC0A!important;
      fill: #00DC0A!important;
    }
  }
}

@media (min-width: 993px) and (max-width: 1200px) {
  .alpha-options-page {
    flex-direction: column;
  }

  .alpha-options-page-content-wrapper {
    width: 100%;
  }

  .past-videos-content {
    width: 100%;
  }

  .alpha-options-qa-chat-widget {
    padding-top: 0;
    height: 600px;
  }
}

@media (min-width: 850px) and (max-width: 992px) {
  .past-videos-dropdown {
    padding: 12px;
    margin-bottom: 30px;
  }

  span.past-videos-dropdown-text {
    font-size: 20px;
  }

  .past-videos-big-title {
    font-size: 20px;
    margin-bottom: 30px;
  }
  
  .past-videos-small-title {
    font-size: 12px;
    margin-bottom: 30px;
  }

  .past-videos-blue-title {
    font-size: 12px;
  }

  .alpha-options-page-live-streams-schedule-day {
    font-size: 12px;
  }

  .alpha-options-page-live-streams-schedule-time {
    font-size: 12px;
  }

  .alpha-options-qa-chat-widget {
    padding-top: 165px;
  }
}

@media (max-width: 849px) {
  .alpha-options-page {
    flex-direction: column;
  }

  .alpha-options-page-content-wrapper {
    width: 100%;
  }

  .past-videos-content {
    width: 100%!important;
  }

  .alpha-options-page-live-streams-schedule-day {
    font-size: 12px;
  }

  .alpha-options-page-live-streams-schedule-time {
    font-size: 12px;
  }

  .alpha-options-qa-chat-widget {
    padding-top: 0;
    height: 600px;
  }
}

@media (max-width: 600px) {
  .alpha-options-page-tutorial-section {
    width: 100%;
  }

  .alpha-option-page-navigator {
    flex-wrap: wrap;
  }

  .alpha-option-page-navItem {
    width: 50%;
  }

  .alpha-options-page-live-streams-content {
    padding: 20px;
  }

  .alpha-options-page-real-time-trading-info {
    width: 100%;
  }
}
