.fallback-span {
  position: relative;
  top: 45px;

  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 100%;
  /* identical to box height, or 28px */

  text-align: center;
  letter-spacing: -0.02em;

  /* Gray 1 */

  color: #141414;
}
.oops {
  position: relative;
  top: 67px;
  left: 53px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  text-align: center;

  color: #a2a3a5;

  /* Inside Auto Layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 16px 0px;
}

body.dark .fallback-span {
  color: #ffffff;
}