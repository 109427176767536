.row-title {
    font-size: 14px;
     color: #B7B4C7 !important;
     white-space:nowrap;
}
.row-wrapper{
    border-bottom: 1px solid rgba(226, 232, 240, 0.5) !important ;
    padding: 5px;
}
.row-wrapper2{
    border-bottom: 1px solid rgba(226, 232, 240, 0.5) !important ;
    padding: 2px;
}
.left-title{
    float: left;
    color: #B7B4C7 !important;
}
.right-title{
    color: black;
    float: right;
    font-weight: 400;
}
.left-title2 {
float: left;
font-family: Poppins;
font-style: normal;
font-weight: normal;
font-size: 12px;

/* color: #64748B; */
}
.right-title2 {
float: right;
font-family: Poppins;
font-style: normal;
font-weight: 600;
font-size: 12px;
/* color: #64748B; */
white-space: nowrap;
}
.adjust {
    position: relative;
    left: 15px;
    padding-right: 12px;
}