.normInt {
    color: black;
    display: inline-block;
  }
  @keyframes rotate-down2 {
    0% {
      color: red;
      transform: translateY(16px);
      opacity: 1;
    }
    25% {
      color: red;
      opacity: 1;
      transform: translateY(8px);
    }
    100% {
      color: red;
      transform: translateY(0px);
      opacity: 1;
    }
  }
  @keyframes rotate-down {
    0% {
      color: red;
      transform: translateY(16px);
      opacity: 1;
    }
    25% {
      color: red;
      opacity: 1;
      transform: translateY(8px);
    }
    100% {
      color: red;
      transform: translateY(0px);
      opacity: 1;
    }
  }
  
  @keyframes rotate-up {
    0% {
      color: green;
      transform: translateY(-16px);
      opacity: 1;
    }
    25% {
      color: green;
      opacity: 1;
      transform: translateY(-8px);
    }
    100% {
      color: green;
      transform: translateY(0px);
      opacity: 1;
    }
  }
  @keyframes rotate-up2 {
    0% {
      color: green;
      transform: translateY(-15px);
      opacity: 1;
    }
    25% {
      color: green;
      opacity: 1;
      transform: translateY(-8px);
    }
    100% {
      color: green;
      transform: translateY(0px);
      opacity: 1;
    }
  }
  .redIntDown {
    display: inline-block;
    animation: rotate-down 0.6s;
  }
  .redIntDown2 {
    display: inline-block;
    animation: rotate-down2 0.6s;
  }
  .greenIntUp {
    display: inline-block;
    animation: rotate-up 0.5s;
  }
  .greenIntUp2 {
    display: inline-block;
    animation: rotate-up2 0.5s;
  }
  
  .redIntUp {
    display: inline-block;
    animation: rotate-up 0.5s;
  }
  
  .greenIntDown {
    display: inline-block;
    animation: rotate-down 0.5s;
  }
  