.up-strikes {
  /*font-family: THICCCBOI;*/
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #9CA3AF;
  order: 0;
  flex-grow: 0;
  margin: 0px 8px;
}
.current-strike {
  position: relative;
  top: 12px;
  white-space: nowrap;
  /*font-family: THICCCBOI;*/
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  color: #4b5563;
  text-align: center;
}
.symbol {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin-right: 12px;
  /* identical to box height */

  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.calls {
  .strike-higher-light {
    background: white;
  }
  .strike-lower-light {
    background: #dcbdf1 !important;   
  }
}

.calls {
  .strike-higher-dark {
    background: white;
  }
  .strike-lower-dark {
    background: #14031f !important;   
  }
}

.puts {
  .strike-higher-dark {
    background: #14031f !important;   
  }
  .strike-lower-dark {
    background: white;
  }
}


.puts {
  .strike-higher-light {
    background: #dcbdf1 !important;   
  }
  .strike-lower-light {
    background: white;
  }
}



.option-scanner {
  .MuiAutocomplete-popper {
    z-index: 1000;
  }
  .MuiPickersDay-today {
    border: none !important;
  }
  .data-tables {
    > h5 {
      font-family: POPPINS;
      font-weight: bold !important;
      color: #000000;
      font-size: 18px;
      line-height: 21px;
      margin: 0;
    }
  }
}

.option-scanner-filter-box {
  // background-color: rgba(255, 255, 255, 0.5);
  border-radius: 16px;
  margin-bottom: 16px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard-datepicker {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  input {
    border: none;
    background: transparent;
    &:focus {
      outline: none;
    }
  }
  .ndatepicker {
    height: 44px;
    background: #f1f5f9;
    border-radius: 24px;
    padding: 8.5px 15px;

    svg {
      path {
        stroke: #555555;
      }
    }
  }
}

.option-scanner-date-area {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.option-scanner-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 50px;
}
.option-scanner-timelines {
  display: flex;
  justify-content: flex-start;
  margin-left: 50px;
  position: relative;
  top: -30px;
}

.option-scanner-timeline-up-light.active {
  background: #5eeecd;
  border-radius: 12px;
  padding: 4px 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #555555;
  margin-left: 16px;
  margin-right: 16px;
  position: relative;
  top: -4px;
}
.option-scanner-timeline-up-dark.active {
  background: #5eeecd;
  border-radius: 12px;
  padding: 4px 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #555555;
  margin-left: 16px;
  margin-right: 16px;
  position: relative;
  top: -4px;
}
.option-scanner-timeline-down-light.active {
  background: #b8040a;
  border-radius: 12px;
  padding: 4px 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ede7e7;
  margin-left: 16px;
  margin-right: 16px;
  position: relative;
  top: -4px;
}
.option-scanner-timeline-down-dark.active {
  background: #b8040a;
  border-radius: 12px;
  padding: 4px 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ede7e7;
  margin-left: 16px;
  margin-right: 16px;
  position: relative;
  top: -4px;
}
.option-scanner-timeline-down-dark {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #ffffffab;
  cursor: pointer;
  padding: 0 16px;
}
.option-scanner-timeline-down-light {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #100e0eab;
  cursor: pointer;
  padding: 0 16px;
}
.option-scanner-timeline-up-dark {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #ffffffab;
  cursor: pointer;
  padding: 0 16px;
}
.option-scanner-timeline-up-light {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  padding: 0 16px;
}

.option-scanner-button {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #b7b4c7;
  cursor: pointer;
  padding: 0 16px;
}

.separator-borders {
  width: 1px;
  background: rgba(183, 180, 199, 0.5);
  height: 30px;
}

.option-scanner-button.active {
  background: #5eeecd;
  border-radius: 12px;
  padding: 4px 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #555555;
  margin-left: 16px;
  margin-right: 16px;
}

.option-scanner-symbol-selector {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.option-scanner-price-area {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.option-scanner-robin-hood-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 1570px) {
  body.option-chain {
    .dashboard-datepicker {
      .symbol {
        margin-left: 0 !important;
      }
    }

    .option-scanner-filter-box {
      flex-direction: column;

      div.option-scanner-filter-box-part1 {
        width: 100%;
        margin-bottom: 20px;
      }

      .option-scanner-date-area {
        width: 100%;
      }
    }
  }

  body.option-chain.sidebar-enable {
    .option-scanner-filter-box {
      flex-direction: row;

      div.option-scanner-filter-box-part1 {
        width: 100%;
        margin-bottom: 0;
      }

      .option-scanner-date-area {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 1399px) {
  body.option-chain.sidebar-enable {
    .dashboard-datepicker {
      .symbol {
        margin-left: 0 !important;
      }
    }

    .option-scanner-filter-box {
      flex-direction: column;

      div.option-scanner-filter-box-part1 {
        width: 100%;
        margin-bottom: 20px;
      }

      .option-scanner-date-area {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  body.option-chain {
    .option-scanner-filter-box-part1 {
      flex-direction: column;
    }

    .option-scanner-symbol-selector {
      margin-bottom: 20px;
    }

    .dashboard-datepicker {
      justify-content: center;
      margin-bottom: 20px;
    }

    .option-scanner-date-area {
      flex-direction: column;
    }

    .option-scanner-buttons {
      width: 100%;
      margin-left: 0;
    }
  }
}
body {
  &.dark {
    .Mui-disabled {
      background-color: #202020 !important;
      color: grey !important;
    }
    .MuiPickersDay-root {
      background-color: rgb(38 16 63);
      color: white;
    }
    // .MuiTypography-root{
    //   color: white;
    // }
    .MuiSvgIcon-fontSizeMedium{
      fill: white;
      color: white;
    }
    .MuiCalendarPicker-root{
      background: #202020;
      color: white;
    }
    .ndatepicker{
      background: #202020;
      color: white;
    }
    .MuiAutocomplete-paper {
      background-color: #202020 !important;
      .Mui-focused {
        background-color: #3A3A3D !important;
      }
      .MuiAutocomplete-option{
        background-color: rgb(16 9 22 / 8%);
        color: white;
        border: 1px solid black;
      }
      .MuiAutocomplete-noOptions{
        color: white;
      }
      .MuiAutocomplete-loading{
        color: white;
      }
    }
  }
}
