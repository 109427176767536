.minuteContainer {
  width: 100%;
  height: 100%;
  position: relative;
  .upperContainer {
    width: 100%;
    display: flex;
    flex-direction: column;

    .filtersContainer {
      width: 100%;
      display: flex;
      padding: 16px;
      justify-content: space-between;
      flex-wrap: nowrap;

      .filters {
        display: flex;
        flex-wrap: wrap;
      }
      .pairsInput {
        background: #1f1f21;
        padding: 10px 16px;
        flex-grow: 1;
        max-width: 500px;
        border: none;
      }
    }
    .candleContainer {
      width: 100%;
      display: flex;
      align-items: center;
      margin: 10px 0px;
      .paddedContainer {
        width: 100%;
        display: flex;
        column-gap: 25px;
        padding: 16px;
        flex-wrap: wrap;
        justify-content: space-between;
        .leftContainer {
          flex-grow: 1;
          display: flex;
        }
        .rightContainer {
          flex-grow: 1;
          display: flex;
          justify-content: flex-end;
          column-gap: 25px;
          .dateContainer {
            display: flex;
            align-items: center;
            .dateText {
              font-family: "Circular Std";
              font-style: normal;
              font-weight: 700;
              font-size: 18px;
              line-height: 23px;
              margin-right: 16px;
              /* identical to box height */

              letter-spacing: -0.04em;

              /* white */

              color: #ffffff;
            }
            .dateInput {
              padding: 10px 16px;
              flex-grow: 1;
              max-width: 500px;
              border: none;
            }
          }
          .tabContainer {
            .tab {
              padding: 8px;
              background: #121416;
              width: 133px;
              border: none;
            }
            .activeTab {
              color: #00dc0a;
              padding: 8px;
              background: #242426;
              width: 133px;
              border: none;
            }
          }
        }
      }
    }
  }

  .tableCont {
    width: 100%;
  }
  .tableHeader {
    display: flex;
    justify-content: space-around;
    background-color: aqua;
  }

  .minute-card-wrapper {
    display: flex;
    padding: 16px;
    width: 100%;
    align-items: stretch;
    justify-content: space-between;

    .minute-card {
      display: flex;
      padding: 10px;
      background-color: #fff;
      align-items: center;
      width: calc(25% - 8px);
      justify-content: space-between;

      .minute-card-title {
        font-family: "Circular Std";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.04em;
        color: #000000;
        word-spacing: -0.4em;
      }
    }
  }

  .slider1 {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .slider2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 30px;
  }

  .minute-scanner-ticker {
    color: #141a14;
  }
}

@media (max-width: 1299px) {
  .minuteContainer .upperContainer .filtersContainer {
    flex-direction: column;
  }

  .minuteContainer .upperContainer .filtersContainer .pairsInput {
    margin-top: 16px;
    width: 100%;
  }

  .minuteContainer .minute-card-wrapper .minute-card .minute-card-title {
    font-size: 16px;
    line-height: 22px;
  }
}

@media (max-width: 992px) {
  .minuteContainer .minute-card-wrapper .minute-card .minute-card-title {
    font-size: 18px;
    line-height: 24px;
  }
}

@media (max-width: 800px) {
  .minuteContainer .minute-card-wrapper {
    flex-wrap: wrap;
  }

  .minuteContainer .minute-card-wrapper .minute-card {
    width: calc(50% - 8px);
    margin-bottom: 16px;
  }

  .minuteContainer .upperContainer .candleContainer .paddedContainer {
    flex-direction: column;
  }

  .minuteContainer
    .upperContainer
    .candleContainer
    .paddedContainer
    .rightContainer {
    justify-content: flex-start;
  }

  .minuteContainer .upperContainer .filtersContainer .filters {
    overflow: auto;
    padding-bottom: 16px;
  }

  .minuteContainer .upperContainer .filtersContainer .pairsInput {
    margin-top: 8px;
  }
}

@media (max-width: 500px) {
  .minuteContainer .minute-card-wrapper {
    flex-wrap: wrap;
  }

  .minuteContainer .minute-card-wrapper .minute-card {
    width: 100%;
    margin-bottom: 16px;
  }

  .minuteContainer
    .upperContainer
    .candleContainer
    .paddedContainer
    .leftContainer {
    flex-direction: column;
  }

  .minuteContainer .slider2 {
    margin-left: 0;
  }

  .minuteContainer
    .upperContainer
    .candleContainer
    .paddedContainer
    .rightContainer {
    margin-top: 16px;
  }
}


body.light .pairsInput {
  background: #79797B!important;
  color: #ffffff;
}
