.loader-notification {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  color: #FFFFFF;
}

.forex-activity-ticker {
  a {
    color: #141a14;
    font-weight: bold;
    cursor: pointer;
  }
}

body.light .forex-activity-ticker {
  a {
    color: #FFFFFF;
  }
}
