.alpha-options-sign-up {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  user-select: none;
}

.alpha-options-sign-up-left {
  width: 70%;
  background: black;
}

.alpha-options-sign-up-right {
  width: 30%;
  background: #29292B;
}

.alpha-options-sign-up-header {
  padding: 72px 46px;
  background-image: url(../../../assets/images/alpha-sign-up-background.png);
  background-size: cover;
  background-position: initial;
}

.alpha-options-sign-up-title {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 900;
  font-size: 64px;
  line-height: 81px;
  letter-spacing: -0.02em;
  color: #FFFFFF;
  margin-bottom: 8px;
}

.alpha-options-sign-up-info {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 48px;
  line-height: 61px;
  color: #FFFFFF;
}

.alpha-options-sign-up-info-1 {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 61px;
  color: #00DC0A;
}

.alpha-options-sign-up-button {
  margin-top: 75px!important;
  background: linear-gradient(90deg, #00B208 1.67%, #4ADA51 100%)!important;
  border-radius: 5px!important;
  font-family: 'Circular Std'!important;
  font-style: normal!important;
  font-weight: 700!important;
  font-size: 24px!important;
  line-height: 30px!important;
  color: #121416!important;
  text-transform: inherit!important;
  padding: 16px 75px!important;
  -webkit-box-shadow: 0px 16px 18px 0px rgba(0,220,10,0.4)!important;
  -moz-box-shadow: 0px 16px 18px 0px rgba(0,220,10,0.4)!important;
  box-shadow: 0px 16px 18px 0px rgba(0,220,10,0.4)!important;
}

.alpha-options-sign-up-trading-section {
  padding: 0 46px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  top: -30px;
}

.alpha-options-sign-up-unlock-text {
  width: calc(100% - 300px);
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #FFFFFF;
  padding-bottom: 32px;
  border-bottom: 1px solid #6E6E70;
}

img.alpha-options-sign-up-trading {
  width: 300px;
  position: absolute;
  top: -180px;
  right: 0;
}

.alpha-options-sign-up-items {
  padding: 0 46px 10px 46px;
}

.alpha-options-sign-up-item-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 22px;
}

.alpha-options-sign-up-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  width: calc(50% - 20px);
  color: #FFFFFF;
}

.alpha-options-sign-up-item-icon {
  background: #222228;
  border-radius: 10px;
  width: 29px;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
}

.alpha-options-sign-up-price-row {
  padding: 0 46px;
}

.alpha-options-sign-up-price-row-inner {
  padding: 32px 0;
  border-top: 1px solid #6E6E70;
  border-bottom: 1px solid #6E6E70;
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
}

.alpha-options-sign-up-price {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #FFFFFF;
}

.alpha-options-sign-up-year {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #BCBCBC;
  margin-left: 8px;
}

.alpha-options-sign-up-button-small {
  background: linear-gradient(90deg, #00B208 1.67%, #4ADA51 100%)!important;
  border-radius: 5px!important;
  font-family: 'Circular Std'!important;
  font-style: normal!important;
  font-weight: 450!important;
  font-size: 20px!important;
  line-height: 25px!important;
  color: #121416!important;
  text-transform: inherit!important;
  padding: 10px 40px!important;
  -webkit-box-shadow: 0px 16px 18px 0px rgba(0,220,10,0.4)!important;
  -moz-box-shadow: 0px 16px 18px 0px rgba(0,220,10,0.4)!important;
  box-shadow: 0px 16px 18px 0px rgba(0,220,10,0.4)!important;
  margin-left: 25px !important;
}

.alpha-options-sign-up-footer {
  padding: 32px 46px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.alpha-options-sign-up-footer-left {
  width: calc(100% - 250px);
}

img.alpha-options-sign-up-footer-img {
  width: 250px;
}

.alpha-options-sign-up-footer-title {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #FFFFFF;
  margin-bottom: 20px;
}

.alpha-options-sign-up-footer-description {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 150%;
  color: #FFFFFF;
  margin-bottom: 20px;
}

.alpha-options-sign-up-button-outline {
  font-family: 'Circular Std'!important;
  font-style: normal!important;
  font-weight: 450!important;
  font-size: 16px!important;
  line-height: 20px!important;
  color: #FFFFFF!important;
  padding: 10px 40px!important;
  border: 1px solid #045F08!important;
  text-transform: inherit!important;

}

@media (min-width: 993px) and (max-width: 1200px) {
  .alpha-options-sign-up {
    flex-direction: column;
  }

  .alpha-options-sign-up-left {
    width: 100%;
  }

  .alpha-options-sign-up-right {
    width: 100%;
  }
}

@media (max-width: 992px) {
  .alpha-options-sign-up-title {
    font-size: 47px;
    line-height: 61px;
  }

  .alpha-options-sign-up-info {
    font-size: 34px;
    line-height: 47px;
  }

  .alpha-options-sign-up-info-1 {
    font-size: 34px;
    line-height: 47px;
  }

  .alpha-options-sign-up-button {
    padding: 16px 50px!important;
  }

  .alpha-options-sign-up-unlock-text {
    width: calc(100% - 200px);
  }

  img.alpha-options-sign-up-trading {
    width: 250px;
    position: absolute;
    top: -140px;
    right: 20px;
  }
}

@media (max-width: 820px) {
  .alpha-options-sign-up {
    flex-direction: column;
  }

  .alpha-options-sign-up-left {
    width: 100%;
  }

  .alpha-options-sign-up-right {
    width: 100%;
  }
}


@media (max-width: 600px) {
  .alpha-options-sign-up-button {
    padding: 12px 20px !important;
  }

  .alpha-options-sign-up-unlock-text {
    font-size: 21px;
    line-height: 25px;
    width: 100%;
  }

  .alpha-options-sign-up-footer {
    flex-direction: column;
    align-items: flex-start;
  }

  .alpha-options-sign-up-footer-left {
    width: 100%;
  }

  img.alpha-options-sign-up-footer-img {
    margin: 0 auto;
  }

  img.alpha-options-sign-up-trading {
    display: none;
  }

  .alpha-options-sign-up-item {
    font-size: 14px;
    line-height: 16px;
  }
}

body.light {
  .alpha-options-sign-up-left {
    background: #CCCCCC;
  }

  .alpha-options-sign-up-right {
    background: #fff;
  }

  .alpha-options-sign-up-header {
    background-image: none;
  }

  .alpha-options-sign-up-title {
    color: #171717;
  }

  .alpha-options-sign-up-info {
    color: #171717;
  }

  .alpha-options-sign-up-unlock-text {
    color: #171717;
  }

  .alpha-options-sign-up-item {
    color: #171717;
  }

  .alpha-options-sign-up-price {
    color: #171717;
  }

  .alpha-options-sign-up-year {
    color: #171717;
  }

  .alpha-options-sign-up-footer-title {
    color: #171717;
  }

  .alpha-options-sign-up-footer-description {
    color: #171717;
  }

  .alpha-options-sign-up-button-outline {
    color: #171717!important;
  }
}