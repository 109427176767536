.sendbird-openchannel-app {
  position: relative;
  height: 84vh;
  width: 100%;
  display: flex;
  .brian {
    font-weight: 900;
    color: black;
    padding: 1.2px;
    background:rgb(99 255 128 / 99%);
    padding-right: 5px;
    font-size: 12.5px;
    border-radius: 9px;
    padding-left: 4px;
  }
  .sendbird-openchannel-app__channel {
    position: relative;
    height: 100%;
    width: 100%;
  }

  .sendbird-openchannel-app__settings {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 100%;
  }
  .sendbird-icon-info {
    display: none;
  }
}
.sendbird-place-holder {
  .sendbird-place-holder__body {
    height: auto;

    .sendbird-place-holder__body__icon {
      margin: 10px 0 0 0;

      .icon-chat_svg__fill {
        animation: loading 1.5s linear infinite;
      }

      &-timestamp {
        font-family: Helvetica Neue, sans-serif;
        font-size: 11px;
        line-height: 141%;
        color: white;
        margin-left: 6px;
      }
    }
    .str-chat__li:hover .str-chat__message-text-inner {
      background: rgb(0 0 0 / 97%);
    }
    .str-chat__message-simple-text-inner p {
      padding-top: 4px !important;
      color: white !important;
    }

    .str-chat__li:hover .str-chat__message-simple__actions {
      background: #000000;
    }
    .str-chat__list {
      background: #00000026;
    }
    .str-chat__modal__inner {
      background: black;
    }
    .str-chat.dark .str-chat__edit-message-form textarea {
      background: #00000026 !important;
      border: 1px solid blue;
    }
    .str-chat__header-livestream {
      padding: var(--xs-p) var(--xl-p);
      min-height: 70px;
      display: flex;
      align-items: center;
      font-family: var(--second-font);
      border-radius: 3px;
      background: #000000;
      box-shadow:
		/* offset-x | offset-y | blur-radius | spread-radius | color */ 0px 4px 5px 0px hsla(0, 0%, 0%, 0.14),
        0px 1px 10px 0px hsla(0, 0%, 0%, 0.12), 0px 2px 4px -1px hsla(0, 0%, 0%, 0.2);
    }
  }
}

.sendbird-loader {
  display: none;
}
.sendbird-openchannel-app-notifications {
  .sendbird-openchannel-conversation-header__left__title {
    position: absolute;
    left: 23px;
    top: 12px;
  }
  .sendbird-openchannel-conversation-header__left__sub-title {
    // position: absolute;
    // left: 26px;
    // top: 40px;
    display: none;
  }
  .sendbird-openchannel-conversation-header__left__cover-image {
    display: none;
  }
  .sendbird-openchannel-conversation-header {
    border-bottom: none;
  }
  .sendbird-frozen-channel-notification {
    display: none;
  }
  .sendbird-openchannel-conversation-header__right {
    display: none;
  }
  .sendbird-openchannel-footer {
    display: none;
  }
  position: relative;
  height: 50vh;
  width: 100%;
  display: flex;
  border-radius: 12px;
  .sendbird-openchannel-app__channel {
    position: relative;
    height: 100%;
    width: 100%;
  }

  .sendbird-openchannel-app__settings {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 100%;
  }
}
body {
  .sendbird-dropdown__menu-item:hover {
    background-color: #330057 !important;
  }
  .sendbird-dropdown__menu {
    background-color: #742ddd !important;
  }
  .sendbird-dropdown__menu-item__text {
    color: white !important;
  }
  .sendbird-modal__content {
    background-color: #742ddd !important;
  }
  .sendbird-modal__header {
    color: white !important;
  }
  &.dark {
    .sendbird-button__text {
      color: black !important;
    }
    .sendbird-button {
      background: white !important;
    }
  }
  &.light {
    sendbird-label {
      color: white !important;
    }
    .sendbird-button__text {
      color: white !important;
    }
    .sendbird-button {
      background: #330057 !important;
    }
  }
}
