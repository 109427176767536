.mm-calendar {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
  padding: 16px;
}

.mm-calendar-sidebar {
  width: 300px;
  line-height: 1.5;
  background: #eaf9ff;
  border-right: 1px solid #d3e2e8;
}

.mm-calendar-sidebar-section {
  padding: 2em;
}

.mm-calendar-main {
  flex-grow: 1;
}

.fc {
  /* the calendar root */
  max-width: 1100px;
  margin: 0 auto;
}
/* General buttons */
.fc .fc-button-group > .fc-button {
  background-color: #121416;
  color: #FFFFFF;
  padding: 8px;
  text-transform: capitalize;
  border: none;
}

body.light .fc .fc-button-group > .fc-button {
  background-color: #79797B;
  color: #FFFFFF;
  padding: 8px;
  text-transform: capitalize;
  border: none;
}
/* Active Button */
.fc .fc-button-group > .fc-button.fc-button-active {
  background-color: #242426;
  color: #00dc0a;
}
/* Cells */
/* .fc .fc-daygrid-day{} */
.fc .fc-daygrid-day-frame {
  background: #121416;

  border: 1px solid #2f2e31;
}

body.light .fc .fc-daygrid-day-frame {
  background: #79797B;
  border: 1px solid #2f2e31;
}
/* Column Headers */
.fc .fc-col-header-cell-cushion {
  color: #fff;
  display: none;
}
/* Cells text */
.fc .fc-daygrid-day-number {
  width: 100%;
  height: 100%;
  color: #fff;
}
