.new-item-light {
  background-color: rgba(153, 111, 244, 0.961);
  z-index: 1000;
  animation-name: hvr-pulse-grow-light;
  animation-fill-mode: both;
  animation-duration: 1.2s;
  animation-delay: 0s;
  width: fit-content;
}
.new-item-dark {
  background-color: rgba(37, 2, 112, 0.961);
  z-index: 1000;
  animation-name: hvr-pulse-grow-dark;
  animation-fill-mode: both;
  animation-duration: 1.2s;
  animation-delay: 0s;
  width: fit-content;
}
.new-item-options-up {
  z-index: 1000;
  animation-name: hvr-pop;
  animation-fill-mode: both;
  animation-duration: 0.6s;
  animation-delay: 0.2s;
  color: green;
  width: fit-content;
}
.new-item-options-down {
  z-index: 1000;
  animation-name: hvr-pop;
  animation-fill-mode: both;
  animation-duration: 0.6s;
  animation-delay: 0.2s;
  color: red;
  width: fit-content;
}
@keyframes bckanim {
  0% {
    background-color: rgba(179, 145, 250, 0.961);

    opacity: 0.3;
  }
  15% {
    background-color: rgba(153, 111, 244, 0.961);
    opacity: 0.4;
  }
  30% {
    background-color: rgba(153, 111, 244, 0.961);

    opacity: 0.5;
  }
  50% {
    background-color: rgba(153, 111, 244, 0.961);

    opacity: 0.6;
  }
  65% {
    background-color: transparent;

    opacity: 0.7;
  }
  80% {
    background-color: transparent;

    opacity: 0.8;
  }
  90% {
    background-color: transparent;

    opacity: 0.9;
  }
  100% {
    background-color: transparent;

    opacity: 1;
  }
}
@-webkit-keyframes hvr-pop {
  0% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  25% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes hvr-pop {
  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}
.hvr-pop {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
/* Buzz */
@-webkit-keyframes hvr-buzz {
  50% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  75% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  100% {
    -webkit-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg);
  }
}
@keyframes hvr-buzz {
  50% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  75% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  100% {
    -webkit-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg);
  }
}
.hvr-buzz {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

/* Push */
@-webkit-keyframes hvr-push {
  50% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  75% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes hvr-push {
  50% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  75% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.hvr-push {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

/* Pulse Grow */
@-webkit-keyframes hvr-pulse-grow-light {
    0% {
        background-color: rgba(179, 145, 250, 0.961);
        opacity: 0.8;
        -webkit-transform: scale(1);
        transform: scale(1);
      }
      50% {
          -webkit-transform: scale(1.1);
          transform: scale(1.1);
        }
        100% {
            background-color: transparent;
        opacity: 1;
          -webkit-transform: scale(1);
          transform: scale(1);
        }
  }
  @keyframes hvr-pulse-grow-light {
    0% {

        background-color: rgba(179, 145, 250, 0.961);
        opacity: 0.8;
        -webkit-transform: scale(1);
        transform: scale(1);
      }
      50% {
          -webkit-transform: scale(1.08);
          transform: scale(1.08);
        }
        100% {
            background-color: transparent;
        opacity: 1;
          -webkit-transform: scale(1);
          transform: scale(1);
        }
  }
  .hvr-pulse-grow-light {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  }
  // dark
  @-webkit-keyframes hvr-pulse-grow-dark {
    0% {
        background-color: rgba(87, 27, 218, 0.961);
        opacity: 0.8;
        -webkit-transform: scale(1);
        transform: scale(1);
      }
      50% {
          -webkit-transform: scale(1.1);
          transform: scale(1.1);
        }
        100% {
            background-color: transparent;
        opacity: 1;
          -webkit-transform: scale(1);
          transform: scale(1);
        }
  }
  @keyframes hvr-pulse-grow-dark {
    0% {

        background-color: rgba(42, 3, 61, 0.94);
        opacity: 0.8;
        -webkit-transform: scale(1);
        transform: scale(1);
      }
      50% {
          -webkit-transform: scale(1.08);
          transform: scale(1.08);
        }
        100% {
            background-color: transparent;
        opacity: 1;
          -webkit-transform: scale(1);
          transform: scale(1);
        }
  }
  .hvr-pulse-grow-dark {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  }