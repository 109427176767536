.support-component {
  padding: 28px 30px;
  background: #fff;
  min-height: calc(100vh - 280px);
}

.support-component-navigator {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 30px;
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  color: #6E6E70;
  cursor: pointer;
}

.support-component-navigator svg {
  color: #6E6E70!important;
  fill: #6E6E70!important;
  margin-right: 5px;
  cursor: pointer;
}

.support-component-navigator:hover {
  color: #00DC0A;
}

.support-component-navigator:hover svg {
  color: #00DC0A!important;
  fill: #00DC0A!important;
}

.support-component-header {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  margin-bottom: 30px;
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #081131;
}

.support-component-header-info {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
}

.support-component-title {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #121416;
}

.support-component-description {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 150%;
  color: #6E6E70;
}

.support-component-header-promo {
  background: rgba(0, 220, 10, 0.1);
  border-radius: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.support-component-header-promo svg {
  color: #121416!important;
  fill: #121416!important;
  width: 36px!important;
  height: 36px!important;
}

.support-component-care {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  color: #00DC0A;
  margin-left: 10px;
  max-width: 280px;
}

.support-component-info {
  margin-top: 24px;
}

.support-component-info-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 24px;
}

.live-streams-author {
  width: 48px;
  height: 48px;
  margin-right: 10px;
}

.support-component-author-name {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #121416;
}

.support-component-author-job {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  line-height: 18px;
  color: #BCBCBC;
}

.support-component-info-content {
  padding-bottom: 40px;
  border-bottom: 0.5px solid #E4E5E8;
}

.support-component-title {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #121416;
  margin-bottom: 8px;
}

.support-component-description {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  color: #6E6E70;
}

.support-component-schedule {
  padding-top: 40px;
  padding-bottom: 26px;
  border-bottom: 0.5px solid #E4E5E8;
}

.support-component-schedule-title {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  color: #121416;
  margin-bottom: 10px;
}

.support-component-schedule-list {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}

.support-component-schedule-day-item {
  width: calc(20% - 2px);
}

.support-component-schedule-day {
  background: #F7F8F7;
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #6E6E70;
  padding: 5px;
}

.support-component-schedule-time {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  color: #6E6E70;
  text-align: center;
  margin-top: 7px;
}

.support-component-bottom-item {
  width: calc(50% - 10px);
  padding: 30px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.support-component-bottom-item-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

img.support-component-bottom-faq-img {
  width: 60px;
  margin-right: 15px;
}

.support-component-bottom-item-title {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  color: #121416;
}

.support-component-bottom-item-info {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.05em;
  color: #6E6E70;
}

.support-component-bottom-item-button {
  background: #121416!important;
  box-shadow: 3px 3px 8px 1px rgb(29 29 30 / 35%)!important;
  font-family: 'Circular Std'!important;
  font-style: normal!important;
  font-weight: 450!important;
  font-size: 11px!important;
  line-height: 14px!important;
  letter-spacing: -0.05em!important;
  color: #F4F4F5!important;
  padding: 8px 0!important;
  width: 155px!important;
  text-align: center!important;
  text-transform: initial!important;
  border-radius: 0!important;
}

.support-faq-description {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 150%;
  color: #6E6E70;
  margin-top: 30px;
  margin-bottom: 15px;
}

.support-faq-search-bar {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  margin-bottom: 30px;
}

input.support-faq-search-box {
  border: 0.5px solid #E4E5E8;
  padding: 11px 14px;
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: -0.05em;
  color: #121416;
  min-width: 350px;
}

input.support-faq-search-box:focus-visible {
  outline: none;
}

.support-faq-search-button {
  background: #121416!important;
  padding: 11px 21px!important;
  border-radius: 0!important;
  font-family: 'Circular Std'!important;
  font-style: normal!important;
  font-weight: 450!important;
  font-size: 11px!important;
  line-height: 14px!important;
  letter-spacing: -0.05em!important;
  color: #F4F4F5!important;
  margin-left: 4px!important;
  text-transform: initial!important;
}

.support-faq-categories {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
  margin-bottom: 40px;
}

.support-faq-category {
  width: calc(100% / 3 - 6px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 22px 6px 13px;
  background: #F7F8F7;
  cursor: pointer;
  margin-bottom: 12px;
}

.support-faq-category-title {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  color: #121416;
}

.support-faq-category-available {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: -0.05em;
  color: #6E6E70;
  margin-top: 3px;
}

img.support-faq-category-icon {
  width: 24px;
}

.support-faq-section {
  margin-bottom: 60px;
}

.support-faq-section-title {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 20px;
  line-height: 25px;
  color: #121416;
  padding-bottom: 24px;
  border-bottom: 0.5px solid #E4E5E8;
}

.support-faq-section-item {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  color: #121416;
  padding: 20px 15px;
  border-bottom: 0.5px solid #E4E5E8;
  cursor: pointer;
}

.support-faq-section-item.active {
  background: #121416;
  color: #fff;
}

.support-faq-section-item-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.support-faq-section-item svg {
  color: #121416!important;
  fill: #121416!important;
}

.support-faq-section-item-more {
  border-left: 1px solid #6E6E70;
  padding-left: 16px;
  margin-top: 16px;
  padding-right: 30px;
}

.support-faq-section-item.active svg {
  color: #fff!important;
  fill: #fff!important;
}

.support-faq-scroll-item {
  position: fixed!important;
  bottom: 40px;
  right: calc(30% - 15px);
}

.up-circle-button {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #121416;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.up-circle-button:hover {
  background: #00DC0A;
}

.up-circle-button svg {
  fill: #ffffff!important;
  color: #ffffff!important;
}

.support-component-hand-person-icon {
  width: 36px;
  height: 36px;
}

@media (min-width: 993px) and (max-width: 1200px) {
  .support-faq-scroll-item {
    right: 45px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .support-component-bottom-item {
    flex-direction: column;
    width: 400px;
  }

  .support-component-schedule-day {
    font-size: 12px;
  }

  .support-component-schedule-time {
    font-size: 12px;
  }

  .support-faq-scroll-item {
    right: calc(30% + 43px);
  }
}

@media (max-width: 767px) {
  .support-component {
    padding: 20px;
  }

  .support-component-schedule-day {
    font-size: 12px;
  }

  .support-component-schedule-time {
    font-size: 12px;
  }

  .support-component-header {
    flex-direction: column;
  }

  .support-component-header-info {
    margin-bottom: 10px;
  }

  .support-component-header-promo {
    justify-content: flex-start;
  }

  .support-component-bottom-item {
    width: 100%;
  }

  .support-faq-scroll-item {
    right: 33px;
  }
}

@media (max-width: 600px) {
  .support-faq-category {
    width: 100%;
    padding: 16px 22px 16px 13px;
  }

  input.support-faq-search-box {
    min-width: auto;
    width: calc(100% - 100px);
  }
}