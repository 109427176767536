.home-title {
    position: relative;
    width: 109px;
    height: 29px;
    left: 13px;
    top: 4px;
    white-space: nowrap;
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 120%;
    color: #000000;
    }
    .home-font-family {
      font-family: "Inter", sans-serif;
    }
    
    .tableCell {
      padding: 2px !important;
    }
    
    .content_div {
      height: calc(100vh - 97px);
      overflow: hidden;
      overflow-y: scroll;
    
      padding-bottom: 0;
      text-align: center;
      z-index: 1;
      padding-right: 20px;
    }
    
    .card {
      border: none;
      border-radius: 16px;
    }
    
    @media (max-width: 991px) {
      .content_div {
        padding-left: 20px;
      }
    }
    
    .search-icon {
      position: absolute;
      top: 13px;
      left: 15px;
      color: #FFFFFF;
      font-size: 20px;
    }
    
    .search-wrapper .ant-select-selection-search-input {
      font-size: 15px;
      padding: 0 20px 0 50px;
      height: 47px !important;
      /* border-radius: 30px; */
      width: 100% !important;
      border: none;
      font-family: 'Circular Std';
      font-style: normal;
      font-weight: 450;
      font-size: 18px;
      line-height: 23px;
      letter-spacing: -0.04em;

      background: #cccccc;
      color: #FFFFFF;

      /* background: #1F1F21;
      color: #79797B; */
    }
    
    .contact .search-wrapper .ant-select-selection-search-input,
    .about .search-wrapper .ant-select-selection-search-input,
    .privacy .search-wrapper .ant-select-selection-search-input,
    .disclaimer .search-wrapper .ant-select-selection-search-input,
    .terms .search-wrapper .ant-select-selection-search-input,
    .safe-sender .search-wrapper .ant-select-selection-search-input {
      /*background-color: #F8FAFC;*/
      background-color: #FFFFFF;
    }
    
  
    
    .search-wrapper .ant-select-selection-search-input::placeholder {
      color: #FFFFFF !important;
      /* color: #79797B !important; */
    }
    
    .search-wrapper .ant-select-selection-search-input:focus {
      outline: none;
    }
    
    .search-wrapper .ant-select-selection-search-input::-webkit-input-placeholder {
      color: peachpuff;
      font-size: 15px;
    }
    .search-wrapper .ant-select-selection-search-input::-moz-placeholder {
      color: peachpuff;
      font-size: 15px;
    }
    .search-wrapper .ant-select-selection-search-input:-ms-input-placeholder {
      color: peachpuff;
      font-size: 15px;
    }
    .search-wrapper .ant-select-selection-search-input::placeholder {
      color: peachpuff;
      font-size: 15px;
    }
    
    .topnav .search-container button:hover {
      background: #ccc;
    }
    
    .home-page-header {
      height: 20px;
    }
    
    .content-in-layout {
      margin-left: 0;
      min-height: 100vh;
    }
    
    .homepage-main-content {
      margin: 0 16px 0 16px;
    }
  
    
    @media (min-width: 992px) {
    
      .home-page-header {
        height: 97px;
      }
    }
    
    .ant-menu-vertical .ant-menu-item:not(:last-child) {
      margin-bottom: 21.7px;
    }
    
    
    
    /* Mobile header */
    
    .search-transition-enter {
      opacity: 0;
    }
    .search-transition-enter-active {
      opacity: 1;
      transition: opacity 300ms;
    }
    .search-transition-exit {
      opacity: 1;
    }
    .search-transition-exit-active {
      opacity: 0;
      transition: opacity 300ms;
    }