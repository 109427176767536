.alpha-options-page-real-time-trading-content {
  margin-top: 10px;
  position: relative;
}

.alpha-options-page-real-time-trading-info {
  background: #fff;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}

.alpha-options-page-real-time-trading-title {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #081131;
  margin-bottom: 8px;
}

.alpha-options-page-real-time-trading-description {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 150%;
  color: #000000;
  margin-bottom: 20px;
}

.alpha-options-page-real-time-trading-join-button {
  background: #121416!important;
  background-color: #121416!important;
  box-shadow: 3px 3px 8px 1px rgb(29 29 30 / 35%)!important;
  padding: 8px 16px!important;
  cursor: pointer!important;
  font-family: 'Circular Std'!important;
  font-style: normal!important;
  font-weight: 450!important;
  font-size: 11px!important;
  line-height: 14px!important;
  letter-spacing: -0.05em!important;
  color: #F4F4F5!important;
  width: 170px!important;
  text-transform: initial!important;
  border-radius: 0!important;
}

.alpha-options-page-real-time-trading-body {
  padding: 24px;
  width: calc(100% - 300px);
}

.trading-image-wrapper {
  width: 300px;
  position: relative;
}

.trading-image {
  position: absolute;
  width: 300px;
  top: -24px;
  right: -8px;
}

@media (min-width: 768px) and (max-width: 992px) {
  .alpha-options-page-real-time-trading-title {
    font-size: 22px;
  }

  .alpha-options-page-real-time-trading-description {
    font-size: 14px;
  }

  .trading-image {
    width: 200px;
  }
}

@media (max-width: 992px) {
  .alpha-options-page-real-time-trading-body {
    width: 100%;
  }
  
  .trading-image-wrapper {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .alpha-options-page-real-time-trading-title {
    font-size: 22px;
  }

  .alpha-options-page-real-time-trading-description {
    font-size: 14px;
  }
}

@media (max-width: 600px) {
  .alpha-options-page-real-time-trading-body {
    width: 100%;
  }
  
  .trading-image-wrapper {
    display: none;
  }

  .trading-image {
    display: none;
  }
}