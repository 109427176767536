.trending-area {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 16px;

  .half-width {
    width: calc(50% - 8px);
  }

  .trending-area-label {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: -0.04em;
    color: #8e8e93;
    text-transform: uppercase;
    margin-bottom: 8px;
    display: flex;
    align-items: center;

    .ai-score {
      display: flex;
      align-items: center;
      margin-left: 16px;
      text-transform: capitalize;
      font-size: 14px;

      svg {
        font-size: 16px;
        margin-left: 8px;
        position: relative;
        top: -2px;
      }
    }
  }

  .trending-buttons {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    .trending-button {
      padding: 8px;
      width: calc((100% - 32px) / 5);
      margin-left: 4px;
      margin-right: 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      .trending-ticker {
        font-style: normal;
        font-weight: 450;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.02em;
        color: #8e8e93;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .trending-up {
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 20px;
          letter-spacing: -0.04em;
          color: #00dc0a;
          text-transform: uppercase;
          margin-right: 4px;
        }

        .trending-down {
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 20px;
          letter-spacing: -0.04em;
          color: #fa4646;
          text-transform: uppercase;
          margin-right: 4px;
        }
      }
    }
  }
}


@media (max-width: 1300px) {
  .trending-area
  .trending-buttons
  .trending-button
  .trending-ticker {
    font-size: 14px;
  }

  .trending-area
  .trending-buttons
  .trending-button
  svg {
    font-size: 16px;
  }

  .trending-area
  .trending-buttons
  .trending-button
  .trending-ticker
  .trending-up {
    font-size: 16px;
  }

  
  .trending-area
  .trending-buttons
  .trending-button
  .trending-ticker
  .trending-down {
    font-size: 16px;
  }
}

@media (max-width: 1300px) {
  .trending-area {
    flex-direction: column;
  }

  .trending-area .half-width {
    width: 100%;
    margin-bottom: 16px;
  }
}

@media (max-width: 800px) {
  
  .trending-area
  .trending-buttons
  .trending-button {
    min-width: 100px;
    margin-bottom: 8px;
    margin-right: 8px;
    margin-left: 0;
  }

  .trending-area .trending-buttons {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}