.terms-modal-content {
  max-width: 650px;
  width: calc(100% - 20px);
  background: #121416;
  border: 1px solid #6E6E70;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 28px 28px 60px 38px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.terms-modal-content-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  svg {
    color: #9E9F9F!important;
    fill: #9E9F9F!important;
    width: 26px!important;
    height: 26px!important;
    cursor: pointer;
  }
}

.terms-modal-title {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  text-transform: capitalize;
  color: #00DC0A;
}

.terms-modal-body {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  line-height: 18px;
  text-transform: capitalize;
  color: #FFFFFF;
}


body.light {
  .terms-modal-content {
    background: #FFFFFF;
  }

  .terms-modal-title {
    color: #007BFF;
  }

  .terms-modal-body {
    color: #000;
  }
}