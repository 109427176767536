.video-popup-content {
  max-width: 900px;
  width: calc(100% - 10px);
  min-height: 650px;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 45px 45px 20px 45px;
  user-select: none;
}

.video-popup-close-button {
  position: absolute!important;
  top: 10px!important;
  right: 10px!important;
}

.video-popup-close-button svg {
  color: #121416!important;
  fill: #121416!important;
}

.video-popup-title {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: #000000;
  margin-bottom: 25px;
}

.video-popup-description {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 120%;
  color: #6E6E70;
  margin-bottom: 12px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.video-popup-index {
  min-width: 130px;
  text-align: right;
}

.video-popup-progress-bar {
  background: #E7E7E7;
  border-radius: 100px;
  height: 10px;
  width: 100%;
  position: relative;
  margin-bottom: 20px;
}

.video-popup-progress-bar-inner {
  background: #00DC0A;
  height: 10px;
  border-radius: 100px;
}

.video-popup-ele {
  border-radius: 10px;
  overflow: auto;
  margin-bottom: 30px;
}

.video-popup-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.video-popup-footer-action-button {
  font-family: 'Circular Std'!important;
  font-style: normal!important;
  font-weight: 450!important;
  font-size: 16px!important;
  line-height: 20px!important;
  color: #6E6E70!important;
  text-transform: initial!important;
}

.video-popup-footer-action-button svg {
  color: #6E6E70!important;
  fill: #6E6E70!important;
}

.visibility-hidden {
  visibility: hidden;
}