.dash-circle-wrap-light {
  width: 80px;
  height: 80px;
  background: #fefcff;
  border-radius: 50%;
  margin-top: 9px;
}

.dash-circle-wrap-light .dash-circle .dash-mask,
.dash-circle-wrap-light .dash-circle .fill {
  width: 80px;
  height: 80px;
  position: absolute;
  border-radius: 50%;
}
.dash-circle-wrap-light .dash-circle .zoro {
  height: 0px !important
}
.zoro {
  clip:rect(0px, 137px, 180px, 81px) !important;
}
.one-fourth {
  clip : rect(0px 80px 80px 71px) !important
}
.half-ass {
  clip : rect(0px 80px 80px 42px) !important
}
.tree-fourth{
  clip : rect(0px 80px 80px 40px) !important
}
.hundo {
  clip: rect(0px, 137px, 180px, 38px) !important;
}
.dash-circle-wrap-light .dash-circle .dash-mask {
  clip: rect(0px, 137px, 180px, 40px);
}

.dash-circle-wrap-light .inside-dash-circle-up {
  width: 70.5px;
  height: 70.5px;
  border-radius: 50%;
  text-align: center;
  margin-top: 4.5px;
  margin-left: 4.5px;
  position: absolute;
  z-index: 100;
  font-weight: 700;
  font-size: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #ffffff;
  box-shadow: rgb(9 227 147 / 20%) 0px 0px 12px 6px;
}

.dash-circle-wrap-light .inside-dash-circle-down {
  width: 70.5px;
  height: 70.5px;
  border-radius: 50%;
  background: #ffffff;
  box-shadow: rgb(229 0 53 / 24%) 0px 0px 12px 6px;
  text-align: center;
  margin-top: 4.5px;
  margin-left: 4.5px;
  position: absolute;
  z-index: 100;
  font-weight: 700;
  font-size: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
// light Mode ^

.dash-circle-wrap-dark {
  width: 80px;
  height: 80px;
  background: #fefcff;
  border-radius: 50%;
  margin-top: 9px;
}

.dash-circle-wrap-dark .dash-circle .dash-mask,
.dash-circle-wrap-dark .dash-circle .fill {
  width: 80px;
  height: 80px;
  position: absolute;
  border-radius: 50%;
}

.dash-circle-wrap-dark .dash-circle .dash-mask {
  clip: rect(0px, 137px, 180px, 40px);
}

.dash-circle-wrap-dark .inside-dash-circle-up {
  width: 70.5px;
  height: 70.5px;
  border-radius: 50%;
  text-align: center;
  margin-top: 4.5px;
  margin-left: 4.5px;
  position: absolute;
  z-index: 100;
  font-weight: 700;
  font-size: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  background-color: #140b0b;
  box-shadow: rgb(7 191 124 / 20%) 0px 0px 12px 6px;
}

.dash-circle-wrap-dark .inside-dash-circle-down {
  width: 70.5px;
  height: 70.5px;
  border-radius: 50%;
  background: #140b0b;
  box-shadow: rgb(229 0 53 / 24%) 0px 0px 12px 6px;
  text-align: center;
  margin-top: 4.5px;
  margin-left: 4.5px;
  position: absolute;
  z-index: 100;
  font-weight: 700;
  font-size: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* color animation */

/* 3rd progress bar */
.dash-mask .fill {
  clip: rect(0px, 80px, 80px, 0px);
}

@keyframes fill {
  0% {
    transform: rotate(0deg);
  }
}

/* sub parts */
.inside-dash-circle-percentage {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #555555;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.inside-dash-circle-value {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #a4a7aa;
  flex: none;
  flex-grow: 0;
  margin: 8px 0;
}

.inside-dash-circle-title {
  /* TradeAlgo/sm/regular */

  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  position: relative;
  top: -7px;
  left: 1px;
  text-align: center;

  /* Mint Green/full */

  color: #3eb489;
}

.summary-block {
  flex: 1 1 25%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

@media (max-width: 767px) {
  .summary-blocks {
    margin-top: 25px;
    margin-bottom: 14px;
  }

  .summary-block {
    flex: 1 1 50%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 10px 0;
  }
}
.darkflow-title {
  position: relative;
  left: 3px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 10px;
  text-align: center;
  color: #94a3b8;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 16px 0px;
}
.options-title {
  position: relative;
  left: -9px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 10px;
  text-align: center;
  color: #94a3b8;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 16px 0px;
}
