.name {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #94a3b8;
}
.top-price {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -1px;
}
.down {
  color: rgba(239, 68, 68, 1) !important;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
}

.up {
  color: #0dad60 !important;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
}
.compare {
  color: rgba(148, 163, 184, 1);
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}
.left-side {
  padding-right: 0px;
  padding: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #94a3b8;
}
.left-side-value-green {
  padding-right: 10px !important;
  padding: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  float: right;
  text-align: right;

  /* Mint Green/full */

  color: #3eb489;
}
.left-side-value-red {
  padding-right: 10px !important;
  padding: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  float: right;
  text-align: right;

  /* Mint Green/full */

  color: rgba(239, 68, 68, 1);
}
.right-side-value-light {
  padding-right: 10px !important;
  padding: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  text-align: right;
  /* Trade algo Web/Gray scale/Gray 900 */
  color: #171717;
}
.right-side-value-dark {
  padding-right: 10px !important;
  padding: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  text-align: right;

  /* Trade algo Web/Gray scale/Gray 900 */

  color: white;
}
