.sign-up-hint {
  padding: 17px 24px;
}

.sign-up-hint-big-text {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
  color: #FFFFFF;
  margin-bottom: 40px;
}

.sign-up-hint-small-text {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 150%;
  color: #F5F5F5;
  margin-bottom: 40px;
}

a.sign-up-hint-anchor {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  text-decoration-line: underline;
  color: #00DC0A;
  margin-bottom: 5px;
  display: block;
}

.sign-up-hint-ul {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 180%;
  color: #F5F5F5;
  list-style-type: disc;
  padding-left: 22px;
}

.alpha-options-method {
  color: #00DC0A;
}

body.light {
  .sign-up-hint-big-text {
    color: #000;
  }

  .sign-up-hint-small-text {
    color: #6E6E70;
  }

  .sign-up-hint-ul {
    color: #6E6E70;
  }
}