.supports-widget {
  margin-top: 10px;
  padding: 24px;
  background: #fff;
}

.supports-widget-title {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #081131;
  margin-bottom: 16px;
}

.supports-actions-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.supports-widget-item {
  width: calc(50% - 10px);
  padding: 20px;
  background: #F7F8F7;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.supports-widget-item-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

img.supports-widget-faq-img {
  width: 60px;
  margin-right: 15px;
}

.supports-widget-item-title {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  color: #121416;
}

.supports-widget-item-info {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.05em;
  color: #6E6E70;
}

.supports-widget-item-button {
  background: #121416!important;
  box-shadow: 3px 3px 8px 1px rgb(29 29 30 / 35%)!important;
  font-family: 'Circular Std'!important;
  font-style: normal!important;
  font-weight: 450!important;
  font-size: 11px!important;
  line-height: 14px!important;
  letter-spacing: -0.05em!important;
  color: #F4F4F5!important;
  padding: 8px 0!important;
  width: 155px!important;
  text-align: center!important;
  text-transform: initial!important;
  border-radius: 0!important;
}

@media (max-width: 992px) {
  .supports-widget-item {
    flex-direction: column;
  }

  .supports-widget-item-detail {
    margin-bottom: 20px;
  }
}


@media (max-width: 600px) {
  .supports-actions-wrapper {
    flex-direction: column;
  }

  .supports-widget-item {
    width: 100%;
    margin-bottom: 10px;
    align-items: flex-start;
  }
}