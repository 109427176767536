#parent > * {
  margin: 8px 0;
}
#parent {
  width: 100%;
  position: relative;
  top: -6px;
}
.details {
  position: relative;
  width: 99px;
  top: -2px;
}
.industry {
  position: relative;
  width: 99px;
  top: -1px;
}
.website {
  position: relative;
  width: 99px;
  top: -1px;
}

.trade-algo-equity-page-info-row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 40px 0;
  width: 100%;
  // background: #fff;
  border-radius: 16px;
  padding: 40px 15px 30px;
}

.equity-page-info-left-part {
  padding: 2px;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  display: flex;
  align-items: center;
  // color: #555555;
}

.equity-page-info-right-part-row {
  display: flex;
  align-items: baseline;
  margin-bottom: 8px;
}

.equity-page-info-aspect {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 32px;
  color: #555555;
  margin-right: 5px;
  @media screen and (max-width: 900px) {
    margin-left: -12px !important;
  }
}

.equity-page-info-value {
  padding-left: 10px;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  color: #555555;
  word-break: break-all;
}

.tv-embed-widget-wrapper__body {
  border: none !important;
}
