.timestamp-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 500px;
  width: 100%;
  font-family: 'Circular Std';

  .timestamp-hour {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 450;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: -0.04em;
    color: #6d6f71;
    padding: 8px 36px;
    cursor: pointer;
    height: 40px;
    display: flex;
    align-items: center;
  }
}

@media (max-width: 1050px) {
  .timestamp-buttons {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .timestamp-buttons .timestamp-hour {
    justify-content: center;
    width: 25%;
  }
}

@media (max-width: 510px) {
  .timestamp-buttons
  .timestamp-hour {
    width: calc(25% - 4px);
    padding: 8px 16px;
    justify-content: center;
  }
}