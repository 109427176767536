$backgroundColorScroll: var(--background-color-scroll);

.news-notification {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    &.text-msg {
        color: #fff;
        font-size: 24px;
    }
}

.news-filters-wrapper {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 10px;

    .news-filter-dropdown {
        width: calc(100% / 3 - 20px);

        @media(max-width: 768px) {
            width: 100%;
            margin-bottom: 10px;
        }
    }
}

.news-wrapper {
    display: flex;
    width: 100%;
    height: 60%;
    column-gap: 10px;
    flex-direction: row;

    @media (max-width: 599px) {
        flex-direction: column;
    }
}

.news-big-one {
    width: calc( 100% / 3 * 2 );

    @media (max-width: 899px) {
        width: calc( 100% / 2 );
    }

    @media (max-width: 599px) {
        width: 100%;
    }
}

.news-small-one {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: calc( 100% / 3 );

    @media (max-width: 899px) {
        width: calc( 100% / 2 );
    }

    @media (max-width: 599px) {
        width: 100%;
    }
}

.news-filter-dropdown .dropdown-menu::-webkit-scrollbar {
    width: 18.5px;
    background-color:$backgroundColorScroll!important;
}