// Darkmode variables
$main: #001128;
$table: #001528;
$text: #79797B;
$secondaryText: #cfd1ff;
//$background: #181818;
$background: #F8FAFC;
$contrastBackground: #1F1F21;
$chartBackground: #202020;
$boxShadow: 0 -2px 10px rgba(0, 0, 0, 1);
$hover: #1F1F21;
$seperator: #363637;
$backgroundColorScroll: var(--background-color-scroll);
// .html-el {
//   overflow: hidden !important;
// }
.parent-image {
  position: relative;
  display: inline-block;
}
.forex-mentor {
  position: relative;
  > svg {
    margin-left: 9px;
  }
}
.close-x {
  position: absolute !important;
  top: -10 ;
  right: -10;
}
.curse-point {
  cursor: pointer !important;
  z-index: 2;
}
.open-popover {
  overflow: hidden !important;
}
.autocomplete-lo {
  width: calc(50% - 5px);
  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: #2b2b2b;
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

}
*::-webkit-scrollbar {
  width: 18.5px;
  background-color:$backgroundColorScroll;
}

*::-webkit-scrollbar-track {
  border-radius: 16px;
}

*::-webkit-scrollbar-thumb {
  height: 56px;
  border-radius: 8px;
  border: 4px solid transparent;
  background-clip: content-box;
  background-color: #888;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
.MuiPaper-rounded {
  overflow-y: auto !important;
}
.market-makers-darkflow-card {
  overflow: hidden !important;
}
.disabled-link {
  cursor: pointer !important;
  pointer-events: none !important;
}
body {
  .MuiFormLabel-root {
    color: white;
  }
  .MuiFormLabel-root.Mui-focused{
    color: white;
  }
  .dxfeed-radar-symbol-modal {
    position: relative;
    top: 22%;
    left: 10.5%;
  }
 
  .ant-modal-mask  {
    background-color:#6f6f6f3b !important;
  }
  .MuiDivider-root {
    border-color:rgb(111 250 130 / 88%) !important;
  }
  a:hover{
    text-decoration: none !important;
  }
  .spark-row {
    position: relative;
    left: -9px;
    @media(max-width: 1400px) {
      left: -56px !important;
    }
  }

  .rc-virtual-list {
    position: relative;
    top: 5px;
  }
  .graph-red,
  .graph-red {
    stroke: rgb(252, 22, 22);
  }
  .graph-green,
  .graph-green {
    stroke: rgb(51, 251, 67);
  }
  .highcharts-tooltip {
    .areyu{
      fill: black;
    }
  }
  .drag-text {
    color: black;
    font-weight: bold;
  }
  .ant-dxfeed-modal-mask {
    background-color: inherit !important;
  }
  .MuiButton-containedPrimary {
    background-color: black;
    color: white;
  }
  .MuiButton-startIcon{
     .MuiSvgIcon-root {
      fill: white;
      color:white;
     }
  }
  .MuiDataGrid-columnsContainer {
    border-right: 2px solid black;
  }
  .MuiDataGrid-window{
    overflow-x: hidden !important;
  }
  &.dark {
    .MuiButton-containedPrimary {
      background-color: white;
      color: black;
    }
    .MuiButton-startIcon{
       .MuiSvgIcon-root {
        fill: black;
        color: black;
       }
    }
    .MuiButton-endIcon{
      .MuiSvgIcon-root {
       fill: black;
       color: black;
      }
   }
    .actions .btn-outline{
      border-radius: 20px;
      border-color: #a2a2a2 !important;
      font-size: 18px;
      line-height: 27px;
      color: #ffffff !important;
      font-weight: 600;
      font-family: "Inter", sans-serif;
      text-align: right;
    }
    .ant-dxfeed-modal-mask {
      background-color: rgb(0 0 0 / 37%) !important;
    }
    .css-1tape97 {
      background-color: black;
    }
    .MuiPickersArrowSwitcher-root {
      background-color: black;
    }
    .drag-text {
      color: white;
      font-weight: bold;
    }
    .react-grid-item > .react-resizable-handle::after {
      content: "";
      position: absolute;
      right: 3px;
      bottom: 3px;
      width: 15px;
      height: 15px;
      border-right: 3px solid white;
      border-bottom: 3px solid white;
    }
    .MuiListItem-gutters > .MuiSvgIcon-root {
      fill: #fff;
    }
    .simplebar-placeholder {
      border-right: 1px solid black;
    }
    .MuiList-root {
      background-color: #2F2E31 !important;
    }
    .MuiMenu-list {
      background-color: #2F2E31 !important;
    }
    background-color: #1B1D1E !important;
    .caret.up {
      border-bottom-color: $contrastBackground;
    }
    .MuiTabs-flexContainer {
      border-block-end: 2px solid $hover;
    }
    .MuiTab-root {
      min-width: 8px !important;
    }
    .editorial-content > h3 {
      color: $text;
    }
    .embedded-dark-pool-page
      .content
      .dark-pool-datatable-container
      .dark-pool-datatable
      tbody
      tr:nth-child(odd) {
      background-color: $background;
    }
    .PhoneInputInput {
      background-color: $contrastBackground;
    }
    .cathie-wood-widget .phone-number-field {
      color: $text;
    }
    .cathie-wood-widget .select-option .ant-radio-wrapper span {
      color: $text;
    }
    .negative-text-color {
      color: #dc3545 !important;
    }
    .positive-text-color {
      color: #20c997 !important;
    }
    .line-clamp-one-line text-light-black {
      color: $text;
    }
    .text-light-black {
      color: $text;
    }
    .holdings > h5 {
      color: $text;
    }
    .custom-filter-box .small-label {
      color: $text;
    }
    .company-owner-popover .ant-popover-inner .ant-popover-title {
      color: $text;
    }

    .btn-primary .text-bold, .btn-outline .text-bold {
      color: $text;
    }

    .text-bold {

      font-style: normal;
      font-weight: 600!important;
      font-size: 18px!important;
      line-height: 21px;
      color: #13111A;
    }
    .closedHours {
      filter: invert(1);
    }
    .ant-dropdown-menu-item:hover,
    .ant-dropdown-menu-submenu-title:hover {
      background-color: $hover;
    }
    .ant-dropdown-menu {
      background-color: $contrastBackground;
      color: $text;
      svg {
        fill: $text;
        color: $text;
        stroke: $text;
      }
    }
    .ant-radio-button-wrapper {
      border: 1px solid $text !important;
    }
    .stock-wall-titan-card > .hidden-info > .bottom > h2 {
      color: $text;
    }
    .stock-wall-titan-card > .info > h1.titan {
      color: $text;
    }
    .ant-select-arrow {
      color: $text;
    }
    .mobile-nav {
      background-color: $main;
    }
    .pin-active {
      background-color: $background;
      &:hover {
        background-color: $background;
      }
      &:focus {
        background-color: $contrastBackground;
      }
    }
    .nav-tabs {
      .nav-item > a {
        color: $text;
      }
      .nav-link {
        color: $text;
        background-color: $contrastBackground !important;
      }
      .nav-link:hover {
        color: $secondaryText;
      }
      .nav-link.active {
        background-color: $seperator !important;
        color: $text;
      }
    }
    .nav-tabs-dark {
      .nav-item-dark > a {
        color: $text;
      }
      .nav-link-dark {
        color: $text;
        background-color: rgba(25, 27, 28, 0.5) !important;
      }
      .nav-link-dark:hover {
        color: $secondaryText;
      }
      .nav-link-dark.active {
        background-color: $seperator !important;
        color: $text;
      }
    }

    .ant-popover-content .ant-popover-inner {
      background: $hover;
      box-shadow: none !important;
      color: $text;
    }
    .ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow {
      border: $contrastBackground;
    }
    .custom-filter-box {
      .options-wrapper .option-value {
        background: $hover;
        color: $text;
      }
      .item {
        background: $hover;
        color: $text;
      }
      .custom-filter-box .small-label {
        background: $hover;
        color: $text;
      }
    }

    .search-wrapper .ant-select-selection-search-input {
      background: $contrastBackground;
      color: $text;
    }
    .box-shadow-default {
      box-shadow: none !important;
    }
    .stock-wall-etf-card {
      background-color: $contrastBackground;
      box-shadow: $boxShadow;
      color: $text;
    }
    .portfolio-information {
      box-shadow: $boxShadow !important;
    }
    .portfolio-information-label {
      color: $text;
    }
    .portfolio-label {
      color: $text !important;
    }
    .portfolio-value {
      color: $text !important;
    }
    .ant-input-affix-wrapper {
      background-color: $hover;
    }
    .ant-table-thead > tr > th {
      background-color: $contrastBackground !important;
    }
    .account-layout > .left-menu > .ant-menu-inline {
      background-color: $contrastBackground;
    }
    .desktop-institution-holding,
    .mobile-institution-holding {
      background-color: $contrastBackground;
      color: $text;
    }
    .ant-btn-group > .ant-btn:last-child:not(:first-child),
    .ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
      background-color: $contrastBackground;
      color: $text;
    }
    .ant-btn .anticon {
      color: $text;
    }
    .ant-menu-item {
      color: $text;
    }
    .pin-btn.ant-btn {
      background-color: $contrastBackground;
    }
    .pin-btn.ant-btn:hover {
      background-color: $contrastBackground;
      color: $text;
    }
    .highcharts-background {
      fill: $chartBackground;
    }
    .highcharts-menu-wrapper {
      background-color: $chartBackground;
    }
    .ant-radio-button-wrapper {
      background-color: $contrastBackground;
      color: $text;
      border: $contrastBackground;
    }
    .ant-radio-button-wrapper:hover {
      background-color: $hover;
    }
    .ant-radio-button-wrapper-checked {
      background-color: $hover;
    }
    .highcharts-bindings-wrapper
      li.highcharts-separator
      > span.highcharts-menu-item-btn {
      display: none;
    }
    .highcharts-annotation-shapes > * {
      stroke: $secondaryText;
      stroke-width: 1;
    }
    .highcharts-bindings-wrapper li > span.highcharts-menu-item-btn {
      background-color: $contrastBackground;
      border: 1px solid $background;
    }
    .highcharts-bindings-wrapper li:hover,
    .highcharts-submenu-item-arrow:hover {
      background-color: $contrastBackground;
    }
    .highcharts-bindings-wrapper .highcharts-submenu-wrapper {
      background-color: $background;
    }
    .highcharts-toggle-toolbar {
      background-color: $background;
    }
    .highcharts-scrollbar-thumb {
      fill: $hover;
      stroke: $hover;
    }
    .highcharts-scrollbar-track {
      fill: $background;
      stroke: $hover;
    }
    .highcharts-scrollbar-button {
      stroke: $hover;
      fill: $background;
    }
    .highcharts-scrollbar-arrow {
      fill: $text;
    }
    .highcharts-axis-line {
      stroke: $hover;
    }
    .highcharts-navigator-outline {
      stroke: $hover;
    }
    .highcharts-navigator-handle {
      stroke: $secondaryText;
      fill: $hover;
    }
    .highcharts-navigator-series {
      .highcharts-point {
        fill: $hover;
      }
    }
    .ant-card {
      background-color: $contrastBackground;
    }
    .cathie-wood-widget {
      background: $background;
    }
    .bg-white {
      background: $contrastBackground !important;
      color: $text;
      &.box-shadow-secondary {
        box-shadow: none;
      }
    }
    .stock-wall-tabs > li > button {
      background-color: $hover;
      box-shadow: none;
    }
    .stock-wall-tabs > li > button.active {
      background-color: blue;
    }
    .stock-wall-stock-card > .info > h3 {
      color: $text;
    }
    .stock-wall-card:hover * {
      text-decoration: none;
    }
    .stock-wall-card h1,
    .stock-wall-card h2,
    .stock-wall-card h3,
    .stock-wall-card h4 {
      color: $text;
    }
    .stock-wall-stock-card > .hidden-info > .top > h1,
    .stock-wall-stock-card > .hidden-info > .top > h2,
    .stock-wall-stock-card > .hidden-info > .top > h3 {
      color: $text;
    }
    .stock-wall-stock-card > .hidden-info > .today > h1,
    .stock-wall-stock-card > .hidden-info > .today > h2,
    .stock-wall-stock-card > .hidden-info > .today > h3 {
      color: $text;
    }
    .stock-wall-stock-card > .hidden-info > .date > h1,
    .stock-wall-stock-card > .hidden-info > .date > h2,
    .stock-wall-stock-card > .hidden-info > .date > h3 {
      color: $text;
    }

    .stock-wall-card {
      box-shadow: none;
    }
    .user-activity-wall {
      display: flex;
      align-items: center;
      background-color: $contrastBackground;
      border-radius: 20px;
      padding: 18px;
      margin-bottom: 28px;
      justify-content: space-between;
      min-height: 90px;
      box-shadow: $boxShadow;
    }

    .user-activity-wall > .stat-wrapper > .title {
      color: #9ea7b3;
      font-size: 18px;
      margin-right: 35px;
      font-weight: 700;
    }

    .stat-wrapper {
      display: flex;
      align-items: center;
    }

    .user-activity-wall > .stat-wrapper > .stat {
      display: flex;
      align-items: center;
      /*border-right: 1px solid #e7e9ec;*/
      min-height: 34px;
    }

    .user-activity-wall > .stat-wrapper > .stat > .title.first {
      margin-left: 0;
    }

    .user-activity-wall > .stat-wrapper > .stat + .last {
      border-right: none;
    }

    .user-activity-wall > .stat-wrapper > .stat > .title {
      color: #9ea7b3;
      font-size: 15px;
      line-height: 16px;
      text-align: left;
      margin-right: 24px;
      margin-left: 24px;
    }

    .user-activity-wall > .stat-wrapper > .stat > .percent {
      color: #6dc92f;
      font-size: 20px;
      font-weight: 700;
      /*margin-right: 23px;*/
      margin-right: 0;
    }

    .user-activity-wall > .stat-wrapper > .stat > .percent.red {
      color: #ef5656;
    }

    .user-activity-wall > div > .dropdown > button {
      background: none;
      color: white;
      border: none;
      font-size: 18px;
      padding: 0;
      font-weight: 500;
    }

    .user-activity-wall > div > .dropdown > button:focus {
      color: white !important;
    }
    .user-activity-wall > div > .dropdown > button:active {
      border: none !important;
      box-shadow: none !important;
      outline: none;
      background: none !important;
      color: white !important;
    }

    .user-activity-wall > div > .dropdown > button:after {
      color: #0071ee;
      margin-left: 20px;
    }

    .user-activity-wall > .switchers {
      display: none;
    }

    .user-activity-wall > .switchers > button {
      color: #9ea7b3;
      font-size: 16px;
      border: none;
      background: none;
      padding: 0;
      margin: 0;
      font-weight: 300;
    }

    .user-activity-wall > .switchers > button:focus {
      outline: none;
    }

    .user-activity-wall .switchers button:first-child {
      margin-right: 15px;
    }
    .btn-primary.follow-btn.follow-active.follow-theme-white,
    .btn-primary.follow-btn.follow-theme-white {
      background-color: $hover !important;
      padding: 0 !important;
      border: 1px solid $hover !important;
    }

    .btn-primary.follow-btn.follow-active.follow-theme-white:focus,
    .btn-primary.follow-btn.follow-theme-white:focus,
    .btn-primary.follow-btn.follow-active.follow-theme-white:active,
    .btn-primary.follow-btn.follow-theme-white:active {
      outline: none !important;
      box-shadow: none !important;
      border: 1px solid $hover !important;
    }

    .follow-theme-white span.btn-icon {
      color: #127bef !important;
      font-weight: 500 !important;
    }

    .follow-theme-white span.btn-label {
      font-size: 18px !important;
      line-height: 23px !important;
      color: white !important;
      text-transform: initial !important;
      font-weight: 400 !important;
    }
    .black-color {
      color: $text !important;
    }
    section > main {
      background-color: $background;
    }
    .main-content-light {
      background-color: $background !important;
    }
    .main-content-dark {
      background-color: #1C1E20 !important;
    }
    .insider-header .insider-icon .fa-chart-line {
      transform: none;
      margin-top: -25px;
      background-color: $background;
      box-shadow: none;
      border: 1px solid $secondaryText;
    }
    .tag-link {
      color: $text !important;
    }
    .page-link {
      color: $text;
      background-color: $background;
      &.disabled {
        color: $text;
        background-color: $background;
      }
    }
    .btn-light {
      //background-color: $contrastBackground;
      //color: $text;
      background-color: #FFFFFF;
      color: #13111A;
    }
    .btn-default {
      background-color: $background !important;
      color: $text !important;
      &.active {
        background-color: $background !important;
        color: $secondaryText !important;
      }
    }
    .ant-table-body {
      background-color: $contrastBackground;
    }
    .ant-table-content {
      background-color: $contrastBackground;
    }
    .ant-table-cell {
      background-color: $contrastBackground;
      color: $text;
    }
    .ant-table-tbody > tr.ant-table-row:hover > td {
      background-color: $hover;
      color: $text;
    }
    .ant-input {
      background-color: $hover !important;
      color: $text !important;
    }
    .ant-input-group-addon {
      background-color: $background;
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background-color: $hover;
      color: $text;
    }

    .ant-select-dropdown {
      // background-color: $background;
      color: $text;
    }
    .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
      background-color: $hover;
    }
    
    .rc-virtual-list-holder-inner {
      // border: 1px solid $hover;
      background-color: $contrastBackground;
    }
    .ant-select-item {
      color: $text;
    }

    .ant-input-group-addon button {
      background-color: $hover !important;
      color: $text !important;
    }
    .ant-pagination li button {
      background-color: $hover !important;
      color: $text !important;
    }

    .ant-pagination li {
      background-color: $hover !important;
      color: $text !important;
    }

    .advanced-filters {
      .ant-input {
        background-color: $contrastBackground !important;
      }
      .ant-btn {
        background-color: $contrastBackground !important;
      }
    }
    .bottom-tab {
      background: $contrastBackground !important;
      color: $text !important;
    }
    .darkflow-tabs {
      .MuiTab-wrapper {
        color: $text;
      }
    }
    .darkflowCardsHeader {
      color: $text;
    }
    .darkflowCards {
      background: $background;
      .darkflowCard {
        background: $contrastBackground !important;
        color: $text;
        &--header {
          .ticker a {
            color: $text;
          }
          .companyName a {
            color: $text;
          }
        }
      }
    }
    .strongbuy-divider {
      border-bottom: 1px solid #444;
      height: 1px;
      margin-top: 5px;
      margin-bottom: 5px;
    }
    .strongbuy-row {
      .strongbuy-name {
        a {
          color: $text;
        }
      }
      .strongbuy-chart {
        a {
          color: $text;
        }
      }
    }
  }
  &.light {
    .PhoneInputInput {
      background-color: white;
    }
    background-color: #F8FAFC;
    .caret.up {
      border-bottom-color: white;
    }
  }

  &.media {
    display: block !important;
  }
}

.bottom-tab {
  background: #ddd !important;
  text-align: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  font-size: 12px;
  padding: 3px;
  width: 100%;
  font-weight: bold;
  cursor: pointer;
}

.gauge {
  position: relative;
  width: 80px;
  height: 40px;
  overflow: hidden;
  transform: scale(0.8);
  left: -8px;
}

.ats-equity-gauge {
  position: relative;
  width: 80px;
  height: 40px;
  overflow: hidden;
}

.ats-equity-gauge:before,
.ats-equity-gauge:after,
.meter {
  position: absolute;
  display: block;
  content: "";
}

.ats-equity-gauge:before,
.meter {
  width: 80px;
  height: 40px;
}
.ats-equity-gauge:before {
  border-radius: 40px 40px 0 0;
  background: #dee1e5;
}

.ats-equity-gauge:after {
  position: absolute;
  bottom: 0;
  left: 15px;
  width: 50px;
  height: 25px;
  background: #fff;
  border-radius: 25px 25px 0 0;
}

.gauge-caption {
  position: absolute;
  top: -25px;
  font-size: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.gauge-indicator {
  width: 3px;
  height: 25px;
  background: #eecf46;
  top: -5px;
  position: absolute;
  left: 50%;
  z-index: 99999;
  transform: translateX(-50%);
}

.gauge:before,
.gauge:after,
.meter {
  position: absolute;
  display: block;
  content: "";
}

.gauge:before,
.meter {
  width: 80px;
  height: 40px;
}
.gauge:before {
  border-radius: 40px 40px 0 0;
  background: #dee1e5;
}

.gauge:after {
  position: absolute;
  bottom: 0;
  left: 15px;
  width: 50px;
  height: 25px;
  background: #fff;
  border-radius: 25px 25px 0 0;
}

.meter {
  top: 100%;
  transition: 1.5s;
  transform-origin: center top;
  border-radius: 0 0 48px 48px;
  background: deepskyblue;
}

.meter.green {
  background: #9bcb69;
}

.meter.red {
  background: #ef5854;
}

.meter.blue {
  background: rgb(78, 78, 230);
}

.meter.yellow {
  background: #eecf46;
}

@for $i from 0 through 180 {
  .gauge .meter.deg-#{$i} {
    transform: rotate(#{$i}deg);
  }

  .ats-equity-gauge .meter.deg-#{$i} {
    transform: rotate(#{$i}deg);
  }
}

.strongbuys {
  span {
    font-size: 10px;
    opacity: 0.7;
  }
}
.strongbuy-row {
  .strongbuy-name {
    .ticker {
      font-size: 16px;
      font-weight: 600;
    }
    .name {
      font-size: 12px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    a {
      color: black;
    }
  }
  .strongbuy-chart {
    a {
      color: black;
    }
  }
  .strongbuy-price {
    div {
      font-weight: 600;
      font-size: 14px;
    }
    span {
      font-size: 12px;
      &.up {
        color: #6dc92f;
      }
      &.down {
        color: #ef5656;
      }
    }
  }
}

.strongbuy-divider {
  border-bottom: 1px solid #ddd;
  height: 1px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.darkflowCards {
  margin-top: 14px;
  background: #F8FAFC;
  display: block;

  .darkflowTab {
    width: 50%;
  }
  .darkflowCard {
    width: 100%;
    background: #fff;
    border-radius: 25px;
    padding: 15px;
    &--header {
      img {
        width: 100px;
        height: 60px;
        object-fit: contain;
        padding-right: 4px;
      }
      .ticker a {
        color: black;
        font-weight: 800;
        font-size: 22px;
      }
      .companyName {
        white-space: nowrap;
        a {
          color: black;
          opacity: 0.7;
        }
        margin-top: 7px;
        height: auto;
      }
    }
    &--divider {
      border-bottom: 1px solid #88888880;
      margin: 5px;
    }
    &--chart {
      min-height: 126px;
      padding-top: 5px;
      .ohl {
        text-align: right;
      }
      .price {
        font-weight: 800;
        font-size: 20px;
        .priceChange {
          display: inline-block;
          font-weight: normal;
          font-size: 14px;
          text-align: right;
          line-height: 36px;
          float: right;
          &.up {
            color: rgb(109, 201, 47);
          }
          &.down {
            color: red;
          }
        }
      }
      .chart-open {
        opacity: 0.7;
      }
      .chart-high {
        color: rgb(109, 201, 47);
      }
      .chart-low {
        color: red;
      }
    }
    &--bottom {
      .darkflow-volume,
      .darkflow-flow,
      .darkflow-total-volume {
        display: inline;
        .value {
          display: inline;
        }
      }
      .darkflow {
        // border-right: 1px solid #88888888;
      }
      .darkflow-heading {
        color: #aaa;
      }
      .darkflow-score {
        font-size: 16px;
        font-weight: 800;
      }
      .darkflow-multiplier {
        color: rgb(109, 201, 47);
        font-weight: 800;
        font-size: 15px;
      }

      .options-heading {
        color: #aaa;
      }
      .options-flow {
        font-size: 16px;
        font-weight: 800;
      }
    }
  }
}

.companyLogo {
  &.placeholder {
    &.dark {
      filter: invert(1);
    }
  }
}

.hoverbg {
  padding: 10px;
  border-radius: 10px;
  margin: -10px;
}
.hoverbg:hover {
  background: #88888822;
}

.MuiTabs-flexContainer {
  border-block-end: 2px solid #e4e2e2;
}

.top {
  box-shadow: 0 -3px 5px -5px rgb(184, 181, 181);
}

.MuiTab-root {
  min-width: 1px !important;
}
.App {
  text-align: center;
}

ul {
  padding: 0;
  list-style-type: none;
}

/* .text-white {
  color: white;
} */

.custom-table-row {
  font-size: 14px;
}

.form-and-toggle {
  margin-top: 20px;
  margin-bottom: 20px;
}

.markets-tickers-sidebar-container {
  padding: 0px;
}

/* .markets-index-stockbox-wrapper {
  margin-bottom: 10px;
}
.markets-index-stockbox {
  font-size: 20px;
  padding-bottom: 0px;
} */

/* .stock-box-positive {
  color: #20c997;
}

.stock-box-negative {
  color: red;
} */

.markets-index-stockname {
  margin-bottom: 0px;
}

.css-2b097c-container {
  width: 100%;
}

.insider-loading-spinner {
  font-size: 300px;
}

.container-fluid-nav {
  width: 100%;
}

.navbar-nav {
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

/* .savings-account-card:first {
  box-shadow: 0 0 5px 5px rgba(0, 255, 0, 0.2);
} */
.savings-account-card {
  -moz-box-shadow: 0 0 3px #ccc;
  -webkit-box-shadow: 0 0 3px #ccc;
  box-shadow: 0 0 3px #ccc;
  margin-bottom: 10px;
  padding: 10px;
}
/*
.savings-account-bank-name {
  font-size: 20px;
} */
/* .savings-account-img {
  max-width: 200px;
  display: block;
  margin: 15px auto;
} */

.analyst-pick-item {
  margin-bottom: 10px;
}

.savings-account-card:first-child {
  box-shadow: 0 0 5px 5px rgba(0, 255, 0, 0.2);
}
.interest-calc-bg {
  background: #22263f;
}

.flex-wrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
}

.div-calc-explanation {
  font-size: 20px;
}

.secondary-text-label {
  font-size: 15px;
}

/* .ant-input.ant-select {
  border: none;
} */

.ant-table-layout-fixed table {
  table-layout: auto;
}

.ant-select-selection-selected-value {
  width: 100%;
  padding-right: 30px;
}

.tooltip-hover,
.tooltip-hover:hover,
.tooltip-hover:focus,
.tooltip-hover:visited {
  border: none;
  padding: 0;
  margin: 0 10px;
  background: none;
  box-shadow: none;
  color: black;
}

.mutual-fund-data {
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
}

.container-xl-live {
  max-width: 100% !important;
}
@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    // max-width: 1500px;
    max-width: 100%;
  }

  .gauge {
    transform: scale(0.7);
    left: -10px;
  }
}

.card-info-table {
  font-size: 14pt;
  text-align: center;
}
.dot {
  height: 25px;
  width: 25px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}
/*ANTD */
/*ANTD */
/*ANTD */
/*ANTD */
/*ANTD */
/*ANTD */
/*ANTD */
/*ANTD */
/*ANTD */
/*ANTD */
/*ANTD */
/*ANTD */
/*ANTD */
/*ANTD */
/*ANTD */
/*ANTD */
/*ANTD */
/*ANTD */
/*ANTD */
/*ANTD */
/*ANTD */
/*ANTD */
/*ANTD */
/*ANTD */
/*ANTD */
/*ANTD */
/*ANTD */
/*ANTD */
/*ANTD */
/*ANTD */
/*ANTD */
/*ANTD */

/*Tables Styles*/
.ant-table-small > .ant-table-content > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-body > table,
.ant-table-small
  > .ant-table-content
  > .ant-table-scroll
  > .ant-table-header
  > table,
.ant-table-small
  > .ant-table-content
  > .ant-table-scroll
  > .ant-table-body
  > table,
.ant-table-small
  > .ant-table-content
  > .ant-table-fixed-left
  > .ant-table-header
  > table,
.ant-table-small
  > .ant-table-content
  > .ant-table-fixed-right
  > .ant-table-header
  > table,
.ant-table-small
  > .ant-table-content
  > .ant-table-fixed-left
  > .ant-table-body-outer
  > .ant-table-body-inner
  > table,
.ant-table-small
  > .ant-table-content
  > .ant-table-fixed-right
  > .ant-table-body-outer
  > .ant-table-body-inner
  > table {
  padding: 0;
}
.ant-table-small > .ant-table-content > .ant-table-body {
  margin: 0;
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  word-break: normal;
}
.table-responsive {
  overflow: hidden;
}
.MuiPaper-root{
  overflow: hidden;
}
.gx-table-responsive .ant-table {
  min-height: 0.01%;
  overflow-x: auto;
}
@media screen and (max-width: 767px) {
  .gx-table-responsive .ant-table {
    width: 100%;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: solid 1px #e8e8e8;
  }
  .ant-card-bordered.gx-card-full .gx-table-responsive .ant-table {
    border: 0 none;
    border-bottom: solid 1px #e8e8e8;
  }
  .gx-table-responsive .ant-table .ant-table-tbody > tr:last-child > td {
    border-bottom: 0 none;
  }
  .gx-table-responsive .ant-table.ant-table-bordered .ant-table-header > table,
  .gx-table-responsive .ant-table.ant-table-bordered .ant-table-body > table,
  .gx-table-responsive
    .ant-table.ant-table-bordered
    .ant-table-fixed-left
    table,
  .gx-table-responsive
    .ant-table.ant-table-bordered
    .ant-table-fixed-right
    table {
    border-top: 0 none;
    border-left: 0 none;
  }
  .gx-table-responsive .ant-table.ant-table-bordered .ant-table-title {
    border: 0 none;
  }
  .gx-table-responsive .ant-table.ant-table-bordered .ant-table-footer {
    border: 0 none;
    border-top: solid 1px #e8e8e8;
  }
  .gx-table-responsive
    .ant-table.ant-table-bordered
    .ant-table-thead
    > tr
    > th:last-child,
  .gx-table-responsive
    .ant-table.ant-table-bordered
    .ant-table-tbody
    > tr
    > td:last-child {
    border-right: 0 none;
  }
  .gx-table-responsive .ant-table-body > table > thead > tr > th,
  .gx-table-responsive .ant-table-body > table > tbody > tr > th,
  .gx-table-responsive .ant-table-body > table > tfoot > tr > th,
  .gx-table-responsive .ant-table-body > table > thead > tr > td,
  .gx-table-responsive .ant-table-body > table > tbody > tr > td,
  .gx-table-responsive .ant-table-body > table > tfoot > tr > td {
    white-space: nowrap;
  }

  .gx-table-padding-lg thead th,
  .gx-table-padding-lg tbody td {
    padding: 10px !important;
  }

  .gauge {
    transform: scale(1) !important;
  }
}

@media screen and (max-width: 525px) {
  .gauge {
    transform: scale(0.7) !important;
    left: -10px;
  }
}
.gx-table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}
.gx-table th,
.gx-table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: solid 1px #e8e8e8;
}
.gx-table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #e8e8e8;
}
.gx-table tbody + tbody {
  border-top: 2px solid #e8e8e8;
}
.gx-table-bordered {
  border: 1px solid #e8e8e8;
}
.gx-table-bordered th,
.gx-table-bordered td {
  border: 1px solid #e8e8e8;
}
.gx-table-bordered thead th,
.gx-table-bordered thead td {
  border-bottom-width: 2px;
}
.gx-table-no-bordered {
  border: 0 none;
  padding: 10px;
}
.gx-table-no-bordered th,
.gx-table-no-bordered td {
  border: 0 none;
}
.gx-table-no-bordered thead th,
.gx-table-no-bordered thead td {
  border-bottom-width: 2px;
}
.gx-table-no-bordered .ant-table {
  border: 0 none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.gx-table-no-bordered .ant-table-thead > tr > th {
  font-weight: 300;
  text-transform: uppercase;
  font-size: 11px;
}
.gx-table-no-bordered .ant-table-tbody > tr > td {
  border-bottom: 0 none;
}
.gx-table-thead-uppercase thead th {
  text-transform: uppercase;
}
.gx-table-padding-lg thead th,
.gx-table-padding-lg tbody td {
  padding: 20px 30px !important;
}
@media screen and (max-width: 991px) {
  .gx-table-padding-lg thead th,
  .gx-table-padding-lg tbody td {
    padding: 15px 20px !important;
  }

  .gx-table-padding-lg .ant-table-pagination {
    margin: 16px 0;
  }

  .gauge {
    transform: scale(1.5);
    margin: 0 auto;
    left: 0;
  }
}

.gx-table-padding-lg .ant-table-pagination {
  float: none;
  text-align: center;
  margin: 22px 0;
}
/*Time Lines Style*/
/*Antd Time Lines Style*/
.ant-timeline-item {
  padding: 0;
}
.ant-timeline-item:not(:last-child) {
  padding-bottom: 20px;
}
@media screen and (max-width: 575px) {
  .ant-timeline-item:not(:last-child) {
    padding-bottom: 15px;
  }
}

.gx-form-group {
  margin-bottom: 10px;
}

.gx-search-bar {
  position: relative;
}
.gx-search-bar .gx-form-group {
  width: 100%;
  height: 100%;
  margin-bottom: 0;
}
.gx-search-bar input[type="search"] {
  padding-right: 35px;
  background: rgba(250, 250, 250, 0.1);
}
.ant-layout-header .gx-search-bar input[type="search"],
.ant-popover-content .gx-search-bar input[type="search"] {
  border: 0 none;
}
.gx-search-bar input[type="search"]:focus {
  box-shadow: none;
  /* background-color: rgba(250, 250, 250, 0.3); */
}
.gx-search-bar .gx-search-icon {
  background-color: transparent;
  border: 0 none;
  color: #003366;
  height: 32px;
  width: 30px;
  line-height: 36px;
  text-align: center;
  position: absolute;
  right: 0;
  top: 0;
}
.gx-algolia-header .gx-search-bar .gx-search-icon {
  margin-top: 2px;
  text-align: left;
}
.gx-search-bar .ant-input-lg + .gx-search-icon {
  height: 40px;
  line-height: 45px;
}

.ant-select-dropdown.search-bar-dropdown.ant-select-dropdown-placement-bottomLeft {
  top: 50px !important;
  left: 0 !important;
  border-radius: 0px !important;
}

.customTooltip {
  font-size: 14px!important;
}


.stock-wall-tabs-filter-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.stock-wall-filter-wrapper {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 28px;
}

.stock-wall-filter-sort-by-caption {
  margin-right: 30px;
  color: #8e98a6;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;
  font-family: 'Inter', sans-serif;
  letter-spacing: 2px;
  position: relative;
  top: 5px;
}

body.dark .stock-wall-filter-label {
  color: #FFFFFF;
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .stock-wall-filter-label {
    font-size: 13px!important;
  }

  .stock-wall-filter-sort-by-caption {
    margin-right: 20px !important;
    font-size: 13px !important;
  }

  .gauge {
    transform: scale(0.5) !important;
    left: -20px!important;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .strongbuy-row {
    width: 80%;
    margin: 0 auto!important;
  }

  .stock-wall-tabs-filter-wrapper {
    flex-direction: column;
  }
}

@media screen and (max-width: 991px) {
  .gx-table-padding-lg thead th,
  .gx-table-padding-lg tbody td {
    padding: 15px 20px !important;
  }

  .gx-table-padding-lg .ant-table-pagination {
    margin: 16px 0;
  }

  .stock-wall-tabs-filter-wrapper {
    flex-direction: column;
  }

  .stock-wall-filter-wrapper {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .stock-wall-filter-sort-by-caption {
    margin-right: 0!important;
  }
}

@media screen and (max-width: 767px) {
  .gx-table-padding-lg thead th,
  .gx-table-padding-lg tbody td {
    padding: 10px !important;
  }

  .stock-wall-filter-label {
    font-size: 14px!important;
  }

  .stock-wall-tabs > li > button {
    font-size: 12px!important;
  }
}

img.dashboard-top-gradient {
  position: absolute;
  width: 600px;
  top: -90px;
  left: 0;
}

img.dashboard-bottom-gradient {
  position: fixed;
  width: 600px;
  bottom: 0;
  right: 0;
}

body.home img.dashboard-top-gradient, body.home img.dashboard-bottom-gradient, body.company img.dashboard-bottom-gradient {
  z-index: 10;
}

#header-search-bar {
  z-index: 1000;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, .MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: none!important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none!important;
}

body.dark .search-wrapper .ant-select-selection-search-input::placeholder {
  color: #79797B !important;
}

body.dark .MuiDataGrid-toolbarContainer input::placeholder {
  color: #FFFFFF!important;
}

body.light .MuiDataGrid-toolbarContainer svg, body.light .MuiDataGrid-toolbarContainer input {
  color: #000000;
}

body.light .MuiDataGrid-toolbarContainer input::placeholder {
  color: #000000!important;
}

body.light .MuiDataGrid-columnHeader, body.light .MuiDataGrid-cell, body.light .MuiDataGrid-menuIcon svg, body.light .MuiDataGrid-cell:first-of-type {
  // background-color: #79797B;
  // color: #FFFFFF;
  background-color: #ffffffcc;
  color: #000000;
}

body.light .MuiDataGrid-iconButtonContainer svg {
  color: #000000;
  background-color: transparent;
}

body.light .MuiTablePagination-root, body.light .MuiTablePagination-selectIcon {
  color: #000000;
}

body.light .MuiTablePagination-select{
  border: 1px solid #79797B;
}

body.light .MuiDataGrid-columnHeaderTitle {
  // color: #FFFFFF!important;
  color: #000!important;
}

body.light .loader-notification {
  color: #000000;
}

.MuiSlider-rail {
  background-color: #FFFFFF!important;
}

body.light .MuiSlider-rail {
  background-color: #2F2E31!important;
}

body.light .MuiDataGrid-root .MuiDataGrid-columnsContainer {
  border-bottom: 1px solid #000000!important;
}

body.light .MuiDataGrid-columnsContainer {
  border-right: 2px solid rgba(255, 255, 255, 0.8);
}

body.light thead tr .MuiTableCell-root {
  color: #000!important;
  font-weight: bold;
  background-color: rgba(255, 255, 255, 0.8);
}

body.dark tbody tr .MuiTableCell-root {
  color: #000;
  background-color: #fff;
}

body.light tbody tr  .MuiTableCell-root {
  background-color: rgba(255, 255, 255, 0.8);
  color: #000000;
  // font-weight: bold;
}