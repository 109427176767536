.historical-activity-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.historical-type-selector {
  display: flex;
  justify-content: flex-end;
  padding: 0 16px;
}

.historical-activity-header-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .historical-activity-hours {
    font-style: normal;
    font-weight: 450;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: -0.04em;
    color: #6d6f71;
    padding: 8px 36px;
    cursor: pointer;
    height: 40px;
    display: flex;
    align-items: center;
  }
}

@media (max-width: 740px) {
  .historical-activity-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .historical-activity-header .historical-activity-header-buttons {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
  }

  .historical-activity-header .historical-activity-header-buttons .historical-activity-hours {
    justify-content: center;
    width: 25%;
  }
}

@media (max-width: 460px) {
  .historical-activity-header .historical-activity-header-buttons .historical-activity-hours {
    font-size: 14px;
    padding: 8px;
  }
}