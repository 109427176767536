.docusign-modal {
    text-align: left;
    background: white;
    width: 90%;
    height: auto;
    position: absolute;
    padding: 40px 40px 20px 40px;
    overflow-y: auto;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    overflow: hidden;
    border-radius: 10px;
}

.modal-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.top-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
}

.bottom-text {

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    color: #7A7A7A;

}

.MuiLinearProgress-bar {
    background: linear-gradient(90deg, #00B208 1.67%, #4ADA51 100%) !important;
}

.MuiButton-startIcon .MuiSvgIcon-root {
    fill: black !important;
    color: black !important;
}

.icon-flipped {
    transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
}

.docusignModal-action-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* margin-top: 15px; */
    /* min-height: 80px; */
    margin-top: 120px;
}

.docusignModal-action-buttons button {
    color: #000000;
    text-transform: initial;
}

@media (min-width: 992px) {
    .docusign-modal {
        width: 50%;
        height: auto;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        border-radius: 10px;
    }

    .docusign-modal:focus-visible {
        outline: none;
    }
}

.progress-text {
    display: flex;
    justify-content: flex-end;
    font-size: 20px;
    color: #b9b9b9;
    font-weight: bold;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;

    color: #BFBFBF;
}

.docu-container {
    max-width: 490px;
    min-height: 405px;
    margin: auto;
    width: 100%;
    height: auto;
}

.docu-container-header {
    background: #1E4CA1;
    color: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    height: 120px;
    align-items: flex-end;
    justify-content: space-between;
    padding: 16px;
}

.docu-container-status-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
}

.docu-sign-logo {
    width: 140px;
}

.docu-container-body {
    padding: 16px;
    background: #FAFAFA;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.docu-container-body-top {
    padding-bottom: 24px;
}

.docu-container-body-top-fromsection {
    margin-bottom: 30px;
}

.blue-from-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: #1E4CA1;
}

.docu-container-body-top-fromsection-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: #0B0B0B;
    margin-left: 30px;
}

.docu-container-body-top-normal-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 11px;
    color: #0B0B0B;
}

.docu-container-body-button {
    padding: 12px;
    border-top: 1px solid #E4E5E8;
    border-bottom: 1px solid #E4E5E8;
}

.docu-container-body-button-inputs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.docu-container-body-button-inputs .half-width {
    width: calc(50% - 5px);
}

.docu-container-body-action-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
}

img.pdf-download {
    width: 15px;
    margin-right: 8px;
    cursor: pointer;
}

.docu-sign-pdf-view {
    padding: 8px 45px!important;
    background-color: #F8C433!important;
    border-radius: 4px!important;
    font-family: 'Inter'!important;
    font-style: normal!important;
    font-weight: 700!important;
    font-size: 14px!important;
    line-height: 17px!important;
    color: #0B0B0B!important;
    cursor: pointer!important;
}

.docu-sign-pdf-view:disabled {
    background-color: rgb(227, 232, 233)!important;
}

.docu-container-body-footer {
    padding: 14px 0;
}

.docu-container-body-footer-main-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 9px;
    line-height: 10px;
    color: #0B0B0B;
    text-align: center;
}

.docu-container-body-footer-main-text a {
    font-size: 9px;
}

.docu-container-body-footer-copyright {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 10px;
    color: #0B0B0B;
    margin-top: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}

.docu-container-body-top-big-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    padding: 16px 0px;
    color: #0B0B0B;
}

.tradealgo-text-logo {
    width: auto;
    height: 21px;
}

.tradealgo-text-logo-label {
    color: #808080;
    font-size: 16px;
    margin-left: 5px;
}

.docusignModal-action-text {
    /* width: 320px; */
    width: 100%;
    text-align: center;
}

.docusignModal-action-text-title {
    /* font-family: 'Circular Std';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: #000000; */
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 64px;
    text-align: center;

    /* Black */

    color: #121416;

}

.docusignModal-action-text-info {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 450;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.05em;
    color: #6E6E70;

    width: 320px;
    margin: 0 auto;
    margin-top: 8px;
}

img.docusignModal-logo-text {
    width: 177px;
    margin: 0 auto;
    display: block;
    margin-bottom: 20px;
    margin-top: 80px;
}

.no-margin-top {
    margin-top: 0!important;
}

.no-margin-bottom {
    margin-bottom: 0!important;
}