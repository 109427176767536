$direction-map: null;
$direction-map: null;
$theme-map: null;
$themes: (
  light: (
    colorBackground: white,
    colorBackgroundBody: #f2f4f7,
    inboxButtonBackground: #f2f4f7,
    inboxButtonBackgroundHover: darken(#f2f4f7, 5%),
    colorHeaderDataPicker: #6db0ff,
    colorText: #646777,
    colorTextAdditional: #646777,
    logoImg: url(../../shared/img/logo/logo_light.svg),
    colorHover: #fafbfe,
    colorFolderHover: #f0eeee,
    colorBorder: #eff1f5,
    colorIcon: #dddddd,
    imgInvert: invert(0%),
    colorFieldsBorder: #f2f4f7,
    colorBubble: rgba(242, 244, 247, 0.65),
    colorBubbleActive: rgba(234, 238, 255, 0.6),
    colorScrollbar: #b4bfd0,
    colorFitness: #646777,
    colorEmoji: #232329,
    sidebarColor: aliceblue,
  ),
  dark: (
    colorBackground: #232329,
    colorBackgroundBody: #2a2a31,
    inboxButtonBackground: #2a2a31,
    inboxButtonBackgroundHover: lighten(#2a2a31, 5%),
    colorHeaderDataPicker: #063263,
    colorText: #dddddd,
    colorTextAdditional: #999999,
    logoImg: url(../../shared/img/logo/logo_dark.svg),
    colorHover: #38373f,
    colorFolderHover: #ffffff1a,
    colorBorder: #333246,
    colorIcon: #605f7b,
    imgInvert: invert(100%),
    colorFieldsBorder: #33333a,
    colorBubble: rgba(68, 79, 97, 0.65),
    colorBubbleActive: rgba(92, 104, 156, 0.6),
    colorScrollbar: #606071,
    colorFitness: #ffffff,
    colorEmoji: #ffffff,
    sidebarColor: #232329,
  ),
);

@mixin themify($themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}

$color-accent: #4ce1b6;
$color-light-accent: #55e5bb;
$color-accent-hover: darken($color-accent, 10%);
$color-additional: #999999;
$color-additional-hover: darken($color-additional, 10%);

$color-yellow: #f6da6e;
$color-light-yellow: #f4dd87;
$color-yellow-hover: darken($color-yellow, 10%);

$color-violet: rgba(130, 119, 223, 1);

$color-red: #ff4861;
$color-dark-red: #721c24;
$color-light-red: #f88e7d;
$color-very-light-red: #f8d7da;
$color-red-hover: darken($color-red, 10%);

$color-blue: #70bbfd;
$color-light-blue: #87c3f7;
$color-blue-hover: darken($color-blue, 10%);

$color-black: #000000;
$color-gray: #787985;
$color-light-gray: #d8dfe9;
$color-dusty-white: #dddddd;
$color-white: #ffffff;

$color-green: #b8e986;
$color-green-hover: darken($color-green, 10%);

$color-dark-fields-border: #33333a;
$color-black-background: #232329;
$color-background-body: #f2f4f7;
$color-text: #646777;
$color-hover: #fafbfe;

$directions: (
  ltr: (
    direction: ltr,
    right: right,
    left: left,
    margin-right: "margin-right",
    margin-left: "margin-left",
    padding-left: "padding-left",
    padding-right: "padding-right",
    border-top-left-radius: "border-top-left-radius",
    border-bottom-left-radius: "border-bottom-left-radius",
    border-top-right-radius: "border-top-right-radius",
    border-bottom-right-radius: "border-bottom-right-radius",
    border-left: "border-left",
    border-right: "border-right",
    translate: "translate(-50%, -50%);",
    mirrorY: "scale(1, 1)",
    border-right-color: "border-right-color",
    transform-sidebar: "rotate(90deg)",
    flex-flow: row nowrap,
    row: "row",
    sidebar-close: translateX(0),
    sidebar-no-desktop: translateX(calc(0%)),
    "-": "-",
  ),
  rtl: (
    direction: rtl,
    right: left,
    left: right,
    margin-right: "margin-left",
    margin-left: "margin-right",
    padding-left: "padding-right",
    padding-right: "padding-left",
    border-top-left-radius: "border-top-right-radius",
    border-bottom-left-radius: "border-bottom-right-radius",
    border-top-right-radius: "border-top-left-radius",
    border-bottom-right-radius: "border-bottom-left-radius",
    border-left: "border-right",
    border-right: "border-left",
    translate: "translate(50%, -50%);",
    mirrorY: "scale(1, -1)",
    border-right-color: "border-left-color",
    transform-sidebar: "rotate(-90deg)",
    flex-flow: row-reverse nowrap,
    row: "row-reverse",
    sidebar-close: translateX(0),
    sidebar-no-desktop: translateX(calc(100%)),
    "-": "+",
  ),
);
@mixin directify($directions) {
  @each $direction, $map in $directions {
    .#{$direction}-support & {
      $direction-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($directions, $direction), "#{$key}");
        $direction-map: map-merge(
          $direction-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $direction-map: null !global;
    }
  }
}

@function directed($key) {
  @return map-get($direction-map, $key);
}
.unusual {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
}
.nav {
  @include directify($directions) {
    #{directed('padding-left')}: 10px;
  }
}
.tabtext {
  font-family: Inter;
font-style: normal;
font-weight: normal;
font-size: 16px;
color: #A2A3A5;
}
.tabs {
  overflow: auto;

  .tabs__wrap {
    @include directify($directions) {
      text-align: directed("left");
    }
    min-width: min-content;
    //-webkit-min-width: min-content;
  }

  .nav-tabs {
    border-bottom: none;
    li {
      .nav-link {
        padding: 10px 20px;
        @include directify($directions) {
          #{directed('margin-right')}: 0px;
        }
        transition: background-color 0.3s;
        cursor: pointer;

        @include themify($themes) {
          color: themed("colorText");
        }

        &:hover,
        &:focus,
        &:active {
          outline: none;

          @include themify($themes) {
            background-color: themed("colorHover");
            border-color: themed("colorHover");
            border-bottom-color: $color-dusty-white;
          }
        }
      }

      .nav-link.active,
      .nav-link.active:focus,
      .nav-link.active:hover {
        background-color: transparent;

        @include themify($themes) {
          border-color: $color-dusty-white;
          border-bottom-color: themed("colorBackground");
          color: themed("colorText");
        }
      }

      &.disabled .nav-link {
        color: $color-additional;
      }
    }
  }

  .tab-pane {
    padding-top: 20px;
  }

  &.tabs--bordered-top {
    .nav-tabs {
      li .nav-link {
        border-top: 2px solid transparent;
        border-radius: 0;
      }

      li .nav-link.active {
        &,
        &:focus,
        &:hover {
          border-radius: 0;
          border-top: 2px solid $color-accent;
        }
      }
    }
  }

  &.tabs--bordered-bottom {
    .nav-tabs {
      li .nav-link {
        border-color: transparent;
        border-bottom: 1px solid transparent;
        padding-bottom: 11px;
      }

      li .nav-link.active {
        &,
        &:focus,
        &:hover {
          border-radius: 0;
          border-color: transparent;
          border-bottom: 4px solid $color-accent;
          padding-bottom: 10px;
        }
      }
    }
  }
  
  &.tabs--justify {
    .nav-tabs {
      display: flex;
      flex-wrap: nowrap;

      li {
        // width: 155px;

        a {
          text-align: center;
        }
      }
    }
  }

  &.tabs--vertical {
    overflow-y: auto;

    .tabs__wrap {
      display: flex;
    }

    .nav-tabs {
      border-bottom: none;
      width: 90px;
      height: 100%;
      flex-wrap: wrap;

      .nav-item {
        margin-bottom: 0;
      }

      li {
        margin-top: 0;
        width: 100%;

        .nav-link {
          @include directify($directions) {
            #{directed('padding-right')}: 10px;
            #{directed('padding-left')}: 0px;
            #{directed('margin-right')}: 0px;
            #{directed('border-right')}: 2px solid #dddddd;
          }
          padding-top: 10px;
          padding-bottom: 10px;
          transition: background-color 0.3s;
          border-radius: 0;
          border: none;

          @include themify($themes) {
            color: themed("colorText");
          }

          &:hover,
          &:focus,
          &:active {
            outline: none;

            @include themify($themes) {
              background-color: themed("colorHover");
            }
          }
        }

        .nav-link.active,
        .nav-link.active:focus,
        .nav-link.active:hover {
          background-color: transparent;

          @include directify($directions) {
            #{directed('border-right-color')}: $color-accent;
          }
        }

        &.disabled .nav-link {
          color: $color-additional;
        }
      }
    }

    .tab-pane {
      padding-top: 0;
      @include directify($directions) {
        #{directed('padding-left')}: 20px;
      }
    }

    &.tabs--vertical-colored {
      .nav-tabs {
        li {
          .nav-link {
            @include directify($directions) {
              #{directed('border-right-color')}: #eeeeee;
            }
            @include directify($directions) {
              #{directed('padding-left')}: 10px;
            }
            text-align: center;
          }

          .nav-link.active,
          .nav-link.active:focus,
          .nav-link.active:hover {
            background-color: #eeeeee;
            @include directify($directions) {
              #{directed('border-right-color')}: #eeeeee;
            }
            color: $color-text;
          }
        }
      }
    }
  }
}

.unusual-activity-table.table thead th {
  border-bottom: 1px solid #dee2e6;
  border-top: none;
}
