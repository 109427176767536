/*** Company Page Styles ***/
.company-nav-header {
  padding: 16px 16px 8px 16px;
  font-family: "Circular Std";

  .company-nav-header-back-button {
    font-style: normal;
    font-weight: 450;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: #aeaeb2;
    margin-bottom: 4px;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    width: auto;

    span {
      margin-right: 10px;
    }
  }
}

.company-container {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding: 8px 16px;
  flex-direction: row;

  @media (max-width: 992px) {
    flex-direction: column;
  }

  .one-third-width {
    width: calc(100% / 3 - 4px);
  }
  .second-third-width {
    width: calc(100% / 3 * 2 - 4px);
  }

  .half-width {
    width: calc(50% - 10px);
  }

  .full-width {
    width: 100% !important;
  }

  .flex-start {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media (max-width: 1200px) {
      flex-direction: column;
    }
  }

  .non-forex-page {
    .company-chart-wrapper {
      width: 100%!important;
    }
  }

  .company-chart-wrapper {
    width: calc(60% - 8px);

    @media (max-width: 1200px) {
      width: 100%;
    }
  }

  .company-news-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    // background-color: yellow;
    justify-content: space-between;
    width: calc(40% - 8px);
    @media (max-width: 1200px) {
      width: 100%;
    }
  }

  .company-container-row {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    @media (max-width: 992px) {
      margin-top: 8px;
    }
  }

  .main-chart-container {
    .component-section-title {
      font-family: "Circular Std";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      letter-spacing: -0.04em;
      padding: 8px 8px 4px 8px;
      background-color: #fff;
    }
  }
}

.ai-sentiment-only {
  margin-top: 40px;
}

body.light .company-nav-header .company-nav-header-back-button {
  color: #000;
}
