.darkMode {
  background: #172027;
}

.darkMode .home-font-family {
  background: none !important;
}

.darkMode .ant-select-selection-search input {
  background: none;
  border-radius: 15px;
  background-color: #171f27;
  border: 2px solid #29425b;
  color: #3f6082;
  font-weight: 400;
  font-family: "Inter";
}

.darkMode .search-bar .ant-select-selection-search .ant-select-selection-placeholder {
  font-size: 19px;
  line-height: 39px;
  color: #3f6082;
  font-weight: 400;
  font-family: "Inter";
}

.darkMode .switchMode .ant-switch-checked{
  background-color: #1f3347;
}

.darkMode .switchMode .ant-switch-handle::before{
  background-color: #0167ff;
}

.darkMode .line-clamp-one-line{
  color: #2b4054 !important;
}

.darkMode .summary .bg-white{
  background-color: #1d2935 !important;
}

.darkMode .black-color{
  color:  #fff !important;
}

.darkMode .main-filters .filter-item{
  color: #fff !important;
}

.darkMode .date-filter .ant-picker {
  background: none;
  border: 2px solid #29425b !important;
}

.darkMode .ant-select-selector .ant-input-group {
  height: 42px !important;
}

.darkMode .ant-select-selector .ant-input-group input{
  border-color: #29425b !important;
}

.darkMode .ant-select-selector .ant-input-group button{
  color: #29425b !important;
  background: none !important; 
}

.darkMode .dark-pool-page .content .top-filter .advanced-filters span.ant-input-group-addon{
  background: none;
  border: 2px solid #29425b;
  border-left: 0;
  height: 42px;
}

.darkMode .dark-pool-page .content .top-filter .advanced-filters span.ant-input-group-addon button{
 margin: 0;
 height: 35px;
}

.darkMode table thead th {
  background-color: #172027 !important;
  color: #fff !important;
}

.darkMode table thead th td {
  background-color: #203448 !important;
  color: #fff !important;
}

.darkMode table tr {
  background-color: #203448 !important;
  border-radius: 5px !important;
}

.darkMode table tr td {
  background-color: #203448 !important;
  color: #fff;
}

.darkMode .dark-pool-page .content .dark-pool-datatable-container .dark-pool-datatable tbody tr:nth-child(even) {
  background-color: #142435 !important;
}

.darkMode .dark-pool-page .content .dark-pool-datatable-container .dark-pool-datatable tbody tr:nth-child(even) td {
  background-color: #142435 !important;
}