#info__modal__table td {
  padding: 0px 10px;
}
#info__modal__table th {
  padding: 0px 10px;
  font-family: "Circular Std";
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */

  letter-spacing: -0.02em;

  /* grey/gray 500 */

  color: #ffffff;
}
#header__row {
  background: #121416;
  /* grey/gray 950 */

  border-bottom: 1px solid #171917;
}
#time__info__modal {
  font-family: "Circular Std";
  font-style: normal;
  font-weight: 450;
  font-size: 15px;
  line-height: 23px;
  /* identical to box height */

  letter-spacing: -0.04em;

  /* grey/gray 400 */

  color: #8e8e93;
}
.bold__content__modal {
  font-family: "Circular Std";
  font-style: normal;
  font-weight: 450;
  font-size: 18px;
  line-height: 23px;
  /* identical to box height */

  letter-spacing: -0.04em;

  /* dark */

  color: #000000;
}
