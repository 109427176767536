.bid-ask-base {
  width: 19px;
  height: 17px;
  padding-left: 6px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  text-align: right;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.BB {
  color: #2563EB;
}
.AA {
  color: #0D9488; 
}
.LA {
  color: #DB2777;
}
.LB {
  color: #EA580C;
}
.BBi {
  width: 16px;
  height: 16px;
  padding-left: 4px;
  padding-bottom: 1px;
  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}
.total-price {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  /* color: #0f172a !important; */
  flex-grow: 1;
  flex-basis: 0;
  min-width: 0;
  margin-left: 7px;
}
.avg-price {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  /* color: #0f172a !important; */
  flex-grow: 1;
  flex-basis: 0;
  min-width: 0;
  margin-left: 5px;
}
.unusual-time {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  /* color: #475569; */
  flex-grow: 1;
  flex-basis: 0;
  min-width: 0;
  margin-left: 8px;
}
.table-headers {
  font-size: 15px;
  color: #FFFFFF;
  font-weight: 600;
}
body.light .table-headers {
  color: #000;
}
.table-headers2 {
  font-size: 15px;
  color: #FFFFFF;
  font-weight: 600;
  padding-left: 32px;
}
body.light .table-headers2 {
  color: #000;
}
.large-table {
  position: relative;
  left: 35px;
}
.spot {
  position: relative;
  left: 35px;
}
.strike {
  position: relative;
  left: 8px;
}
.cp {
  position: relative;
  left: 6px;
  font-weight: 600;
}
.red-put {
  color: red;
}
.green-call {
  color: #0dad60;
}

.ppc {
  position: relative;
  left: 9px;
  color: #4c4663;
}
.type-cell {
  font-weight: 700;
  position: relative;
  left: 6px;
}
.prem-cell {
  color: #0dad60;
  position: relative;
  left: 8px;
  font-weight: 500;
  font-size: 15px;
}
.data-grid-wrap {
  width: 100%;
  height: fit-content;
}
.bottom-height-table {
  height: 4px;
}
.ticker {
  position: relative;
  left: 9px;
  font-weight: 600;
  /* color: black !important; */
}
