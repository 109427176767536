.currency-strength-table {
  margin-top: 40px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}

.currency-strength-table-column {
  width: calc(100% / 8 - 8px);
}

.currency-strength-table-green-item {
  background: #00DC0A;
  border-radius: 2px;
  padding: 6px;
  margin-bottom: 8px;
  cursor: pointer;
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
  height: 52px;

  &:hover {
    .currency-strength-table-item-price {
      display: block;
    }

    .currency-strength-table-item-percentage {
      display: none;
    }
  }
}

.currency-strength-table-red-item {
  background: #FA4646;
  border-radius: 2px;
  padding: 6px;
  margin-bottom: 8px;
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
  height: 52px;
  cursor: pointer;
  
  &:hover {
    .currency-strength-table-item-price {
      display: block;
    }

    .currency-strength-table-item-percentage {
      display: none;
    }
  }
}

.currency-strength-table-default-item {
  background: #FFFFFF;
  border-radius: 2px;
  padding: 6px;
  margin-bottom: 8px;
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color: #000000;
}

.currency-strength-table-item-price {
  display: none;
}

@media (max-width: 767px) {
  .currency-strength-table-green-item {
    height: 32px;
  }

  .currency-strength-table-red-item {
    height: 32px;
  }

  .currency-strength-table-item-currency {
    text-align: center;
  }

  .currency-strength-table-default-item {
    justify-content: center;
  }

  .currency-strength-table-item-percentage {
    display: none;
  }

  .currency-strength-table-item-price {
    display: none!important;
  }
}