.alpha-options-sign-up-result {
  background-image: url(../../../assets/images/alpha-sign-up-background.png);
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: calc(100vh - 90px);
  padding: 80px;
}

.alpha-options-sign-up-result-icon-wrapper {
  text-align: center;

  svg {
    color: #00DC0A!important;
    fill: #00DC0A!important;
    width: 200px!important;
    height: 200px!important;
  }
}

.alpha-options-sign-up-result-loading-text {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  color: #FFFFFF;
  margin-top: 50px;
}

.alpha-options-sign-up-result-success-title {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 900;
  font-size: 64px;
  line-height: 81px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #FFFFFF;
  margin-top: 25px;
}

.alpha-options-sign-up-result-success-info {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  color: #FFFFFF;
  margin-top: 30px;
}

.back-to-dashboard {
  border-radius: 5px!important;
  font-family: 'Circular Std'!important;
  font-style: normal!important;
  font-weight: 450!important;
  font-size: 16px!important;
  line-height: 20px!important;
  color: #FFFFFF!important;
  border: 1px solid #045F08!important;
  margin: 0 auto!important;
  display: block!important;
  margin-top: 30px!important;
  text-transform: initial!important;
  padding: 8px 16px!important;
}


@media (max-width: 600px) {
  .alpha-options-sign-up-result {
    padding-left: 40px;
    padding-right: 40px;
  }

  .alpha-options-sign-up-result-success-title {
    font-size: 54px;
    line-height: 71px;
  }

  .alpha-options-sign-up-result-success-info {
    font-size: 16px;
    line-height: 21px;
  }
}