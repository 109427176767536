/*** AI Sentiment Styles ***/
.active-circle {
  background: linear-gradient(
    260deg,
    #00dc0a 10.12%,
    #f1cf54 50.04%,
    #dc0000 83.33%
  ) !important;
}
.ai-sentiment-page {
  width: 100%;
  height: 48%;
  padding: 16px;
  background: #ffffff;
  font-family: "Circular Std";
  margin-top: 1%;
  @media (max-width: 1200px) {
    min-height: 350px;
  }
  .ai-sentiment-title {
    font-family: "Circular Std";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.04em;
    color: #1f1f21;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .ai-sentiment-time-slots {
    width: 100%;
    margin-bottom: 0;

    .ai-sentiment-time-slots-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-bottom: 10px;
    }

    .ai-sentiment-time-slot {
      width: 25%;
      text-align: center;
      font-style: normal;
      font-weight: 450;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.02em;
      border-right: 1px solid #c7c7cc;
      padding: 0 8px;
      text-transform: uppercase;
      color: #8e8e93;
      cursor: pointer;

      &.active {
        color: #00dc0a;
      }

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        border-right: none;
        padding-right: 0;
      }
    }
  }

  .meter-chart {
    position: relative;
    top: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .loader-notification {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    color: #000000;
  }
}
