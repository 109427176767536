.previous-streams-page-title {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  color: #121416;
  margin-bottom: 30px;
}

.previous-streams-page-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.previous-stream-element {
  width: calc(100% / 3 - 15px);
  cursor: pointer;
  margin-bottom: 30px;
}

.previous-stream-date {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #121416;
  margin-top: 10px;
}

.previous-stream-duration {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  color: #6E6E70;
}

@media (max-width: 992px) {
  .previous-stream-element {
    width: 100%;
  }
}