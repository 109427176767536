.mm-success-cards {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 16px;
  }
  
  .mm-success-card {
    margin: 15px;
    margin-left: 0;
    margin-top: 0;
    min-width: inherit !important;
    max-width: inherit !important;
    width: calc(100% / 3 - 10px);
    overflow: visible;
    position: relative;
    border-radius: 0 !important;
    min-height: 420px;
    max-height: 1420px;
    background-color: #F5F5F5!important;
  
    &:nth-child(3n + 1) {
      margin-left: 0;
    }
  
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
  
  .mm-success-card-content {
    padding: 24px !important;
  }
  
  .break-line {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 15px;
    letter-spacing: -0.04em;
    line-break: anywhere;
    color: #000000;
    position: relative;    /* Inside auto layout */
    top: 23px;
    flex: none;
    order: 0;
  }
  
  .expand-more-icon {
    position: absolute !important;
    bottom: 8px;
    right: 8px;
  
    &:hover {
      background-color: rgba(0, 0, 0, 0.4) !important;
    }
  }
  
  .action-button-container {
    display: flex;
    flex-direction: row;
  }
  
  .action-button {
    padding: 16px 24px;
    background: #1F1F21;
    border: none;
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: -0.04em;
    color: #fff;
    margin-right: 16px;
  }
  
  .action-button.active-button {
    background: #00DC0A;
    color: #171917;
  }
  
  @media (max-width: 1300px) {
    .mm-success-card {
      width: calc(50% - 8px);
      &:nth-child(2n) {
        margin-right: 0;
        margin-left: 8px;
      }
  
      &:nth-child(2n + 1) {
        margin-right: 8px;
        margin-left: 0;
      }
    }
  }
  
  @media (max-width: 800px) {
    .mm-success-card {
      width: 100%;
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
  
  @media (max-width: 680px) {
    .action-button-container {
      flex-direction: column;
    }
  
    .action-button {
      margin-right: 0;
      margin-top: 16px;
      width: 100%;
    }
  }