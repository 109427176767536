.gauge-chart-base{
    height: 115px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    position: relative;

    .item {
      height: 100%;
      width: 220px;
      position: relative;
      display: block;
      margin: 0;

      &__chart {
        display: block;
        .active-circle {
          position: absolute;
          width: 200px;
          height: 200px;
          background: linear-gradient(210deg, #00DC0A 10.12%, #F1CF54 50.04%, #DC0000 90.33%);
          left: 15px;
          border-radius: 140px;

          @for $i from 0 through 100 {
            &.percent-#{$i} {
              $deg: ($i*1.8);
              mask-image: conic-gradient(from -90deg, #000000, #000000 #{$deg}deg, #00000036 #{$deg}deg, #00000036 180deg, transparent 180deg, transparent 360deg);
            }
          }

          .inner-circle {
            position: absolute;
            left: 10px;
            top: 10px;
            width: 180px;
            height: 180px;
            background: white;
            border-radius: 50%;
          }
        }

        .arrow  {
          $awidth: 35px;
          $aheight: 35px;
          position: absolute;
          display: block;
          background: #171817;
          width: $awidth;
          top: calc(100% - 33px);
          height: $aheight;
          left: calc(50% - 10px);
          transform: rotate(-44deg) skew(35deg, 35deg);
          border-radius: 5px;
          border-bottom-right-radius: 35px;
          transition: all 1s;

          // position: absolute;
          // display: block;
          // width: 70px;
          // height: 70px;
          // top: calc(50% - 12px);
          // left: calc(50% - 5px);
          // transition: all 1s;

          @for $i from 0 through 100 {
            &.percent-#{$i} {
              $deg: (-44 + $i*1.8);
              transform: rotate(#{$deg}deg) skew(35deg, 35deg);
              // transform: rotate(#{$deg}deg);
            }
          }
        }
      }
    }

    .gauge-label {
        position: absolute;
        font-family: 'Circular Std';
        font-style: normal;
        font-weight: 450;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: -0.02em;
        color: #8E8E93;
        width: 60px;
    }

    .label0 {
        top: 75px;
        left: -60px;
    }

    .label1 {
        top: 5px;
        left: 0px;
    }

    .label2 {
        top: -30px;
        left: calc(50% - 25px);
    }

    .label3 {
        top: 5px;
        left: 200px;
    }

    .label4 {
        top: 75px;
        left: 220px;
        text-align: right;
    }

    .gauge-value {
      position: absolute;
      top: calc(100% + 15px);
      border: 2px solid #00DC0A;
      padding: 8px;
      font-family: "Circular Std";
      font-style: normal;
      font-weight: 450;
      font-size: 18px;
      line-height: 23px;
      letter-spacing: -0.04em;
      color: #00DC0A;
      text-transform: uppercase;
      left: calc(50% + 5px);
      transform: translateX(-50%);
      width: 140px;
      text-align: center;
    }
  }