.past-videos-dropdown {
  padding: 24px 16px;
  background: #fff;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 40px;
  cursor: pointer;
  flex-direction: column;
}

.past-videos-list {
  width: 100%;
  padding-top: 20px;
  display: none;
}

.past-videos-dropdown:hover .past-videos-list {
  display: block;
}

.past-videos-dropdown-option {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  color: #000000;
  padding: 5px 0;
  cursor: pointer;
}

.past-videos-dropdown-option.selected {
  font-weight: 700;
}

span.past-videos-dropdown-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
}

.past-videos-dropdown svg {
  fill: #BCBCBC!important;
  color: #BCBCBC!important;
  width: 30px!important;
  height: 30px!important;
}

.past-videos-big-title {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
  color: #FFFFFF;
  margin-bottom: 40px;
}

.past-videos-small-title {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 150%;
  color: #F5F5F5;
  margin-bottom: 40px;
}

.past-videos-blue-title {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: #00DC0A;
  cursor: pointer;
}

.past-videos-blue-title svg {
  color: #007BFF!important;
  fill: #007BFF!important;
}